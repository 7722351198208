// hooks/useErrorHandler.js
import { useNavigate } from 'react-router-dom';

export const useErrorHandler = () => {
    const navigate = useNavigate();

    const handleError = (error) => {
        if (error.response && error.response.status === 401) {
            // Assuming 401 status code indicates lack of token or unauthorized access
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            navigate('/login');
            window.location.reload();
        } else {
            // Handle other errors or rethrow
            console.error(error);
        }
    };

    return handleError;
};

