import React, { useEffect, useState } from 'react';
import { Button, Flex, Radio, Checkbox, notification, Tooltip, Select, Modal, Table, InputNumber, Alert, Badge } from 'antd';
import Input from 'antd/es/input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1, fa3, faBatteryFull, faSun, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Slider } from 'antd';
import { pushQuickSizingProject, fetchQuickSizingProject, fetchQuickSizingReport, fetchComponents } from '../services/apiService';
import { useParams } from "react-router-dom";
import logger from "../utils/logger";
logger.enableLogging('QuickSizing');

function QuickSizing() {
  const { sizing_id } = useParams(); // Get the UUID from the URL parameters
  const [clientName, setClientName] = useState('');
  const { Option } = Select;
  const [phase, setPhase] = useState('single');
  const [systemType, setSystemType] = useState('backup');
  const [batteryCapacity, setbatteryCapacity] = useState(30);
  const [inverterCapacity, setinverterCapacity] = useState(30);
  const [solarCapacity, setsolarCapacity] = useState(30);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [componentsData, setComponentsData] = useState({
    inverters: {},
    batteries: {},
    panels: {},
  });
  const [selectedInverterMake, setSelectedInverterMake] = useState(null);
  const [selectedBatteryMake, setSelectedBatteryMake] = useState(null);
  const [selectedSolarMake, setSelectedSolarMake] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
//   const [modalContent, setModalContent] = useState('');
  const [tableData, setTableData] = useState([]);


  const [batteryCompMeetsRequired, setBatteryCompMeetsRequired] = React.useState(true);
  const [inverterCompMeetsRequired, setInverterCompMeetsRequired] = React.useState(true);
  const [panelCompMeetsRequired, setPanelCompMeetsRequired] = React.useState(true);

  const groupComponentsByMake = (components) => {
    return components.reduce((acc, component) => {
      const { make } = component;
      if (!acc[make]) {
        acc[make] = [];
      }
      acc[make].push(component);
      return acc;
    }, {});
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    async function fetchData() {
      const response = await fetchQuickSizingProject(token, sizing_id);
      const data = response.data;
      setClientName(data.clientName);
      setPhase(data.phase);
      setSystemType(data.systemType);
      if (data.systemType === 'hybrid') {
        setsolarCapacity(data.solarCapacity);
      }
      setSelectedInverterMake(data.selectedInverterMake);
      setSelectedBatteryMake(data.selectedBatteryMake);
      setbatteryCapacity(data.batteryCapacity);
      setinverterCapacity(data.inverterCapacity);
      setAcceptTerms(data.acceptTerms);
    }

    async function fetchComponentsData() {
      const components_data = await fetchComponents(token);
      const groupedComponentsData = {
        inverters: groupComponentsByMake(components_data.inverters),
        batteries: groupComponentsByMake(components_data.batteries),
        panels: groupComponentsByMake(components_data.panels),
      };
      setComponentsData(groupedComponentsData);
    }

    fetchComponentsData();

    if (sizing_id) {
      fetchData();
      logger.log("QuickSizing", "setting data");
    }
  }, [sizing_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      clientName,
      phase,
      systemType,
      selectedInverterMake,
      selectedBatteryMake,
      selectedSolarMake,
      batteryCapacity,
      inverterCapacity,
      acceptTerms,
      solarCapacity
    };

    if (sizing_id) {
      data["_id"] = sizing_id;
    }

    logger.log("QuickSizing", "Pushing...", data);

    const token = localStorage.getItem('accessToken');
    await pushQuickSizingProject(token, data);

    notification.success({
      message: 'Form Submitted',
      description: 'Quick Monitor Sizing form submitted successfully',
      placement: 'bottomRight'
    });

    const quickReport = await fetchQuickSizingReport(token, data);
    logger.log("QuickSizing", quickReport);

    // Process the quickReport to extract table data
    const cleaned_Data = quickReport;
    const inverter_comp_results = cleaned_Data.system_results.inverter_result;
    inverter_comp_results["component_uuid"] = cleaned_Data.components.inverter.component_uuid;
    const battery_comp_results = cleaned_Data.system_results.battery_result;
    battery_comp_results["component_uuid"] = cleaned_Data.components.battery.component_uuid;
    let solar_panel_results = {};

    if (cleaned_Data.system_results.solar_panel_result) {
      solar_panel_results = cleaned_Data.system_results.solar_panel_result;
      solar_panel_results["component_uuid"] = cleaned_Data.components.solar.component_uuid;
    }

    const tableDatra = [
      {
        key: "1",
        category: "Inverter Technical",
        title: "Deye SUN-8K",
        component_uuid: inverter_comp_results.component_uuid,
        output: (inverter_comp_results.inverter_rated_output_kw * inverter_comp_results.no_units).toFixed(0),
        requirement: inverter_comp_results.peak_required_kw.toFixed(2) + " kW",
        quantity: inverter_comp_results.no_units,
        calcOutput: inverter_comp_results.inverter_rated_output_kw,
        calcCost: inverter_comp_results.cost_per_with_factor,
        component_cost: inverter_comp_results.cost_per_inverter,
      },
      {
        key: "2",
        category: "Battery Technical",
        title: battery_comp_results.battery_name,
        component_uuid: battery_comp_results.component_uuid,
        output: (battery_comp_results.backup_power_provided * battery_comp_results.no_units).toFixed(2),
        requirement: battery_comp_results.backup_power_required.toFixed(2) + " kWh",
        quantity: battery_comp_results.no_units,
        calcOutput: battery_comp_results.backup_power_provided,
        calcCost: battery_comp_results.cost,
        component_cost: battery_comp_results.cost,
      }
    ];

    if (solar_panel_results && solar_panel_results.name) {
      tableDatra.push({
        key: "3",
        category: "Panel Technical",
        title: solar_panel_results.name,
        component_uuid: solar_panel_results.component_uuid,
        output: (solar_panel_results.WP * solar_panel_results.no_units).toFixed(0),
        requirement: (solar_panel_results.required_solar_capacity_kw * 1000).toFixed(0) + " W",
        quantity: solar_panel_results.no_units,
        calcOutput: solar_panel_results.WP,
        calcCost: solar_panel_results.panel_price,
        component_cost: solar_panel_results.cost_per_panel,
      });
    }

    setTableData(tableDatra);
    // setModalContent(JSON.stringify(quickReport, null, 2)); // Format the response as JSON
    setIsModalVisible(true); // Show the modal
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleComponentQuanityChange = (value, record) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => record.key === item.key);

    if (index !== -1) {
      const item = newData[index];
      const newOutput = item.calcOutput * value;

      newData[index] = {
        ...item,
        quantity: value,
        output: newOutput.toFixed(0),
      };
      setTableData(newData);
    }
  };

  const editableColumns = [
    {
        title: "Category",
        dataIndex: "category",
        key: "category",
    },
    {
        title: "Recommended Components",
        dataIndex: "title",
        key: "title",
    },
    {
        title: "Components Output",
        dataIndex: "output",
        key: "output",
        render: (text, record) => {
            // Add the appropriate unit based on the category
            let unit;
            let meetsRequirement;
            switch (record.category) {
                case "Inverter Technical":
                    unit = " kW";
                    meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
                    setInverterCompMeetsRequired(meetsRequirement);
                    break;
                case "Battery Technical":
                    unit = " kWh";
                    meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
                    setBatteryCompMeetsRequired(meetsRequirement);
                    break;
                case "Panel Technical":
                    unit = " W";
                    meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
                    setPanelCompMeetsRequired(meetsRequirement);
                    break;
                default:
                    unit = "";
                    meetsRequirement = true;
            }


        
            // Render with Badge
            return (
                <div>
                    {!meetsRequirement ? (
                        <Badge status="warning" text={`${text}${unit}`} />
                    ) : (
                        `${text}${unit}`
                    )}
                </div>
            );
        },
    },
    {
        title: "Requirement",
        dataIndex: "requirement",
        key: "requirement",
    },
    {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        render: (text, record) => (
            <InputNumber
                min={1}
                value={text}
                onChange={(value) => handleComponentQuanityChange(value, record)}
            />
        ),
    },
];

  return (
    <div>
      <h1>QuickSizing</h1>
      <form onSubmit={handleSubmit}>
        <Flex gap="middle" horizontal>
          <div style={{ width: '50%' }}>
            <h2>Inputs</h2>
            <p>
              The quick sizing if for a basic installation. Add a project name and use the sliders to get the inputs you need for the project. Then click the button to get the results.
            </p>
            <Input placeholder="Client Name/Project" value={clientName} onChange={(e) => setClientName(e.target.value)} required />
            <br></br>
            <br></br>
            <Flex gap="small">
              <label style={{ fontSize: '16px', color: '#888', marginTop: "auto", marginBottom: "auto" }}>Phase</label>
              <Radio.Group onChange={(e) => setPhase(e.target.value)} value={phase} buttonStyle="solid">
                <Radio.Button value={'single'} ><FontAwesomeIcon icon={fa1} /> SINGLE</Radio.Button>
                <Radio.Button value={'three'}><FontAwesomeIcon icon={fa3} /> THREE</Radio.Button>
              </Radio.Group>
            </Flex>
            <br></br>
            <Flex gap="small">
              <label style={{ fontSize: '16px', color: '#888', marginTop: "auto", marginBottom: "auto" }}>System Type</label>
              <Radio.Group onChange={(e) => setSystemType(e.target.value)} value={systemType} buttonStyle="solid" >
                <Radio.Button value={'backup'}><FontAwesomeIcon icon={faBatteryFull} /> BACKUP</Radio.Button>
                <Radio.Button value={'hybrid'}><FontAwesomeIcon icon={faSun} /> HYBRID/OFF GRID</Radio.Button>
              </Radio.Group>
            </Flex>
            <br></br>
            <div>
              <Flex gap="small">
                <label
                  style={{
                    fontSize: "16px",
                    color: "#888",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  Inverter Make
                </label>
                <Select
                  placeholder="Select Inverter Make"
                  onChange={(value) => setSelectedInverterMake(value)}
                  value={selectedInverterMake}
                  required
                >
                  {Object.keys(componentsData.inverters).map((make) => (
                    <Option key={make} value={make}>
                      {make}
                    </Option>
                  ))}
                </Select>
              </Flex>
              <br />
              <Flex gap="small">
                <label
                  style={{
                    fontSize: "16px",
                    color: "#888",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  Battery Make
                </label>
                <Select
                  placeholder="Select Battery Make"
                  onChange={(value) => setSelectedBatteryMake(value)}
                  value={selectedBatteryMake}
                  required
                >
                  {Object.keys(componentsData.batteries).map((make) => (
                    <Option key={make} value={make}>
                      {make}
                    </Option>
                  ))}
                </Select>
              </Flex>
              <br />
              {systemType === 'hybrid' && (
                <div>
                  <Flex gap="small">
                    <label
                      style={{
                        fontSize: "16px",
                        color: "#888",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Solar Make
                    </label>
                    <Select
                      placeholder="Select the Solar make"
                      onChange={(value) => setSelectedSolarMake(value)}
                      value={selectedSolarMake}
                      required
                    >
                      {Object.keys(componentsData.panels).map((make) => (
                        <Option key={make} value={make}>
                          {make}
                        </Option>
                      ))}
                    </Select>
                  </Flex>
                  <br />
                </div>
              )}
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <p>Battery Capacity - {batteryCapacity}kWh <Tooltip title="The amount of energy the battery can store">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                style={{ marginLeft: "8px" }}
              />
            </Tooltip></p>
            <Slider value={batteryCapacity} onChange={setbatteryCapacity} max={100} min={2} />

            <p>Inverter Peak Capacity - {inverterCapacity}kW <Tooltip title="The maximum amount of power the inverter can supply">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                style={{ marginLeft: "8px" }}
              />
            </Tooltip></p>
            <Slider value={inverterCapacity} onChange={setinverterCapacity} min={3} max={500} />

            {systemType === 'hybrid' && <div>
              <p>Solar Capacity - {solarCapacity}kW <Tooltip title="The amount of power the solar panels you plan to install can generate">
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ marginLeft: "8px" }}
                />
              </Tooltip></p>
              <Slider value={solarCapacity} onChange={setsolarCapacity} min={1} max={30} />
            </div>}

            <br></br>
            <Checkbox checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)}>Accept Terms and Conditions</Checkbox>
            <br></br>
            <br></br>
            <a href="#">Terms and Conditions</a>

            <br></br>
            <br></br>
            <Button type="primary" disabled={!acceptTerms || !clientName || !selectedBatteryMake || !selectedInverterMake || (systemType === 'hybrid' && (!selectedSolarMake))} htmlType="submit">GENERATE SIZING</Button>
          </div>
        </Flex>
      </form>
      <Modal
        title="Quick Sizing Report"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Close"
        width={800}
      >
       <div>
       <div id="compsTable">
               


               <Table
                   dataSource={tableData}
                   columns={editableColumns}
                   pagination={false}
                   bordered
                   title={() => "Recommended Components Based On Calculations"}
                   footer={() =>
                       "* Component recommendations take into account the configuration and minimums required to setup the system optimally."
                   }
               />
                {(!batteryCompMeetsRequired || !inverterCompMeetsRequired || !panelCompMeetsRequired) && (
                   <span>
                       <br />
                       <Alert message="CAUTION" description="Some of your components don't meet their required output in the table above. Only proceed if you know what you are doing" type="warning" showIcon />
                       
                   </span>
               )}
           </div>
       </div>
      </Modal>
    </div>
  );
}

export default QuickSizing;