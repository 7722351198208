import React, { useState, useEffect } from 'react';
import PropsType from 'prop-types';
import { Button, Flex, Tooltip, Input, Modal, Select } from 'antd';
import { PlusOutlined, ThunderboltOutlined, UnorderedListOutlined, DeleteOutlined, LineChartOutlined, BarChartOutlined } from '@ant-design/icons';
import { Card, Skeleton, Alert, message, Badge, Row, Col, Statistic } from 'antd';
import { Link } from 'react-router-dom';
import { fetchMonitors, removeMonitorFromAccount, fetchCreditBalance } from '../services/apiService';
import TourComponent from './TourComponent';
import logger from '../utils/logger';
import { useNavigate } from 'react-router-dom';
logger.enableLogging('Home');

const { Option } = Select;
function Home({ userData, setMonitorData, monitorData, businessData, isConnectedToBusiness }) {
    const [loading, setLoading] = useState(true);
    const [isBusinessAdmin, setisBusinessAdmin] = useState(false);
    // const [email, setEmail] = useState('');
    // const [newMonitorID, setNewMonitorID] = useState('');
    const { confirm } = Modal;
    // const [dataFetched, setDataFetched] = useState(false); // New state variable to track data fetching
    const [localMonitors, setLocalMonitors] = useState([]); // New state variable to store fetched data locally

    const [hasNoMonitors, setHasNoMonitors] = useState(false);


    const navigate = useNavigate();


    const [searchValue, setSearchValue] = useState('');
    const [filteredMonitors, setFilteredMonitors] = useState(localMonitors);
    const [filterStatus, setFilterStatus] = useState('all');
    const [sortOrder, setSortOrder] = useState('desc');


    const [businessCredits, setBusinessCredits] = useState(0);



    useEffect(() => {
        setFilteredMonitors(
            localMonitors
                .filter((monitor) =>
                    monitor.displayName.toLowerCase().includes(searchValue.toLowerCase()) &&
                    (filterStatus === 'all' || (filterStatus === 'online' && monitor.isOnline) || (filterStatus === 'offline' && !monitor.isOnline))
                )
                .sort((a, b) => {
                    const dateA = new Date(a.updated);
                    const dateB = new Date(b.updated);
                    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
                })
        );
    }, [searchValue, localMonitors, filterStatus, sortOrder]);


    const tour_steps = [
        {
            title: 'Welcome',
            description: 'Welcome to the dashboard! This is a quick tour to help you get started.',
            target: null,
        },
        {
            title: 'Shopping button',
            description: 'As you can see, the shopping button is located on the left side of the screen.',
            placement: 'right',
            targetId: 'shopping-button',
        },
        {
            title: 'New Monitor Sizing',
            description: 'This button allows you to create a new monitor sizing.',
            placement: 'top',
            targetId: 'new-monitor-sizing-button',
        },
        {
            title: 'New Quick Sizing',
            description: 'This button allows you to create a new quick sizing.',
            placement: 'top',
            targetId: 'new-quick-sizing-button',
        },
        {
            title: 'Projects',
            description: 'This button takes you to the projects page.',
            placement: 'top',
            targetId: 'projects-button',
        },
    ];



    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('accessToken');

            if(!userData.isSuperAdmin){

            if(isConnectedToBusiness){
                const creds = await fetchCreditBalance(token);
                setBusinessCredits(creds);
                
        }else{
            setBusinessCredits(0);
        }
    }else {
        setBusinessCredits(1000);
    }
            if (!monitorData || monitorData.length === 0) {
                if (!hasNoMonitors) {
                    try {
                        // const token = localStorage.getItem('accessToken');
                        const response_data = await fetchMonitors(token);



                        // console.log("fetching data as there is none", response_data);
                        if (response_data.length === 0) {
                            setHasNoMonitors(true);
                        } else {
                            setHasNoMonitors(false);
                        }
                        setMonitorData(response_data);
                        setLocalMonitors(response_data);
                        setLoading(false);

                    } catch (error) {
                        console.error('Failed to fetch monitors:', error);
                    }
                } else {

                    setLoading(false);
                }
            } else {
                // console.log("no monitors", monitorData);
                setLocalMonitors(monitorData);
                setLoading(false);
            }
        };

        if (userData.privilage) {
            setisBusinessAdmin(userData.privilage.level === "1");
        }

        fetchData();
    }, [monitorData, setMonitorData, userData.privilage, businessData, hasNoMonitors]);

    // const handleMonitorRegister = async () => {
    //     try {
    //         const token = localStorage.getItem('accessToken');
    //         const response_data = await registerMonitorToAccount(token, { newMonitorID });
    //         // console.log("response_data", response_data);
    //         if (response_data.status === '200') {
    //             // now add this monitor to the list of monitors
    //             const newMonitorData = await fetchMonitors(token);
    //             setLocalMonitors(newMonitorData);
    //             setMonitorData(newMonitorData);
    //             setHasNoMonitors(false);
    //             message.success(`Monitor ${newMonitorID} added successfully`);
    //         } else {
    //             message.error(`Failed to add monitor ${newMonitorID}. ` + response_data.message);
    //         }

    //     } catch (error) {
    //         console.error('Failed to fetch monitors:', error);
    //     }
    // };



    // const handleInvite = async () => {
    //     try {
    //         const token = localStorage.getItem('accessToken');
    //         const invite_response = await inviteUserToBusiness(token, { email });
    //         logger.log("Home", "invite_response", invite_response);
    //         // Handle the response from the invitation
    //         if (invite_response.status === '200') {
    //             message.success(`Invitation sent to ${email}`);

    //         } else {
    //             message.error(`Failed to invite ${email}`);
    //         }
    //         // Reset the email input
    //         setEmail('');
    //     } catch (error) {
    //         console.error('Failed to invite user:', error);
    //         message.error('An error occurred while inviting the user');
    //     }
    // };


    const handleGraphClick = (monitorId) => {
        console.log(`Graph clicked for monitor ${monitorId}`);
        navigate(`/monitors/${monitorId}`);
        // Add your logic to handle graph click
    };

    // const handleEditClick = (monitorId) => {
    //     console.log(`Edit clicked for monitor ${monitorId}`);
    //     // Add your logic to handle edit click
    // };


    const handleDeleteClick = (monitorId) => {
        confirm({
            title: 'Are you sure you want to remove this monitor from your business account?',
            content: 'This action cannot be undone.',
            async onOk() {
                // console.log(`Delete confirmed for monitor ${monitorId}`);
                const token = localStorage.getItem('accessToken');
                const resp = await removeMonitorFromAccount(token, monitorId);
                // console.log("resp", resp);
                if (resp.status === '200') {
                    const token = localStorage.getItem('accessToken');
                    const newMonitorData = await fetchMonitors(token);
                    setLocalMonitors(newMonitorData);
                    setMonitorData(newMonitorData);
                    message.success(`Monitor ${monitorId} removed successfully`);
                } else {
                    message.error(`Failed to remove monitor ${monitorId}. ` + resp.message);
                }

            }
        });
    };



    return (
        <div style={{ margin: '10px' }}>

            <Row justify="space-between" align="middle">
                <Col>
                    <h1>Overview</h1>
                    {isConnectedToBusiness ? (
                        <p>Logged in as <strong>{userData.fullname}</strong> acting as <strong>{isBusinessAdmin ? 'Admin' : 'User'}</strong> for <strong>{businessData ? businessData['business_name'] : 'No Business Linked'}</strong></p>
                    ) : (
                        <p>Logged in as <strong>{userData.fullname}</strong></p>
                    )}
                </Col>
                <Col>
                    {userData.isSuperAdmin ? (<Tooltip title="Super admins have infinite credits for testing purposes">
                    <Statistic title="Account Balance (Credits)" value="∞" />
                </Tooltip>) : (
                        <Statistic title="Account Balance (Credits)" value={businessCredits} precision={2} />
                    )}
                    {/* <Statistic title="Account Balance (Credits)" value={businessCredits} precision={2} /> */}
                
                </Col>



            </Row>

            {userData.isSuperAdmin && (
                <Alert message="You are logged in as a Super Admin. The webapp is purple for super admins. Super admins are not business admins, they are the highest level of authority." type="warning" showIcon />
            )}
            <br />








            <Flex gap="small" wrap>
                <Link to="/monitors">
                    <Button type="primary" icon={<BarChartOutlined />} id="monitors-button">Monitor Usage</Button>
                </Link>
                <Link to="/project-simple">
                    <Tooltip title={!isBusinessAdmin ? "Only admins can create new sizings" : ""}>
                        <Button type="primary" disabled={!isBusinessAdmin} icon={<PlusOutlined />} id="new-monitor-sizing-button">New Monitor Sizing</Button>
                    </Tooltip>
                </Link>
                <Link to="/quick-sizing">
                    <Tooltip title={!isBusinessAdmin ? "Only admins can create new quick sizings" : ""}>
                        <Button type="primary" disabled={!isBusinessAdmin} icon={<ThunderboltOutlined />} id="new-quick-sizing-button">New Quick Sizing</Button>
                    </Tooltip>
                </Link>
                <Link to="/projects">
                    <Button type="primary" icon={<UnorderedListOutlined />} id="projects-button">Projects</Button>
                </Link>
            </Flex>
            <br />
            {businessData ? (
                <div>
                    <Skeleton loading={loading} active>
                        <Flex gap="small">
                            <Input.Search
                                placeholder="Search monitors"
                                onSearch={(value) => setSearchValue(value)}
                                onChange={(e) => setSearchValue(e.target.value)}
                                style={{ marginBottom: '20px', width: '300px' }}
                            />
                            <Select
                                defaultValue="all"
                                onChange={(value) => setFilterStatus(value)}
                                style={{ marginBottom: '20px', width: '150px' }}
                            >
                                <Option value="all">All</Option>
                                <Option value="online">Online</Option>
                                <Option value="offline">Offline</Option>
                            </Select>
                            <Select
                                defaultValue="desc"
                                onChange={(value) => setSortOrder(value)}
                                style={{ marginBottom: '20px', width: '150px' }}
                            >
                                <Option value="asc">Last Seen Asc</Option>
                                <Option value="desc">Last Seen Desc</Option>
                            </Select>
                        </Flex>
                        {filteredMonitors.length > 0 ? (
                            <Flex gap="small" wrap>
                                {filteredMonitors.map((monitor) => (
                                    <Card
                                        key={monitor.id}
                                        title={
                                            <div>
                                                {monitor.displayName}
                                                {monitor.isOnline && (
                                                    <Badge
                                                        status="success"
                                                        text="Online"
                                                        style={{ marginLeft: 10 }}
                                                    />
                                                )}
                                                {!monitor.isOnline && (
                                                    <Badge
                                                        status="error"
                                                        text="Offline"
                                                        style={{ marginLeft: 10 }}
                                                    />
                                                )}

                                            </div>
                                        }
                                        style={{ width: 300 }}
                                        actions={[
                                            <LineChartOutlined key="graphs" onClick={() => handleGraphClick(monitor.id)} />,
                                            // <EditOutlined key="edit" onClick={() => handleEditClick(monitor.id)} />,
                                            <DeleteOutlined key="remove" onClick={() => handleDeleteClick(monitor.id)} />,
                                        ]}
                                    >
                                        <p>
                                            {monitor.description ? monitor.description : 'No description available'}
                                            <br />
                                            <strong>SN:</strong> {monitor.name} <br />
                                            <strong>Last:</strong> {new Date(monitor.updated).toLocaleString()}

                                        </p>
                                    </Card>
                                ))}
                            </Flex>
                        ) : (
                            <p>No monitors found. Make sure to register them in the business section of the <b><Link to="/profile">Settings Page</Link></b></p>
                        )}
                    </Skeleton>
                </div>
            ) : (
                <Alert message="No business was found linked to this account. To get started either have the business owner invite your account to contribute, or click here to register as a business." type="warning" showIcon />
            )}

            <br />


            <TourComponent steps={tour_steps} tourKey="homeTourCompleted" />

{/* 
            {isConnectedToBusiness && isBusinessAdmin && (
                <div>
                    <h2>Business Quick Management</h2>
                    <div>
                        <p>Enter email below to invite that user to your business. This will allow them to view the business monitors, ect...</p>
                        <Input
                            type="email"
                            placeholder="Enter user's email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ width: '300px', marginRight: '10px' }}
                        />
                        <Button type="primary" onClick={handleInvite}>
                            Invite
                        </Button>
                    </div>
                    <div>
                        <h2>Monitor Management</h2>
                        <p>As a business Admin, you can add monitors to your business</p>
                        <Input placeholder="Enter the Monitor ID" style={{ width: '300px', marginRight: '10px' }} value={newMonitorID}
                            onChange={(e) => setNewMonitorID(e.target.value)} />
                        <Button type="primary" onClick={handleMonitorRegister}>
                            Add Monitor
                        </Button>
                    </div>
                </div>
            )} */}



        </div>
    );
}


Home.propTypes = {
    userData: PropsType.object.isRequired,
    setMonitorData: PropsType.func.isRequired,
    monitorData: PropsType.array.isRequired,
    businessData: PropsType.object,
    isConnectedToBusiness: PropsType.bool.isRequired,
};

export default Home;