import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import withDynamicWidth from './withDynamicWidth';

const InverterOperatingCapacityChart = ({ data, width }) => {
    return (
        <Plot
            data={data}
            layout={{
                title: "Inverter Operating Capacity Projection (% of time)",
                width: width,
                height: 400, // Set a fixed height
                xaxis: { title: { text: '% of Inverter Operating Capacity', font: { size: 18 } }, showgrid: false, zeroline: false, tickformat: '%d %b %Y' },
                yaxis: { title: { text: 'Percentage of Consumption Values', font: { size: 18 } }, showgrid: true, zeroline: true },
                plot_bgcolor: '#ffffff',
                mode: 'lines+markers',
                paper_bgcolor: '#ffffff',
                scrollZoom: true,
            }}
        />
    );
};

InverterOperatingCapacityChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
};

export default withDynamicWidth(InverterOperatingCapacityChart);