import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {  Table,  Skeleton, Alert} from 'antd';
import { fetchLeads } from '../services/apiService';
const columns = [
  {
    title: 'Client Name',
    dataIndex: 'clientName',
    key: 'clientName',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (text) => <a href={`mailto:${text}`} target="_blank" rel="noopener noreferrer">{text}</a>,
},
  {
    title: 'Address',
    dataIndex: 'clientAddress',
    key: 'clientAddress',
  },
  {
    title: 'Monthly Bill',
    dataIndex: ['clientQuestions', 'monthlyBill'],
    key: 'monthlyBill',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Created Date',
    dataIndex: 'created',
    key: 'created',
    render: (text) => new Date(text).toLocaleDateString(),
  },
];

function Leads({isConnectedToBusiness}) {

  const [loading, setLoading] = useState(true);
  const [leadData, setLeadData] = useState([]);


  useEffect(() => async () => {


      // if (!monitorData || monitorData.length === 0) {
          try {
              // Retrieve the access token from localStorage
              const token = localStorage.getItem('accessToken');
              const response_data = await fetchLeads(token)
              setLeadData(response_data)
              setLoading(false);
          } catch (error) {
              console.error('Failed to fetch leads:', error);
          }
      // } else {
      //     setLoading(false);
      // }


  }, []);

  if (!isConnectedToBusiness) {
    return (
        <div>
            <h1>Leads</h1>
            <Alert message="You are not connected to a business. Please connect to a business to view the Leads." type="warning" showIcon />
        </div>
    );
}
    
    return <div>
     
        <h1>Leads</h1>
        <p>Solar leads from people near you will display here. You can accept the request and contact the client. Or decline and we will send the lead to another installer.</p>
      <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <Table
          columns={columns}
          dataSource={leadData}
          rowKey="_id"
          pagination={false}
        />
      )}
      </div>
    </div>;
}

Leads.propTypes = {
  isConnectedToBusiness: PropTypes.bool.isRequired,
};

export default Leads;