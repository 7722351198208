import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import {
    Button,
    Flex,
    Select,
    Radio,
    Slider,
    Checkbox,
    DatePicker,
    notification,
    Spin,
    Modal,
    message,
    Tooltip,
    Space,
    Alert,
    Skeleton,
    Progress
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    fa1,
    fa3,
    faBatteryFull,
    faSun,
    faList,
    faPlug,
    faMinus,
    faPlugCircleXmark,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Input from "antd/es/input/Input"; // Importing Input directly from 'antd/es/input/Input' for optimization
import { useParams, Link } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { fetchCreditBalance, fetchMonitorsFast, pushSizingProject } from "../../services/apiService";
import {


    fetchSizingReport,
    fetchSizingProject,
    fetchComponents,
    fetchAggregatedMonitorData,
} from "../../services/apiService";



import { useErrorHandler } from "../Util";


import DailyKWHChart from "../charts/DailyKWHChart";
import PeakDataChart from "../charts/PeakDataChart";

import logger from "../../utils/logger";
logger.enableLogging('SizingPage');

import { useNavigate } from "react-router-dom";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


const Sizing = ({ setReportResponse, userEnabledDarkMode }) => {
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);

    const handleError = useErrorHandler();

    const [useFastAggregation, setUseFastAggregation] = useState(false);

    const  [dateUpdated, setDateUpdated] = useState(true);

    const [noDataFound, setNoDataFound] = useState(false);


    const navigate = useNavigate(); // Initialize useNavigate hook

    const { sizing_id } = useParams(); // Get the UUID from the URL parameters

    const [dateCutoffEnd, setDateCutoffEnd] = useState(dayjs().add(1, "day"));

    const [clientName, setClientName] = useState("");
    const [phase, setPhase] = useState("single");
    const [voltageType, setVoltageType] = useState("HV");
    const [systemType, setSystemType] = useState("backup");

    const [fillGaps, setFillGaps] = useState("fill");
    const [batterySize, setBatterySize] = useState(14);
    const [inverterSize, setInverterSize] = useState(100);
    const [solarSize, setSolarSize] = useState(100);
    const [acceptTerms, setAcceptTerms] = useState(false);

    const [startDate, setStartDate] = useState(dayjs().subtract(2, "day"));
    const [endDate, setEndDate] = useState(dayjs().subtract(1, "day"));

    const [businessCredits, setBusinessCredits] = useState(0);

    const [loading, setLoading] = useState(true);

    const [isLoadingGraphData, setIsLoadingGraphData] = useState(true);
    const [isDateChange, setIsDateChange] = useState(false);
    const [fetchDataProgress, setFetchDataProgress] = useState(false);

    const [abortController, setAbortController] = useState(null);

    // progress bar for the graph data
    const [progress, setProgress] = useState(0);


    // const navigate = useNavigate();
    // const [formData, setFormData] = useState({});

    const { Option } = Select;

    // MONITORS DATA
    // const [monitors, setMonitors] = useState([]);
    // const [monitorData, setMonitorData] = useState([]);
    const [selectedMonitorId, setSelectedMonitorId] = useState(null);

    // const [aggregatedMonitorData, setAggregatedMonitorData] = useState([]);
    const [business_monitors, setBusinessMonitors] = useState({ monitors: [] });


    // CLAMPS
    const [isClamp1, setIsClamp1] = useState(true);
    const [isClamp2, setIsClamp2] = useState(true);
    const [isClamp3, setIsClamp3] = useState(true);

    // DATA AND GRAPHS
    // const [dailyDataAggregation, setDailyDataAggregation] = useState([]);
    // const [combinedData, setCombinedData] = useState([]);
    const [peakData, setPeakData] = useState([]);
    const [dailyKWData, setDailyKWData] = useState([]);
    // const [dayNightSplit, setDayNightSplit] = useState([]);
    // const [lineData, setLineData] = useState([]);
    // const [reportSavingsData, setReportSavingsData] = useState([]);
    // const [totalSavings, setTotalSavings] = useState(0);

    const [newRawHourlyReadings, setNewRawHourlyReadings] = useState([]);
    // We will save the recommended components based on report calculation here
    const [componentsData, setComponentsData] = useState({
        inverters: {},
        batteries: {},
        panels: {},
    });
    const [isSolar, setIsSolar] = useState(false); // State to manage the visibility of solar options

    const [currentData, setCurrentData] = useState([]);

    const [didSubmitReportData, setDidSubmitReportData] = useState(false);

    // MODAL
    // const [reportData, setReportData] = useState(null);
    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const [tableColumns, setTableColumns] = useState([]);
    // const [tableData, setTableData] = useState([]);

    // selection tracking for dropdowns
    const [selectedInverterMake, setSelectedInverterMake] = useState(null);
    const [selectedInverter, setSelectedInverter] = useState(null);
    const [selectedBatteryMake, setSelectedBatteryMake] = useState(null);
    const [selectedBattery, setSelectedBattery] = useState(null);

    const [selectedSolarPanel, setSelectedSolarPanel] = useState(null);
    const [selectedSolarMake, setSelectedSolarMake] = useState(null);

    // clamps status
    const [clampsStatus, setClampsStatus] = useState({
        clamp1: { name: "CT Clamp 1", enabled: true, deduct_from_total: false },
        clamp2: { name: "CT Clamp 2", enabled: true, deduct_from_total: false },
        clamp3: { name: "CT Clamp 3", enabled: true, deduct_from_total: false },
    });


    // Check if the phase is three-phase or single-phase to enable or disable clamps
    const isThreePhase = phase === "three";

    const [isClampsModalOpen, setIsClampsModalOpen] = useState(false);

    // Effect to update clamp states based on clampStatus
    useEffect(() => {
        setIsClamp1(clampsStatus.clamp1.enabled);
        setIsClamp2(clampsStatus.clamp2.enabled);
        setIsClamp3(clampsStatus.clamp3.enabled);
    }, [clampsStatus]);

    // Effect to enable all clamps if threePhase is selected
    useEffect(() => {
        if (isThreePhase) {
            // Enable all clamps for three-phase
            setClampsStatus(
                clampsStatus.clamp1.enabled && clampsStatus.clamp2.enabled && clampsStatus.clamp3.enabled ? clampsStatus : {
                    clamp1: { name: "CT Clamp 1", enabled: true, deduct_from_total: false },
                    clamp2: { name: "CT Clamp 2", enabled: true, deduct_from_total: false },
                    clamp3: { name: "CT Clamp 3", enabled: true, deduct_from_total: false },
                }

            );
        }else{
            // here we just went to single phase
            setVoltageType("LV");
        }
    }, [isThreePhase, clampsStatus]);

    const openClampsModal = () => {
        setIsClampsModalOpen(true);
    };

    const closeClampsModal = () => {
        setIsClampsModalOpen(false);
    };

    // Function to populate form data
    const populateFormData = (projectData) => {
        // logger.log("SizingPage", "Project Data", projectData);
        setClientName(projectData.clientName || "");

        setSelectedMonitorId(projectData.monitorId || "");

        setPhase(projectData.phase || "single");
        setSystemType(projectData.systemType || "backup");



        // Set selected inverter and battery based on the new structure
        setSelectedInverterMake(projectData.inverterMake || "");
        setSelectedInverter(projectData.inverter || "");
        setSelectedBatteryMake(projectData.batteryMake || "");
        setSelectedBattery(projectData.battery || "");

        setSolarSize(projectData.pvArraySize || 100);
        setSelectedSolarMake(projectData.solarMake || "");
        setSelectedSolarPanel(projectData.solarPanel || "");

        setFillGaps(projectData.fillGaps || "fill");

        setVoltageType(projectData.voltageType || "HV");

        setBatterySize(projectData.batterySize ?? 30);
        setInverterSize(projectData.inverterSize ?? 30);
        setAcceptTerms(projectData.acceptTerms || false);

        // Set the clamps status
        setClampsStatus(
            projectData.clampsStatus || {
                clamp1: { name: "CT Clamp 1", enabled: true, deduct_from_total: false },
                clamp2: { name: "CT Clamp 2", enabled: true, deduct_from_total: false },
                clamp3: { name: "CT Clamp 3", enabled: true, deduct_from_total: false },
            }
        );

        const formattedStartDate = projectData.startDate
            ? dayjs(projectData.startDate)
            : dayjs().subtract(2, "day");
        const formattedEndDate = projectData.endDate
            ? dayjs(projectData.endDate)
            : dayjs().subtract(1, "day");

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
    };

    // is used to cancel request and go to home page
    const handleCancel = () => {
        if (abortController) {
            abortController.abort(); // Abort the ongoing request
        }
        navigate('/'); // Navigate to the home page
    };

    /**
     * Effect hook to aggregate daily data and update graph data based on selected monitor and date range.
     *
     * @param {string} sizing_id - The ID of the sizing.
     * @param {Object} business_monitors - The business monitors object.
     * @param {Object[]} business_monitors.monitors - Array of monitor objects.
     * @param {string} selectedMonitorId - The ID of the selected monitor.
     * @param {Date} startDate - The start date for the data range.
     * @param {Date} endDate - The end date for the data range.
     * @param {boolean} isClamp1 - Flag to include clamp 1 data.
     * @param {boolean} isClamp2 - Flag to include clamp 2 data.
     * @param {boolean} isClamp3 - Flag to include clamp 3 data.
     * @param {boolean} isThreePhase - Flag to indicate if the system is three-phase.
     * @param {Function} setDailyDataAggregation - Function to set the aggregated daily data.
     * @param {Function} setDailyKWData - Function to set the daily kWh graph data.
     * @param {Function} setPeakData - Function to set the peak data.
     * @param {Function} setDayNightSplit - Function to set the day/night split data.
     */
    useEffect(() => {
        if (sizing_id && business_monitors && business_monitors.monitors) {
            const selectedMonitor = business_monitors.monitors.find(
                (monitor) => monitor.id === selectedMonitorId
            );
            if (selectedMonitor) {
                console.time('Selected Monitor Aggregated Data');
                

                // console.log("SELECTED MONITOR", selectedMonitor);
                // Check if aggregatedData.data is an object
                if (selectedMonitor.data && typeof selectedMonitor.data === 'object') {
                    const clamp1vData = selectedMonitor.data.clamp1v.data;
                    const clamp1iData = selectedMonitor.data.clamp1i.data;
                    const clamp2vData = selectedMonitor.data.clamp2v.data;
                    const clamp2iData = selectedMonitor.data.clamp2i.data;
                    const clamp3vData = selectedMonitor.data.clamp3v.data;
                    const clamp3iData = selectedMonitor.data.clamp3i.data;

                    // Assuming all data arrays have the same length
                    const newRawHourlyReadings = clamp1vData.map((_, index) => ({
                        clamp1v: clamp1vData[index],
                        clamp1i: clamp1iData[index],
                        clamp2v: clamp2vData[index],
                        clamp2i: clamp2iData[index],
                        clamp3v: clamp3vData[index],
                        clamp3i: clamp3iData[index],
                    }));

                    // console.log("New Raw Hourly Readings", newRawHourlyReadings);

                    setNewRawHourlyReadings(newRawHourlyReadings);
                    console.timeEnd('Selected Monitor Aggregated Data');
                } else {
                    console.error("aggregatedData.data is not an object", selectedMonitor.data);
                }

                //const result = aggregateDailyData(selectedMonitor.data);
                //setDailyDataAggregation(result);

                // logger.log("SizingPage","new data", selectedMonitor);

                // Call the function to set the graph data after a short delay
                //setTimeout(() => {
                    // const formattedRawHourlyReadings = formatHourlyRawData(newRawHourlyReadings);




                    // const correctDailyKwh = aggregateDailyKwh(newRawHourlyReadings, isThreePhase); // working accurate calculation for the daily kwh
                    // console.log("Correct Daily Kwh", correctDailyKwh);

                    // const newDailyKWData = dailyKWHGraph(correctDailyKwh, startDate, endDate, isClamp1, isClamp2, isClamp3, isThreePhase);

                    // const newPeakData = calculatePeakData(newRawHourlyReadings, isClamp1, isClamp2, isClamp3, startDate, endDate);


                    // const newDayNightSplit = calculateDayNightSplit(newRawHourlyReadings, isClamp1, isClamp2, isClamp3, startDate, endDate, isThreePhase);

                    // setDailyKWData(newDailyKWData);
                    // setPeakData(newPeakData);
                    // setDayNightSplit(newDayNightSplit);


                //}, 100); // Adjust the delay as needed
                setIsLoadingGraphData(true);
            } else {
                console.error("Selected monitor not found");
            }
        }
    }, [

        business_monitors,
        sizing_id,
        selectedMonitorId,
        startDate,
        endDate,
        isClamp1,
        isClamp2,
        isClamp3,
        isThreePhase,
    ]);


    useEffect (() => {

        async function fetchCreditScore () {
            const token = localStorage.getItem("accessToken");
            const business_credits = await fetchCreditBalance(token);
            setBusinessCredits(business_credits);
        }
        fetchCreditScore();
    
    }, []);


    // Function to group components by make
    const groupComponentsByMake = (components) => {
        return components.reduce((acc, component) => {
            const { make } = component;
            if (!acc[make]) {
                acc[make] = [];
            }
            acc[make].push(component);
            return acc;
        }, {});
    };

    /**
 * Effect hook to fetch and set business monitors, components data, and sizing project data on page load.
 *
 * @param {string} sizing_id - The ID of the sizing project.
 * @param {Function} setBusinessMonitors - Function to set the business monitors data.
 * @param {Function} setComponentsData - Function to set the grouped components data.
 * @param {Function} setLoading - Function to set the loading state.
 * @param {Function} populateFormData - Function to populate form data with the fetched project data.
 * @param {Function} setCurrentData - Function to set the current project data.
 */
    useEffect(
        () => async () => {
            try {



                const dataAggregationLevel = localStorage.getItem('dataAggregationLevel') || 'minutely';
                if (dataAggregationLevel === 'hourly') {
                    setUseFastAggregation(true);
                }
                const token = localStorage.getItem("accessToken");
                // console.time('Fetching Mon Old');
                // const data = await fetchMonitors(token, true);
                // console.timeEnd('Fetching Mon Old');
                console.time('Fetching Mon New');
                const data = await fetchMonitorsFast(token);

                setBusinessMonitors(data);
                console.timeEnd('Fetching Mon New');



                console.time('Fetching Components');
                const components_data = await fetchComponents(token);

                // Group inverters and batteries by make
                const groupedComponentsData = {
                    inverters: groupComponentsByMake(components_data.inverters),
                    batteries: groupComponentsByMake(components_data.batteries),
                    panels: groupComponentsByMake(components_data.panels),
                };

                setComponentsData(groupedComponentsData);
                console.timeEnd('Fetching Components');
                // logger.log("SizingPage","Load 1 - New Components Data", groupedComponentsData);

                //log("Influxd", monitorData);
                // setMonitorData(monitorData);

                // setMonitors(data);
                setLoading(false);

                if (sizing_id) {
                    const projectData = await fetchSizingProject(token, sizing_id);
                    populateFormData(projectData["data"]);
                    setCurrentData(projectData["data"]);
                    // logger.log("SizingPage","GETTING DATA FOR ", projectData);
                } else {
                    setSelectedMonitorId(data[0].id);
                }

                setProgress(25);
            } catch (error) {
                handleError(error);
                setLoading(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sizing_id]
    );


    useEffect(() => {
        if (selectedMonitorId) {
            const fetchAggregatedData = async () => {
                try {
                    console.time('Fetch Aggregated Data 1');
                    // work out date diff, then set text tip
                    setFetchDataProgress(0);
                    const controller = new AbortController();
                    setAbortController(controller);
                    const signal = controller.signal;

                    incrementDataLoadingProgressBar(dateDifference + (dateDifference / 2));
                    const token = localStorage.getItem("accessToken");
                    const strt = startDate.startOf("day").format("YYYY-MM-DD");
                    const endd = endDate.endOf("day").format("YYYY-MM-DD");
                    const business_credits = await fetchCreditBalance(token);
                    // logger.log("SizingPage","Business Credits", business_credits);
                    const aggregatedData = await fetchAggregatedMonitorData(
                        token,
                        strt,
                        endd,
                        selectedMonitorId,
                        business_credits,
                        useFastAggregation,
                        { signal }
                    ); // fetches the influx data for the monitor aggregated to hourly

                    console.timeEnd('Fetch Aggregated Data 1');

                    if(aggregatedData.data.length == 0){
                        // here we have empty data
                        setNoDataFound(true);
                        setFetchDataProgress(true);
                        setProgress(50);
                        setIsLoadingGraphData(false);
                        message.error("No data found for the selected monitor and date range. Please try another monitor, or your credit balance may be too low");
                        return;
                    }

                    setFetchDataProgress(true);
                    setProgress(50);

                    console.log("expiry", aggregatedData['expiry'])

                    

                    // if expiry date is not after today, then dont update the cuttoff end date setDateCutoffEnd
                    if (!dayjs(aggregatedData['expiry']).tz(dayjs.tz.guess()).isAfter(dayjs().tz(dayjs.tz.guess()))) {
                        // setEndDate(dayjs(aggregatedData['expiry']).tz(dayjs.tz.guess()));
                        // ABOVE REMOVED SO IT WILL WORK PLEASEEE
                        setDateCutoffEnd(dayjs(aggregatedData['expiry']).tz(dayjs.tz.guess()));
                    }

                    // if the expiry date is before the start date, make start date minus one day of expiry date
                    if(dayjs(aggregatedData['expiry']).isBefore(dayjs(startDate))){
                        setStartDate(dayjs(aggregatedData['expiry']).subtract(1, "day"));
                    }


                    // logger.log("SizingPage","LOAD 2 -  Aggregated Data Debug: ", aggregatedData);


                    // Check if aggregatedData.data is an object

                    if (aggregatedData.data && typeof aggregatedData.data === 'object') {
                        console.time('Fetch Aggregated Data 2');
                        const clamp1vData = aggregatedData.data.clamp1v.data;
                        const clamp1wattData = aggregatedData.data.clamp1watt.data;
                        const clamp1iData = aggregatedData.data.clamp1i.data;
                        const clamp2vData = aggregatedData.data.clamp2v.data;
                        const clamp2wattData = aggregatedData.data.clamp2watt.data;
                        const clamp2iData = aggregatedData.data.clamp2i.data;
                        const clamp3vData = aggregatedData.data.clamp3v.data;
                        const clamp3wattData = aggregatedData.data.clamp3watt.data;
                        const clamp3iData = aggregatedData.data.clamp3i.data;

                        // Assuming all data arrays have the same length
                        const newRawHourlyReadings = clamp1vData.map((_, index) => ({
                            clamp1v: clamp1vData[index],
                            clamp1watt: clamp1wattData[index],
                            clamp1i: clamp1iData[index],
                            clamp2v: clamp2vData[index],
                            clamp2watt: clamp2wattData[index],
                            clamp2i: clamp2iData[index],
                            clamp3v: clamp3vData[index],
                            clamp3watt: clamp3wattData[index],
                            clamp3i: clamp3iData[index],
                        }));

                        // logger.log("SizingPage", "New Raw Hourly Readings", newRawHourlyReadings);

                        setNewRawHourlyReadings(newRawHourlyReadings);
                        console.timeEnd('Fetch Aggregated Data 2');
                        setIsDateChange(false);
                        setProgress(75);
                    } else {
                        console.error("aggregatedData.data is not an object", aggregatedData.data);
                    }






                } catch (error) {
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        handleError(error);
                    }
                }
            };

            fetchAggregatedData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonitorId, dateUpdated]);

    // as the monitor is selected we get the id of the monitor
    const handleChange = (value) => {

        setSelectedMonitorId(value);
        setProgress(0);
        setIsLoadingGraphData(true);
    };

    // useEffect(() => {

    //     const formatted_hourly_data = formatHourlyRawData(newRawHourlyReadings);

    //     // const correctDailyKwh = aggregateDailyKwh(newRawHourlyReadings, isThreePhase); // working accurate calculation for the daily kwh
    //     // console.log("Correct Daily Kwh", correctDailyKwh);
    //     const newDailyKWData = dailyKWHGraph(newRawHourlyReadings, startDate, endDate, isClamp1, isClamp2, isClamp3, isThreePhase);

    //     const newPeakData = calculatePeakData(formatted_hourly_data, isClamp1, isClamp2, isClamp3, startDate, endDate);

    //     const newDayNightSplit = calculateDayNightSplit(formatted_hourly_data, isClamp1, isClamp2, isClamp3, startDate, endDate, isThreePhase);

    //     setDailyKWData(newDailyKWData);
    //     setPeakData(newPeakData);
    //     setDayNightSplit(newDayNightSplit);


    // }, [isClamp1, isClamp2, isClamp3, newRawHourlyReadings, startDate, endDate, isThreePhase]);

    useEffect(() => {
        console.time('Worker Message Call');


        setIsLoadingGraphData(true);
        // const controller = abortController;
        const worker = new Worker(new URL('./dataWorker.js', import.meta.url));

        worker.onmessage = function (e) {
            const { newDailyKWData, newPeakData } = e.data;
            setDailyKWData(newDailyKWData);
            setPeakData(newPeakData);
            console.timeEnd('Worker Message Call');

            setProgress(100);
        };

        // worker.onmessage = function (e) {
        //     if (e.data.progress !== undefined) {
        //         // logger.log("SizingPage","Progress", e.data.progress);
        //     //   setProgress(e.data.progress);
        //     } else {
        //       const { newDailyKWData, newPeakData } = e.data;
        //       setDailyKWData(newDailyKWData);
        //       setPeakData(newPeakData);
        //       console.timeEnd('Worker Message Call');

        //     }
        //   };


        if (newRawHourlyReadings.length > 0) {
            worker.postMessage({
                newRawHourlyReadings,
                isThreePhase,
                startDate,
                endDate,
                isClamp1,
                isClamp2,
                isClamp3,
                // signal: controller.signal // Pass the abort signal to the worker
            });
        }

        return () => {
            worker.terminate();
        };

    }, [newRawHourlyReadings, isThreePhase, startDate, endDate, isClamp1, isClamp2, isClamp3]);


    useEffect(() => {
        if (dailyKWData.length > 0 && peakData.length > 0) {
            if (dailyKWData[0].x.length > 0 && dailyKWData[0].y.length > 0 && peakData[0].x.length > 0 && peakData[0].y.length > 0) {
                setIsLoadingGraphData(false);
            }
        }
        else {
            // setIsLoadingGraphData(true);
        }
    }
        , [dailyKWData, peakData]);

    //  This sums and fetches data we want to pass to the backend sizing calculation report system
    const prepareDataForReport = async () => {
        console.time('Prepare Data For Report');
        const token = localStorage.getItem("accessToken");
        const cleaned_startDate = startDate.startOf("day").format("YYYY-MM-DD");
        const cleaned_endDate = endDate.endOf("day").format("YYYY-MM-DD");
        const data = {
            monitor: selectedMonitorId,
            clientName: clientName,

            startDate: cleaned_startDate, // we pass date instead so it does fetch on backend, saving data
            endDate: cleaned_endDate,
            backupHoursBattery: batterySize,
            threePhase: isThreePhase,
            costFactorBattery: 0.15,
            inverterSize: inverterSize,
            systemType: systemType,
            fill: fillGaps,
            voltageType: voltageType,
            pvArraySize: solarSize,
            clampsStatus: clampsStatus,
            // selectedBattery: selectedBattery,
            // selectedInverter: selectedInverter,
            // selectedSolarPanel: selectedSolarPanel,

            inverterMake: selectedInverterMake,
            inverter: selectedInverter,
            batteryMake: selectedBatteryMake,
            battery: selectedBattery,
            solarMake: selectedSolarMake,
            solarPanel: selectedSolarPanel,
            credit_start_date: cleaned_startDate, // switch to start of year later but ok for now
            business_credits: businessCredits,
       


            // influx_data: currentMonitorData.data,
        };
        //  this is what is handled on the other side
        // data = json.loads(request.body)
        // clamps_data = data['clamps']
        // backup_hours_battery = data['backupHoursBattery']
        // three_phase = data['threePhase']
        // cost_factor_battery = data['costFactorBattery']
        // logger.log("SizingPage","Prepared Data", data);

        const reportt = await fetchSizingReport(token, data);
        console.timeEnd('Prepare Data For Report');
        // logger.log("SizingPage","Report Response: ", reportt);
        if (reportt['status'] == '400') {
            message.error("Error: " + reportt['error']);
        } else {
            message.success("Report Generated Successfully");
            setReportResponse(reportt['data']); // We just send all report data to the new stage
        }




    };

    const handleStartDateChange = (date) => {
        const dayjsDate = dayjs(date);
        if (dayjsDate.isValid()) {
            if (dayjsDate.isSameOrAfter(endDate, "day")) {
                message.error("Start date must be before the end date.");
            } else {
                setDateUpdated(prevState => !prevState);
                setIsDateChange(true);
           
                setStartDate(dayjsDate);
            }
        }
    };

    const handleEndDateChange = (date) => {
        const dayjsDate = dayjs(date);
        if (dayjsDate.isValid()) {
            if (dayjsDate.isSameOrBefore(startDate, "day")) {
                message.error("End date must be after the start date.");
            } else {
                setDateUpdated(prevState => !prevState);
                setIsDateChange(true);
         
                setEndDate(dayjsDate);
            }
        }
    };

    useEffect(() => {
        if (systemType === "hybrid" || systemType === "off-grid") {
            setIsSolar(true);
        } else {
            setIsSolar(false);
        }
    }, [systemType]);

    const handleSubmit = async (e) => {
        e.preventDefault();



        const data = {
            clientName,
            monitor: selectedMonitorId,
            monitorId: selectedMonitorId,
            phase,
            systemType,
            inverterMake: selectedInverterMake,
            inverter: selectedInverter,
            batteryMake: selectedBatteryMake,
            battery: selectedBattery,
            solarMake: selectedSolarMake,
            solarPanel: selectedSolarPanel,
            fillGaps,
            voltageType,
            batterySize,
            inverterSize,
            acceptTerms,
            startDate,
            endDate,
            clampsStatus, // Include the clampsStatus state
        };

        if (sizing_id) {
            data["_id"] = sizing_id;
        }

        // setFormData(data);
        const token = localStorage.getItem("accessToken");

        // basically we check if data has any changes, if not we dont save since we save bandwidth and we instead only generate report
        if (currentData && currentData != []) {
            const isDataSame = JSON.stringify(data) === JSON.stringify(currentData);

            if (!isDataSame) {
                await pushSizingProject(token, data);
                setCurrentData(data);

                setDidSubmitReportData(true);

                notification.success({
                    message: "Stage 1 - Setings Saved",
                    description: "Monitor Sizing form submitted successfully",
                    placement: "bottomRight",
                });


            } else {
                logger.log("SizingPage", "Data is same, didnt save");
            }
        } else {
            await pushSizingProject(token, data);
            setCurrentData(data);

            notification.success({
                message: "Stage 1 - Setings Saved",
                description: "Monitor Sizing form submitted successfully",
                placement: "bottomRight",
            });

        }

        // navigate('/projects');

        prepareDataForReport();
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );


    const incrementDataLoadingProgressBar = (totalSeconds) => {
        let ptg = 0;
        const interval = setInterval(() => {


            ptg += (100 / totalSeconds);
            if (ptg >= 99) {
                clearInterval(interval);
                setFetchDataProgress(99);
            } else {
                setFetchDataProgress(Math.round(ptg));

            }
        }, 1000); // Update every second
    };

    // Calculate the difference in days between start and end dates
    const dateDifference =
        startDate && endDate ? endDate.diff(startDate, "day") : null;

    // Check if there are at least one clamp selected
    const isAnyClampSelected =
        clampsStatus.clamp1.enabled || clampsStatus.clamp2.enabled || clampsStatus.clamp3.enabled;



    return (
        <div>
            {isLoadingGraphData && (
                <div>
                    <Modal
                        open={true}
                        footer={null}
                        closable={false}
                        centered
                        style={{ textAlign: 'center' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h2>Processing Request...</h2>

                            {fetchDataProgress === false && (
                                <Spin indicator={antIcon} />
                            )}
                            {fetchDataProgress !== false && (
                                <div>
                                    {dateDifference >= 14 ? (
                                        <p>Thank you for your patience! We are trying our best to process this large request...</p>
                                    ) : (
                                        <p>Thank you for your patience!</p>
                                    )}
                                    {/* <p className="subtitle_text">Estimated ~{dateDifference+10}s</p> */}

                                    <Progress percent={progress >= 50 ? 100 : fetchDataProgress} style={{ width: '100%', marginTop: '16px' }} />
                                    <br />
                                    {progress >= 50 && (
                                        <span>
                                            <p>Performing calculations...</p>
                                    <Spin  tip="Performing calculations..." />
                                    </span>
                                    )}
                                    <br />
                                    <br />
                                    <Button type="primary" onClick={handleCancel}>Cancel</Button>
                                </div>
                            )}

                        </div>
                    </Modal>
                    {/* <Spin spinning={isLoadingGraphData} tip={loadingTextTip} percent={'auto'} fullscreen /> */}
                    {/* <Spin spinning={isLoadingGraphData} tip={<div dangerouslySetInnerHTML={{ __html: loadingTextTip }} />} percent={'auto'} fullscreen /> */}
                    <div style={{ top: 0, left: 0, width: '100%', zIndex: 1000 }}>
                        <Progress percent={progress} showInfo={false} fullscreen />
                    </div>
                </div>

            )}


            {isDateChange && (
                <div>
                    <Spin indicator={antIcon} tip="Fetching New Dates..." fullscreen/>
                </div>
)}
            <h1>Project Sizing</h1>


            {noDataFound && (
                <Alert message="No data found for the selected monitor and date range. Please try another monitor, or make sure your credit balance is not too low" type="error" showIcon />
            )}


     

        


            {didSubmitReportData && (
                <Spin indicator={antIcon} tip="Submitting data... This may take a moment." fullscreen />

            )}


            <Modal
                title="Select the current clamps (CT) to include in your solar setup."
                visible={isClampsModalOpen}
                onCancel={closeClampsModal}
                onOk={closeClampsModal}
            >
                {Object.keys(clampsStatus).map((clampKey) => (
                    <div key={clampKey}>
                        <Input
                            placeholder="Clamp Name"
                            value={clampsStatus[clampKey].name}
                            onChange={(e) =>
                                setClampsStatus({
                                    ...clampsStatus,
                                    [clampKey]: {
                                        ...clampsStatus[clampKey],
                                        name: e.target.value,
                                    },
                                })
                            }
                        />
                        <br />
                        <Checkbox
                            checked={clampsStatus[clampKey].enabled}
                            onChange={(e) =>
                                setClampsStatus({
                                    ...clampsStatus,
                                    [clampKey]: {
                                        ...clampsStatus[clampKey],
                                        enabled: e.target.checked,
                                    },
                                })
                            }
                        >
                            Enabled
                        </Checkbox>
                        <Checkbox
                            checked={clampsStatus[clampKey].deduct_from_total}
                            onChange={(e) =>
                                setClampsStatus({
                                    ...clampsStatus,
                                    [clampKey]: {
                                        ...clampsStatus[clampKey],
                                        deduct_from_total: e.target.checked,
                                    },
                                })
                            }
                        >
                            Deduct from Total
                        </Checkbox>
                        <br />
                        <br />
                    </div>
                ))}
            </Modal>

            <form onSubmit={handleSubmit}>
                <Flex gap="middle" horizontal>
                    <div style={{ width: "60%" }}>
                        {/* <div className="wip-navbar">Graph is Work In Progress</div> */}
                        <div>
                            <p className="graph-label">
                                {isThreePhase ? "Three Phase" : null} Daily kWh for {dayjs(startDate).startOf("day").format("DD MMM")}{" "}
                                to {dayjs(endDate).endOf("day").format("DD MMM")}
                            </p>
                            <div>
                                {/* <pre>{JSON.stringify(dailyKWData)}</pre> */}
                                {!isLoadingGraphData && dailyKWData ? (
                                    <div id="graph2">

                                        <DailyKWHChart data={dailyKWData} userEnabledDarkMode={userEnabledDarkMode}/>
                                    </div>
                                ) : (
                                    <div>
                                        {/* <Spin indicator={antIcon} tip="Please wait, it's loading..."  /> */}
                                        <Skeleton active style={{ height: '400px' }} paragraph={{ rows: 12, }} />
                                    </div>
                                )}
                            </div>
                            <p className="graph-label">
                                Highest Peak Recorded Per Day kW for 00:00{" "}
                                {dayjs(startDate).startOf("day").format("DD MMM")} to 23:59{" "}
                                {dayjs(endDate).endOf("day").format("DD MMM")}
                            </p>
                            <p>{isThreePhase ? "This is the maximum of the 3 clamps, added together per day." : "This is the maximum of the clamps selected, added together per day"}</p>



                            <div>
                                {!isLoadingGraphData && peakData ? (
                                    <div id="graph3">

                                        <PeakDataChart data={peakData} userEnabledDarkMode={userEnabledDarkMode} />
                                    </div>
                                ) : (
                                    <div>
                                        {/* <Spin indicator={antIcon} /> */}
                                        <Skeleton active style={{ height: '400px' }} paragraph={{ rows: 12, }} />
                                    </div>
                                )}
                            </div>
                        </div>
                        {useFastAggregation ? (
                        <p className="subtitle_text">Note that this data was pre aggregated in 1 hour intervals. This will not impact sizing report calculations as for the sizing report we use minutely data for highest accuracy. This only serves to increase speed on this page. Feel free to adjust your preferences at anytime in the <Link to="/profile">Settings Page</Link></p>
                    ): (
                        <p className="subtitle_text">TIP: Want to increase loading speed for larger date ranges? You can enable the choose a faster aggregation method in the settings page! This data is currently fetched with minutely accuracy. Changing the aggregation size will not impact sizing report calculations as for the sizing report we use minutely data for highest accuracy anyway. This only serves to increase speed on this page. Feel free to adjust your preferences at anytime in the <Link to="/profile">Settings Page</Link></p>
                    )}
                        </div>
                    <div style={{ margin: "auto", marginTop: 0 }}>
                        <Flex gap="small">
                            <Input
                                placeholder="Client Name/Project"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value)}
                                required
                            />
                        </Flex>
                        <p>
                            Select your monitor, the date range and clamps. Monitoring graphs
                            will show.
                        </p>
                        <Flex gap="small">
                            {!loading ? (
                                business_monitors && business_monitors.length > 0 ? (
                                    <Select
                                        placeholder="Select an item"
                                        onChange={handleChange}
                                        showSearch
                                        value={selectedMonitorId}
                                        disabled={isLoadingGraphData}
                                    >
                                        {business_monitors.map((monitor) => (
                                            <Option key={monitor.id} value={monitor.id}>
                                                {monitor.displayName}
                                            </Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <p>No monitors available</p>
                                )
                            ) : (
                                <Spin indicator={antIcon} />
                            )}
                            <DatePicker value={startDate} onChange={handleStartDateChange} disabledDate={(current) => current && current > dayjs(endDate)} />
                            <DatePicker value={endDate} onChange={handleEndDateChange} disabledDate={(current) => current && current > dateCutoffEnd} />
                        </Flex>
                        <br />
                        {/* Here are tabs div element */}

                        {/* Here we add warnings */}

                        <div style={{ width: "680px" }}>
                            {dateDifference !== null && dateDifference < 14 && (
                                <div>
                                    <Alert
                                        message="Caution"
                                        description="Your monitoring period is not 2 weeks. This is fine but we recommend you monitor for 2 weeks or more."
                                        type="warning"
                                        showIcon
                                    />

                                    <br />
                                </div>
                            )}



                            <Flex gap="small">
                                <label
                                    style={{
                                        fontSize: "16px",
                                        color: "#888",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    }}
                                >
                                    Phase
                                </label>
                                <Radio.Group
                                    onChange={(e) => setPhase(e.target.value)}
                                    value={phase}
                                    disabled={isLoadingGraphData}
                                >
                                    <Radio.Button value={"single"}>
                                        <FontAwesomeIcon icon={fa1} /> SINGLE
                                    </Radio.Button>
                                    <Radio.Button value={"three"}>
                                        <FontAwesomeIcon icon={fa3} /> THREE
                                    </Radio.Button>
                                </Radio.Group>
                            </Flex>
                            <br />
                          
                            <Flex gap="small">
                                <label
                                    style={{
                                        fontSize: "16px",
                                        color: "#888",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    }}
                                >
                                    Voltage Type
                                </label>
                                <Radio.Group
                                    onChange={(e) => setVoltageType(e.target.value)}
                                    value={voltageType}
                                    disabled={isLoadingGraphData || !isThreePhase}
                                    
                                >
                                    <Radio.Button value={"HV"}>
                                        <FontAwesomeIcon icon={fa1} /> High Voltage
                                    </Radio.Button>
                                    <Radio.Button value={"LV"}>
                                        <FontAwesomeIcon icon={fa3} /> Low Voltage
                                    </Radio.Button>
                                </Radio.Group>
                            </Flex>
                            <br />

                            <ul className="horizontal-list">
                                <li>
                                    <Space size="small">
                                        <FontAwesomeIcon
                                            icon={
                                                clampsStatus["clamp1"]["enabled"]
                                                    ? faPlug
                                                    : faPlugCircleXmark
                                            }
                                        />
                                        {clampsStatus["clamp1"]["name"]}
                                        {clampsStatus["clamp1"]["deduct_from_total"] && (
                                            <FontAwesomeIcon
                                                icon={faMinus}
                                                style={{ float: "right" }}
                                            />
                                        )}
                                    </Space>
                                </li>
                                <li>
                                    <Space size="small">
                                        <FontAwesomeIcon
                                            icon={
                                                clampsStatus["clamp2"]["enabled"]
                                                    ? faPlug
                                                    : faPlugCircleXmark
                                            }
                                        />
                                        {clampsStatus["clamp2"]["name"]}
                                        {clampsStatus["clamp2"]["deduct_from_total"] && (
                                            <FontAwesomeIcon
                                                icon={faMinus}
                                                style={{ float: "right" }}
                                            />
                                        )}
                                    </Space>
                                </li>
                                <li>
                                    <Space size="small">
                                        <FontAwesomeIcon
                                            icon={
                                                clampsStatus["clamp3"]["enabled"]
                                                    ? faPlug
                                                    : faPlugCircleXmark
                                            }
                                        />
                                        {clampsStatus["clamp3"]["name"]}
                                        {clampsStatus["clamp3"]["deduct_from_total"] && (
                                            <FontAwesomeIcon
                                                icon={faMinus}
                                                style={{ float: "right" }}
                                            />
                                        )}
                                    </Space>
                                </li>
                            </ul>

                            {!isAnyClampSelected ? (

                                <div>
                                    <br />
                                    <Alert message="Error" description="No breakers are selected, the calculation will not work without a clamp selected." type="error" showIcon />

                                </div>
                            ) : null}

                            <br />
                            <Button type="primary" onClick={openClampsModal} disabled={isThreePhase || isLoadingGraphData}>
                                CHANGE CURRENT CLAMPS
                            </Button>
                            <br />

                            <br />

                            <Flex gap="small">
                                <label
                                    style={{
                                        fontSize: "16px",
                                        color: "#888",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    }}
                                >
                                    System Type
                                </label>
                                <Radio.Group
                                    onChange={(e) => setSystemType(e.target.value)}
                                    value={systemType}
                                    disabled={isLoadingGraphData}
                                >
                                    <Radio.Button value={"backup"}>
                                        <FontAwesomeIcon icon={faBatteryFull} /> BACKUP
                                    </Radio.Button>
                                    <Radio.Button value={"hybrid"}>
                                        <FontAwesomeIcon icon={faSun} /> HYBRID/OFF GRID
                                    </Radio.Button>
                                </Radio.Group>
                            </Flex>
                            <br />
                            <div>
                                <Flex gap="small">
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            color: "#888",
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                        }}
                                    >
                                        Inverter Make
                                    </label>

                                    <Select
                                        placeholder="Select Inverter Make"
                                        onChange={(value) => setSelectedInverterMake(value)}
                                        value={selectedInverterMake}
                                        required
                                        disabled={isLoadingGraphData}
                                    >
                                        {Object.keys(componentsData.inverters).map((make) => (
                                            <Option key={make} value={make}>
                                                {make}
                                            </Option>
                                        ))}
                                    </Select>
                                </Flex>
                                <br />
                                {/* {selectedInverterMake && (
                                    <Flex gap="small">
                                        <label
                                            style={{
                                                fontSize: "16px",
                                                color: "#888",
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                            }}
                                        >
                                            Inverter
                                        </label>
                                        <Select
                                            placeholder="Select Inverter"
                                            onChange={(value) => setSelectedInverter(value)}
                                            value={selectedInverter}
                                            required
                                        >
                                            {componentsData.inverters[selectedInverterMake].map(
                                                (inverter) => (
                                                    <Option key={inverter._id} value={inverter._id}>
                                                        {inverter.name}
                                                    </Option>
                                                )
                                            )}
                                        </Select>
                                    </Flex>
                                )} */}
                                {/* <br /> */}
                                <Flex gap="small">
                                    <label
                                        style={{
                                            fontSize: "16px",
                                            color: "#888",
                                            marginTop: "auto",
                                            marginBottom: "auto",
                                        }}
                                    >
                                        Battery Make
                                    </label>
                                    <Select
                                        placeholder="Select Battery Make"
                                        onChange={(value) => setSelectedBatteryMake(value)}
                                        value={selectedBatteryMake}
                                        required
                                        disabled={isLoadingGraphData}
                                    >
                                        {Object.keys(componentsData.batteries).map((make) => (
                                            <Option key={make} value={make}>
                                                {make}
                                            </Option>
                                        ))}
                                    </Select>
                                </Flex>
                                <br />
                                {/* {selectedBatteryMake && (
                                    <Flex gap="small">
                                        <label
                                            style={{
                                                fontSize: "16px",
                                                color: "#888",
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                            }}
                                        >
                                            Battery
                                        </label>
                                        <Select
                                            placeholder="Select Battery"
                                            onChange={(value) => setSelectedBattery(value)}
                                            value={selectedBattery}
                                            required
                                        >
                                            {componentsData.batteries[selectedBatteryMake].map(
                                                (battery) => (
                                                    <Option key={battery._id} value={battery._id}>
                                                        {battery.name}
                                                    </Option>
                                                )
                                            )}
                                        </Select>
                                    </Flex>
                                )} */}

                                {isSolar && (
                                    <div>
                                        {/* <br /> */}
                                        <Flex gap="small">
                                            <label
                                                style={{
                                                    fontSize: "16px",
                                                    color: "#888",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                }}
                                            >
                                                Solar Make
                                            </label>
                                            <Select
                                                placeholder="Select the Solar make"
                                                onChange={(value) => setSelectedSolarMake(value)}
                                                value={selectedSolarMake}
                                                required
                                                disabled={isLoadingGraphData}
                                            >
                                                {Object.keys(componentsData.panels).map((make) => (
                                                    <Option key={make} value={make}>
                                                        {make}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Flex>
                                        <br />
                                    </div>
                                )}

                                {/* {selectedSolarMake && isSolar && (
                                    <Flex gap="small">
                                        <label
                                            style={{
                                                fontSize: "16px",
                                                color: "#888",
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                            }}
                                        >
                                            Solar Panel
                                        </label>
                                        <Select
                                            placeholder="Select Solar Panel"
                                            onChange={(value) => setSelectedSolarPanel(value)}
                                            value={selectedSolarPanel}
                                            required
                                        >
                                            {componentsData.panels[selectedSolarMake].map(
                                                (solar_panel) => (
                                                    <Option key={solar_panel._id} value={solar_panel._id}>
                                                        {solar_panel.name}
                                                    </Option>
                                                )
                                            )}
                                        </Select>
                                    </Flex>
                                )} */}
                            </div>
                         

                            <br />
                            <Flex gap="small">
                                <label
                                    style={{
                                        fontSize: "16px",
                                        color: "#888",
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    }}
                                >
                                    Fill Gaps{" "}
                                    <Tooltip title="Fill gaps for example when there is loadshedding, signal or power failures. Uses a basic linear algorithm.">
                                        <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                            style={{ marginLeft: "8px" }}
                                        />
                                    </Tooltip>
                                </label>
                                <Radio.Group
                                    onChange={(e) => setFillGaps(e.target.value)}
                                    value={fillGaps}
                                    disabled={isLoadingGraphData}
                                >
                                    <Radio.Button value={"fill"}>
                                        <FontAwesomeIcon icon={faList} /> FILL
                                    </Radio.Button>
                                    <Radio.Button value={"no fill"}>NO FILL</Radio.Button>
                                </Radio.Group>
                            </Flex>
                            <br />
                            <Button type="primary" disabled>
                                ADVANCED SETTINGS
                            </Button>

                            <p>
                                Battery Bank Size - {batterySize}h{" "}
                                <Tooltip title="Backup hours required when there is no solar power or grid power is down">
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ marginLeft: "8px" }}
                                    />
                                </Tooltip>
                            </p>
                            <Slider
                                value={batterySize}
                                min={1}
                                max={24}
                                onChange={(value) => setBatterySize(value)}
                                disabled={isLoadingGraphData}
                            />

                            <p>
                                Inverter Size - {inverterSize}%{" "}
                                <Tooltip title="You can oversize this parameter. 100% means inverter size equal to highest recorded consumption peak">
                                    <FontAwesomeIcon
                                        icon={faQuestionCircle}
                                        style={{ marginLeft: "8px" }}
                                    />
                                </Tooltip>
                            </p>
                            <Slider
                                value={inverterSize}
                                min={0}
                                max={150}
                                step={10}
                                onChange={(value) => setInverterSize(value)}
                                disabled={isLoadingGraphData}
                            />

                            {isSolar && (
                                <div>
                                    <p>
                                        Solar array size - {solarSize}%{" "}
                                        <Tooltip title="You can oversize this parameter. Solar Generation to Energy Consumption Ratio. 100% covers average daily consumption. With the defaults (location: Johannesburg, azimuth: 0, tilt: 20) the calculation will be based on 5.9 hours of sunlight a day. Oversize if you have less sunlight.">
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                                style={{ marginLeft: "8px" }}
                                            />
                                        </Tooltip>
                                    </p>
                                    <p className="subtitle_text">
                                        (100% = 5.9 hours/day if location is JHB, go above 100% if
                                        you have less sunlight)
                                    </p>
                                    <Slider
                                        value={solarSize}
                                        min={0}
                                        max={200}
                                        step={10}
                                        onChange={(value) => setSolarSize(value)}
                                        disabled={isLoadingGraphData}
                                    />
                                    <br />
                                </div>
                            )}
                        </div>

                        <Checkbox
                            checked={acceptTerms}
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                            disabled={isLoadingGraphData}
                        >
                            Accept Terms and Conditions
                        </Checkbox>
                        <br />
                        <br />
                        <a href="#">Terms and Conditions</a>

                        <br />
                        <br />

                        <Button
                            type="primary"
                            disabled={
                                !acceptTerms ||
                                isLoadingGraphData ||
                                !selectedInverterMake ||
                                !selectedBatteryMake ||
                                !clientName ||
                                !selectedMonitorId ||
                                !isAnyClampSelected ||
                                (isSolar &&
                                    (!solarSize || !selectedSolarMake))
                            }
                            htmlType="submit"
                        >
                            GENERATE SIZING
                        </Button>
                        {(!acceptTerms ||
                            !selectedInverterMake ||
                            !selectedBatteryMake ||
                            !clientName ||
                            !selectedMonitorId) && (
                                <p style={{ color: "red" }}>
                                    Please fill all fields before you can proceed.
                                </p>
                            )}
                    </div>
                </Flex>
            </form>
        </div>
    );
};

Sizing.propTypes = {
    setReportResponse: PropTypes.func.isRequired,
    userEnabledDarkMode: PropTypes.bool.isRequired,
};



export default Sizing;
