import axios from 'axios';
import { api_endpoint } from '../components/apiConfig';
import { handleError } from '../utils/errorhandler';

// import CryptoJS from 'crypto-js';


import logger from '../utils/logger';
logger.enableLogging('apiService');

// const SECRET_KEY = 'zZ0cM020e0qktn_EIEybnXk4COxE0CcgNisvC5-6UEA=';


// export const encrypt_value = (value) => {
//     if (!SECRET_KEY) {
//         return value;
//     }
//     if (typeof value === 'string') {
//         return CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
//     } else if (typeof value === 'object' && !Array.isArray(value)) {
//         const encryptedObject = {};
//         for (const [key, val] of Object.entries(value)) {
//             encryptedObject[key] = encrypt_value(val);
//         }
//         return encryptedObject;
//     } else if (Array.isArray(value)) {
//         return value.map(item => encrypt_value(item));
//     }
//     return value;
// };

// export const decrypt_value = (value) => {
//     if (!SECRET_KEY) {
//         return value;
//     }
//     if (typeof value === 'string') {
//         const bytes = CryptoJS.AES.decrypt(value, SECRET_KEY);
//         return bytes.toString(CryptoJS.enc.Utf8);
//     } else if (typeof value === 'object' && !Array.isArray(value)) {
//         const decryptedObject = {};
//         for (const [key, val] of Object.entries(value)) {
//             decryptedObject[key] = decrypt_value(val);
//         }
//         return decryptedObject;
//     } else if (Array.isArray(value)) {
//         return value.map(item => decrypt_value(item));
//     }
//     return value;
// };


export const claimVoucherCode = async (token, voucher_code) => {
    try {
        const response = await axios.post(api_endpoint + '/api/claimVoucher', { voucher_code }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        handleError(error);
    }
};
            


export const confirmResetToken = async (token) => {
    try {
        const response = await axios.get(`${api_endpoint}/api/confirmResetToken`, {
            params: { token },
        });
        return response.data;
    } catch (error) {
        console.error('Error confirming reset token:', error);
        throw error;
    }
};


export const setNewPassword = async (token, newPassword) => {
    try {
        const response = await axios.post(`${api_endpoint}/api/setNewPassword`, { token, newPassword });
        return response.data;
    } catch (error) {
        console.error('Error resetting password:', error);
        throw error;
    }
};


export const resetPassword = async (email) => {
    try {
        const response = await axios.post(`${api_endpoint}/api/resetPassword`, { email });
        // console.log('Reset link sent:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error sending reset link:', error);
        return { status: 'failure', error: error.response ? error.response.data : error.message };
    }
};


export const confirmEmail = async (token) => {
    try {
        const response = await axios.get(`${api_endpoint}/api/confirmEmail`, {
            params: { token },
        });
        return response.data;
    } catch (error) {
        console.error('Error confirming email:', error);
        throw error;
    }
};




export const loginUser = async (values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/login', values, {
            body: values,
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.error('User not verified:', error.response.data);
            return { status: 'unauthorized', message: 'User not verified' };
        } else {
            console.error('Error logging user:', error);
            return { status: 'failure', message: 'An error occurred during login' };
        }
    }
};


export const registerUser = async (userData) => {
    try {
        const response = await axios.post(api_endpoint + '/api/register', userData);
        // console.log('Registration successful:', response.data);
        return response.data;
    } catch (error) {
        console.error('Registration failed:', error.response.data);
        return error.response.data;
    }
};

export const refreshToken = async () => {
    try {
        const response = await axios.post(api_endpoint + '/api/refreshToken', {}, {
            withCredentials: true
        });
        return response.data.access_token; // Assuming the new token is returned in the 'access_token' field
    } catch (error) {
        handleError(error);
    }
};
 

export const confirmTransaction = async (token, reference) => {
    try {
        const response = await axios.post(`${api_endpoint}/api/confirmTransaction`, reference, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error confirming transaction:', error);
        throw error;
    }
};


// export const fetchTransactions = async (token) => {
//     try {
//         const response = await axios.get(api_endpoint + '/api/fetch_transactions', {
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             }
//         });
//         return response.data['data'];
//     } catch (error) {
//         handleError(error);
//     }
// };

export const fetchCreditStartPoint = async (token) => {
    try {
        const response = await axios.get(api_endpoint + '/api/creditStartPoint', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data['data'];
    } catch (error) {
        handleError(error);
    }
};

export const fetchCreditBalance = async (token) => {
    try {
        const response = await axios.get(api_endpoint + '/api/creditBalance', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data['data'];
    } catch (error) {
        handleError(error);
    }
};



export const fetchUserData = async (token) => {
    try {
        const response = await axios.get(api_endpoint + '/api/getUserData', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return { data: response.data['data'] };
    } catch (error) {
        return { status: 401 };
    }
};

export const registerMonitorToAccount = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/registerMonitor', values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}




export const inviteUserToBusiness = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/inviteUserToBusiness', values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export const confirmInvite = async (token) => {
    try {
        const response = await axios.get(`${api_endpoint}/api/confirmInvite`, {
            params: { token },
        });
        return response.data;
    } catch (error) {
        console.error('Error confirming invite:', error);
        throw error;
    }
};


// Can now pass true to only get online monitors
export const fetchMonitors = async (token, onlineOnly = false) => {
    try {
        const response = await axios.get(api_endpoint + '/api/monitors', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                online_only: onlineOnly
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}

export const fetchMonitorsFast = async (token) => {
    try {
        const response = await axios.get(api_endpoint + '/api/fetchMonitorsFast', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}


export const fetchNetwork = async (network_id, access_code) => {
    try {
        const response = await axios.get(api_endpoint + '/api/getNetwork', {
            headers: {
                "Access-Code": access_code // Include the access code in the headers
            },
            params: {
                network_id: network_id
            }
        });
        return response.data['data'];
    }
    catch (error) {
        console.error('Error fetching network:', error);
        throw error; // Re-throw the error to handle it in the calling function
    }
}


export const createNetwork = async (token, network_data) => {
    try {
        const response = await axios.post(api_endpoint + '/api/networks', network_data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data['network_id'];
        
    }
    catch (error) {
        handleError(error);
    }
}

// fetches the groupings of monitors for publishing
export const fetchNetworks = async (token) => {
    try {
        const response = await axios.get(api_endpoint + '/api/networks', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}

export const removeMonitorFromAccount = async (token, monitor_id) => {
    try {
        const response = await axios.delete(api_endpoint + '/api/removeMonitor', {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                monitor_id: monitor_id
            }   
           
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export const deleteNetwork = async (token, network_id) => {
    try {
        const response = await axios.delete(api_endpoint + '/api/deleteNetwork', {
            headers: {
                "Authorization": `Bearer ${token}`

            },
            data: {
                _id: network_id
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export const pushUserData = async (token, values) => {
    // console.log("vals", values);
    try {
        const response = await axios.post(api_endpoint + '/api/updateUserData', values, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export const pushBusinessData = async (token, values) => {
    // console.log("vals", values);
    values['updated'] = Date.now();
    try {
        const response = await axios.post(api_endpoint + '/api/updateBusinessData', values, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export const fetchLeads = async (token) => {

    try {
        const response = await axios.get(api_endpoint + '/api/leads', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}


export const fetchCategories = async (token) => {

    try {
        const response = await axios.get(api_endpoint + '/api/categories', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}

export const fetchProductById = async (token, product_id) => {
    try {
        const response = await axios.get(`${api_endpoint}/api/product`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                product: product_id
            }
        });
        return response.data['data'];
    } catch (error) {
        handleError(error);
    }
}

export const fetchProducts = async (token, category) => {

    try {
        const response = await axios.get(api_endpoint + '/api/products', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                category: category
            }
        });
        return response.data['data'];
    }

    catch (error) {
        handleError(error);
    }
}

export const fetchOrders = async (token) => {

    try {
        const response = await axios.get(api_endpoint + '/api/orders', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}



export const fetchProjects = async (token) => {

    try {
        const response = await axios.get(api_endpoint + '/api/projects', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}


export const fetchBusinessUser = async (token, customErrorHandler) => {
    
        try {
            const response = await axios.get(api_endpoint + '/api/getBusinessUser', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data['data'];
        }
        catch (error) {
            handleError(error, customErrorHandler);
            // throw error; // if we want to handle it differently
        }
    }

export const registerBusiness = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/registerBusiness', values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response;
    }
    catch (error) {
        handleError(error);
    }
}


export const fetchFeedback = async (token) => {
    try {
        const response = await axios.get(api_endpoint + '/api/feedback', { 
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}

export const fetchSystemTickets = async (token) => {
    try {
        const response = await axios.get(api_endpoint + '/api/systemTickets', { 
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}

export const addFeedbackComment = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/addFeedbackComment', values, {
            headers: {
                "Authorization": `Bearer ${token}`  
            },
           
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export const closeTicket = async (token, ticketId, reason) => {
    try {
        const response = await axios.delete(api_endpoint + '/api/closeFeedback', {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                ticketId: ticketId,
                reason: reason
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}



export const pushFeedback = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/submitFeedback', values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        if (error.response && error.response.status === 429) {
            return { error: 'Too many requests, please try again later.' };
        } else {
            console.error('Error pushing feedback:', error);
            return { error: 'An error occurred while pushing feedback.' };
        }
    }
}


export const pushSizingProject = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/sizingProject', values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


export const pushQuickSizingProject = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/quickSizing', values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


export const fetchQuickSizingProject = async (token, sizingId) => {
    try {
        const response = await axios.get(api_endpoint + '/api/fetchQuickSizingProject', {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            params: {
                sizingId: sizingId
            }
            
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


export const fetchSizingProject = async (token, sizingId) => {
    try {
        const response = await axios.get(api_endpoint + '/api/fetchSizingProject', {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            params: {
                sizingId: sizingId
            }

        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


export const updateSizingProject = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/updateSizingProject', values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}



export const deleteProject = async (token, data) => {
    try {
        const response = await axios.delete(api_endpoint + '/api/projects', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: {
                id: data.id,
                sizingId: data.sizingId,
                sizingtype: data.sizingtype
            }
        });
        return response.data;
    } catch (error) {
        handleError(error);
    }
}

// We need start and end times as well
// 2024-07-28T22:00:00.000Z format
export const fetchMonitorData = async (token, date, monitor_id) => {
    try {
        const response = await axios.get(api_endpoint + '/api/monitorData', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                'date': date,
                'monitor_id': monitor_id
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}

export const fetchAggregatedMonitorData = async (token, start_date, end_date, monitor_id, business_credits, useFastAggregation = false, options = {}) => {
    try {
        const response = await axios.get(api_endpoint + '/api/aggregatedMonitorData', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                'start_date': start_date,
                'end_date': end_date,
                'monitor_id': monitor_id,
                'business_credits': business_credits,
                'credit_start_date': start_date,
                'useFastAggregation': useFastAggregation
            },
            ...options
        });
        console.log("RESPONSE", response.data);

        

        return response.data['data'];
    }
    catch (error) {
        if (axios.isCancel(error)) {
            // console.log('Request canceled', error.message);
        } else {
            handleError(error);
        }
        return undefined;
    }
}

export const fetchMonitorStatus = async (token, monitor_id) => {
    try {
        const response = await axios.get(api_endpoint + '/api/fetchMonitorStatus', {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            params: {
                'monitor_id': monitor_id
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}



// We send data to get sizing report
export const fetchSizingReport = async (token, data) => {
    try {
        const response = await axios.post(api_endpoint + '/api/sizingReport', data, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
         
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export const fetchQuickSizingReport = async (token, data) => {
    try {
        const response = await axios.post(api_endpoint + '/api/quickSizingReport', data, {
            headers: {
                "Authorization": `Bearer ${token}`
            },

        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}

export const fetchComponents = async (token) => {
    try {
        const response = await axios.get(api_endpoint + '/api/fetchComponents', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data['data'];
    }
    catch (error) {
        handleError(error);
    }
}

export const pushComponent = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/updateComponent', values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}

export const deleteComponent = async (token, component_id, component_type) => {
    try {
        const response = await axios.delete(api_endpoint + '/api/deleteComponent', {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                component_id: component_id,
                component_type: component_type
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}




export const pushOrder = async (token, values) => {
    try {
        const response = await axios.post(api_endpoint + '/api/placeOrder', values, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    }
    catch (error) {
        handleError(error);
    }
}


// Since we can just store it locally for now. Since we dont plan on sending notifications. Also means less web requests which is faster and better for resources
export const pushToCart = async (item_values) => {


    let cart_template = {
            "user":'somelongid',
            "business":'somebusinessid',
            "items": [
                
            ]
        };

        let item_template = {
            "id": "someproductid",
            "quantity": 1,
            "stock": 10,
            "price": 100,
            "code": "ABC123",
            "description": "Some product description"
        };

    try{
        let cartData = JSON.parse(localStorage.getItem('cart'));
        if (cartData === null){
            cartData = cart_template;
        }
        let tmp_item = item_template;
        tmp_item.id = item_values.productID;
        // tmp_item.quantity = item_values.quantity;
        tmp_item.stock = item_values.stock;
        tmp_item.price = item_values.price;
        tmp_item.code = item_values.code;
        tmp_item.description = item_values.description;
        console.log("MADE ITEM", tmp_item);
        cartData.items.push(tmp_item);
        localStorage.setItem('cart', JSON.stringify(cartData));
    }
    catch (error) {
        console.error('Error adding to cart:', error);
    }
}



export const unauthed_test = async () => {
    // Simulate a 401 error
    const error = new Error('Unauthorized');
    error.response = { status: 401 };
    throw error;
};