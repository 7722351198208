import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Button, Slider } from 'antd';

const SolarPanelAngleTool = () => {
    const mountRef = useRef(null);
    const panelRef = useRef(null);

    useEffect(() => {
        // Initialize scene, camera, and renderer
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xffffff); // White background

        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        // Orbit controls for interactivity
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;

        // Add a grid to the scene
        const gridHelper = new THREE.GridHelper(100, 100);
        scene.add(gridHelper);

        // Add a flat plane for the Google image
        const planeGeometry = new THREE.PlaneGeometry(10, 10);
        const planeMaterial = new THREE.MeshBasicMaterial({ color: 0xf0f0f0, side: THREE.DoubleSide });
        const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        plane.rotation.x = -Math.PI / 2; // Make it horizontal
        scene.add(plane);

        // Load Google image texture onto the plane (replace with actual image URL)
        const loader = new THREE.TextureLoader();
        loader.load('path/to/your/google-image.jpg', (texture) => {
            plane.material.map = texture;
            plane.material.needsUpdate = true;
        });

        // Add solar panel (box) to the scene
        const geometry = new THREE.BoxGeometry(1, 0.1, 2);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const panel = new THREE.Mesh(geometry, material);
        panelRef.current = panel;
        scene.add(panel);

        // Add a directional light
        const light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(5, 5, 5).normalize();
        scene.add(light);

        // Set camera position
        camera.position.set(5, 5, 5);
        camera.lookAt(0, 0, 0);

        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };
        animate();

        // Clean up on component unmount
        const currentMount = mountRef.current;
        return () => {
            if (currentMount) {
                currentMount.removeChild(renderer.domElement);
            }
        };
    }, []);

    const handleAngleChange = (value) => {
        if (panelRef.current) {
            panelRef.current.rotation.x = THREE.MathUtils.degToRad(value);
        }
    };

    return (
        <div>
            <div ref={mountRef} style={{ width: '100%', height: '80vh' }} />
            <div style={{ padding: '20px' }}>
                <Slider
                    min={0}
                    max={90}
                    onChange={handleAngleChange}
                    defaultValue={0}
                    tooltipVisible
                />
                <Button type="primary" onClick={() => console.log('Calculate angle')}>Calculate Angle</Button>
            </div>
        </div>
    );
};

export default SolarPanelAngleTool;