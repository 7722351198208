// frontend/src/components/charts/WeeklySimulationChart.js
import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { Skeleton } from 'antd';
import withDynamicWidth from './withDynamicWidth';
import withTheme from './withTheme';

const WeeklySimulationChart = ({ weeklyUsageSimulation, width, themeLayout }) => {

  if (!weeklyUsageSimulation) {
    return (<div>
        <h3>Weekly Usage Simulation</h3>
        <Skeleton height={400} width={800} />
      </div>);
  }

  const { hoursOfWeek, hourlyUseKw, pvGenerationKw } = weeklyUsageSimulation;

    // Show labels at intervals to avoid overlapping
    const tickInterval = 12; // Show label every 24 hours (once per day)
    const tickvals = hoursOfWeek.filter((_, index) => index % tickInterval === 0);
    const ticktext = tickvals.map((val) => val);

    
  return (
    <div >
      <Plot
        data={[
          { 
            x: hoursOfWeek,
            y: hourlyUseKw,
            type: 'scatter', 
            mode: 'lines',
            name: 'Hourly Usage',
            line: { color: '#1F77B4', width: 2, shape: 'line' },
          },
          {
            x: hoursOfWeek,
            y: pvGenerationKw, 
            type: 'scatter',
            mode: 'lines',
            name: 'Hourly PV Generation',
            line: { color: '#F1E15B', width: 2, shape: 'line' },
          },
        ]}
        layout={{
          title: 'Hourly PV Generation and Usage Over the Week',
          xaxis: {
            title: 'Hours',
            tickmode: 'array',
            tickvals: tickvals,
            ticktext: ticktext,
            tickangle: 0,
          },
          yaxis: {
            title: 'kW',
            range: [0, Math.max(...pvGenerationKw, ...hourlyUseKw) * 1.2], // Add 20% padding
          },
          width: width,
          height: 400, // Set a fixed height
          ...themeLayout,
        }}
        config={{ responsive: true }}
      />
    </div>
  );
};

WeeklySimulationChart.propTypes = {
  weeklyUsageSimulation: PropTypes.object,
  width: PropTypes.number.isRequired,
  themeLayout: PropTypes.object.isRequired,
};

export default withDynamicWidth(withTheme(WeeklySimulationChart));