import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import withDynamicWidth from './withDynamicWidth';

const PeakDataChart = ({ data, width }) => {
    return (
        <div>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        <Plot
            data={data}
            layout={{
                width: width,
                height: 400, // Set a fixed height
                xaxis: { title: { font: { size: 18 } }, showgrid: false, zeroline: false, tickformat: '%d %b %Y', dtick: 86400000 },
                yaxis: { title: { text: 'kW', font: { size: 18 } }, showgrid: true, zeroline: true },
                plot_bgcolor: '#ffffff',
                barmode: 'stack',
                paper_bgcolor: '#ffffff',
                scrollZoom: true,
            }}
        />
        </div>
    );
};

PeakDataChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
};

export default withDynamicWidth(PeakDataChart);