import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import withDynamicWidth from './withDynamicWidth';
import withTheme from './withTheme';

const HourlyKwChart = ({ data, width, themeLayout }) => {
  return (
    <Plot
      data={data}
      layout={{
        width: width,
        height: 400, // Set a fixed height
        xaxis: { title: { font: { size: 18 } }, showgrid: true, zeroline: false, tickformat: '%d %b %Y', dtick: 86400000 },
        yaxis: { title: { text: 'kW', font: { size: 18 } }, showgrid: true, zeroline: true },
        scrollZoom: true,
        ...themeLayout,
      }}
    />
  );
};

HourlyKwChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  themeLayout: PropTypes.object.isRequired,
};

export default withDynamicWidth(withTheme(HourlyKwChart));