import React, { useEffect, useState } from "react";
import { Modal, List, Input, Button, Typography, Avatar } from "antd";
import propTypes from "prop-types";
import dayjs from "dayjs";
import { toSvg } from "jdenticon";

const { TextArea } = Input;
const { Text } = Typography;

const CommentsModal = ({
  comments,
  visible,
  onClose,
  onAddComment,
  currentUserId,
  ticket,
}) => {
  const [newComment, setNewComment] = useState("");
  const [isReplyDisabled, setIsReplyDisabled] = useState(false);

  const handleAddComment = () => {
    if (newComment.trim()) {
      onAddComment(newComment);
      setNewComment("");
    }
  };

  useEffect(() => {
    comments.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    if (
      comments.length > 0 &&
      comments[comments.length - 1].user_id === currentUserId
    ) {
      setIsReplyDisabled(true);
    } else {
      setIsReplyDisabled(false);
    }
  }, [comments, currentUserId]);

  return (
    <Modal
      title="Ticket Details"
      open={visible}
      onCancel={onClose}
      footer={null}
      style={{ top: 20 }}
    >
      <Text>Type:</Text>
      <Text strong> {ticket.feedback?.type}</Text>
      <br />
      <Text>User:</Text>
      <Text strong> {ticket?.user_id}</Text>
      <br />
      <Text>Subject:</Text>
      <Text strong> {ticket.feedback?.subject}</Text>
      <br />
      <Text>Description:</Text>
      <Text strong> {ticket.feedback?.description}</Text>
      <br />
      <Text>Submitted:</Text>
      <Text strong>
        {" "}
        {dayjs(ticket.created_at).format("DD MMM YYYY, h:mm A")}
      </Text>
      <hr />
      <Text strong>Comments</Text>
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <List
        itemLayout="vertical"
        dataSource={comments}
        locale={{ emptyText: 'No comments. Feel free to comment to add additional information or context' }}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    toSvg(item.user_id, 50)
                  )}`}
                />
              }
              title={<Text strong>{item.name}</Text>}
              description={dayjs(item.timestamp).format("DD MMM YYYY, h:mm A")}
            />
            <Text>{item.comment}</Text>
          </List.Item>
        )}
        style={{ marginBottom: "20px" }}
      />
      </div>
      {ticket.status === "closed" ? (
        <Text type="danger">
          Ticket was closed at{" "}
          {dayjs(ticket.updated_at).format("DD MMM YYYY, h:mm A")}. You cannot
          add comments.
        </Text>
      ) : (
        <div>
          {isReplyDisabled ? (
            <Text type="secondary">
              You have already replied to this ticket. Please wait for a
              response first
            </Text>
          ) : (
            <div>
            <TextArea
            rows={4}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment"
            style={{ marginBottom: "10px" }}
            disabled={isReplyDisabled}
          />

          <Button
            type="primary"
            onClick={handleAddComment}
            block
            disabled={isReplyDisabled}
          >
            Reply
          </Button>
            </div>
          )}

          
          {/* <pre>{JSON.stringify(ticket, null, 2)}</pre> */}
        </div>
      )}
    </Modal>
  );
};

CommentsModal.propTypes = {
  comments: propTypes.arrayOf(
    propTypes.shape({
      user_id: propTypes.string,
      comment: propTypes.string,
      timestamp: propTypes.string,
    })
  ).isRequired,
  visible: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onAddComment: propTypes.func.isRequired,
  currentUserId: propTypes.string.isRequired,
  ticket: propTypes.shape({
    feedback: propTypes.shape({
      type: propTypes.string,
      subject: propTypes.string,
      description: propTypes.string,
    }),
    user_id: propTypes.string,
    created_at: propTypes.string,
    status: propTypes.string,
    updated_at: propTypes.string,
  }).isRequired,
};

export default CommentsModal;
