import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { resetPassword } from '../services/apiService'; // Adjust the import path as necessary

const Forgot = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        setLoading(true);

        try {
            const response = await resetPassword(values.email);
            if (response.status === 'success') {
                message.success('Reset link sent successfully. Please check your email.');
                navigate('/login');
            } else {
                message.error('Failed to send reset link. ' + response.errors);
            }
        } catch (error) {
            console.error('Failed to send reset link:', error);
            message.error('Failed to send reset link: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <h1>Forgot Password</h1>
            <Form onFinish={onFinish} style={{ maxWidth: '300px' }}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please enter a valid email!' }]}
                >
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        Send Reset Link
                    </Button>
                </Form.Item>
            </Form>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to="/login">
                    <Button type="link">Back to Login</Button>
                </Link>
            </div>
        </div>
    );
};

export default Forgot;