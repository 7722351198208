import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Button, Form, notification } from 'antd';
import { fetchNetwork } from '../services/apiService'; // Ensure this import is correct

function PublicMonitor() {
    const { network_id } = useParams();
    const [network, setNetwork] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [accessCode, setAccessCode] = useState('');
    const [isAccessGranted, setIsAccessGranted] = useState(false);

    const handleAccessCodeSubmit = async () => {
        setLoading(true);
        try {
          
            const networkData = await fetchNetwork(network_id, accessCode);
            setNetwork(networkData);
            setIsAccessGranted(true);
            setLoading(false);
            notification.success({
                message: 'Access Granted',
                description: 'You have successfully entered the correct access code.',
            });
        } catch (err) {
            setError(err);
            setLoading(false);
            notification.error({
                message: 'Access Denied',
                description: 'The access code you entered is incorrect.',
            });
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!isAccessGranted) {
        return (
            <div>
                <h1>Enter Access Code</h1>
                <Form onFinish={handleAccessCodeSubmit}>
                    <Form.Item
                        label="Access Code"
                        name="accessCode"
                        rules={[{ required: true, message: 'Please input the access code!' }]}
                    >
                        <Input.Password value={accessCode} onChange={(e) => setAccessCode(e.target.value)} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }

    return (
        <div style={{margin:'20px'}}>
            
            {network ? (
                <div>
                    <h1>Network: {network.name}</h1>
                    <p>{network.description}</p>
                   
                    <p>This is a publically accessable page to all with the access code. TODO is to add the monitors graphs and make the dashboard</p>
                </div>
            ) : (
                <p>Network not found.</p>
            )}
        </div>
    );
}

export default PublicMonitor;