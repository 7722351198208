// frontend/src/utils/errorHandler.js
import { message } from 'antd';

export const handleError = (error, customHandler) => {
    if (customHandler) {
        customHandler(error);
        return;
    }

    if (error.response) {
        // Server responded with a status other than 200 range
        if (error.response.status === 401) {
            // Handle unauthorized access
            message.error('Invalid or Expired Session. Please log in again.');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            window.location.href = '/login'; // Redirect to login page
        } else if (error.response.status === 403) {
            message.error('Forbidden access.');
        } else if (error.response.status === 404) {
            message.error('Resource not found.');
        } else {
            message.error(`Error: ${error.response.status} - ${error.response.data.message}`);
        }
    } else if (error.request) {
        // Request was made but no response received
        message.error('No response from server. Please try again later.');
    } else {
        // Something else happened while setting up the request
        message.error(`Error: ${error.message}`);
    }
};