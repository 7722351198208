import React from 'react';
import PropTypes from 'prop-types';

const SmallText = ({ children }) => {
    return (
        <p style={{ fontSize: '15px' }}>
            {children}
        </p>
    );
};

SmallText.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SmallText;