import { faBattery, faMoon, faSolarPanel, faSun } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import Plot from 'react-plotly.js';
import withTheme from './withTheme';

const DailySizingChartComponent = ({ dailySizingViewEstimate, themeLayout }) => {
  const [width, setWidth] = useState(0);
  const chartRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        setWidth(chartRef.current.offsetWidth);
      }
    };

    // Set initial width
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!dailySizingViewEstimate) {
    return null; // Render nothing until data is available
  }
  const { solarOversize } = dailySizingViewEstimate;

  const systemData = solarOversize.find(item => item.label === 'System');
  const loadsData = solarOversize.find(item => item.label === 'Loads');

  const solarGeneration = systemData.Solar; // Convert to kWh
  const batteryStorage = systemData.Battery; // Convert to kWh
  const dayLoad = loadsData.Day; // Convert to kWh

  const nightLoad = loadsData.Night; // Convert to kWh

  return (
    <div ref={chartRef} style={{ width: '100%', backgroundColor: 'white' }}>
      <div style={{padding:'20px'}}>
      <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
        <Col span={12}>
          <div><FontAwesomeIcon icon={faSolarPanel} /> Solar Generation: {solarGeneration.toFixed(2)} kWh</div>
        </Col>
        <Col span={12}>
          <div><FontAwesomeIcon icon={faBattery} /> Battery Storage: {batteryStorage.toFixed(2)} kWh</div>
        </Col>
        <Col span={12}>
          <div><FontAwesomeIcon icon={faSun} /> Day Load: {dayLoad.toFixed(2)} kWh</div>
        </Col>
        <Col span={12}>
          <div><FontAwesomeIcon icon={faMoon} /> Night Load: {nightLoad.toFixed(2)} kWh</div>
        </Col>
      </Row>
      </div>

      <Plot
        data={[
          {
            x: [dayLoad],
            y: ['Loads'],
            name: 'Day Load',
            type: 'bar',
            orientation: 'h', // Set orientation to horizontal
            marker: { color: '#FF7F0E' },
            text: `${dayLoad.toFixed(2)} kWh`,
            textposition: 'inside',
                    insidetextanchor: 'middle',
            hoverinfo: 'name+text', // Show both name and value in hover info
          },
          {
            x: [nightLoad],
            y: ['Loads'],
            name: 'Night Load',
            type: 'bar',
            orientation: 'h', // Set orientation to horizontal
            marker: { color: '#1F77B4' },
            text: `${nightLoad.toFixed(2)} kWh`,
            textposition: 'inside',
                    insidetextanchor: 'middle',
            hoverinfo: 'name+text', // Show both name and value in hover info
          },
          {
            x: [solarGeneration],
            y: ['System'],
            name: 'Solar Generation',
            type: 'bar',
            orientation: 'h', // Set orientation to horizontal
            marker: { color: '#F1E15B' },
            text: `${solarGeneration.toFixed(2)} kWh`,
            textposition: 'inside',
                    insidetextanchor: 'middle',
            hoverinfo: 'name+text', // Show both name and value in hover info
          },
          {
            x: [batteryStorage],
            y: ['System'],
            name: 'Battery Storage',
            type: 'bar',
            orientation: 'h', // Set orientation to horizontal
            marker: { color: '#D62728' },
            text: `${batteryStorage.toFixed(2)} kWh`,
            textposition: 'inside',
                    insidetextanchor: 'middle',
            hoverinfo: 'name+text', // Show both name and value in hover info
          },
          
        ]}
        layout={{
          barmode: 'stack',
          yaxis: {
            
          },
          xaxis: {
            
          },
          showlegend: false,
          width: width,
          height: 400, // Set a fixed height
          ...themeLayout,
        }}
      />
    </div>
  );
};

DailySizingChartComponent.propTypes = {
  dailySizingViewEstimate: PropTypes.object.isRequired,
  themeLayout: PropTypes.object.isRequired,
};


export default withTheme(DailySizingChartComponent);