import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tabs, Table, Skeleton, Modal, notification, Tooltip, Alert } from 'antd';
import { PlusOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { fetchProjects, deleteProject } from '../services/apiService';
import { useNavigate, Link } from 'react-router-dom';

function Projects({ userData, isConnectedToBusiness }) {
    const [loading, setLoading] = useState(true);
    // const [projects, setProjects] = useState([]);
    const [sizingData, setSizingData] = useState([]);
    const [quickData, setQuickData] = useState([]);
    const [items, setItems] = useState([]);
    const [isBusinessAdmin, setIsBusinessAdmin] = useState(false);
    const [reload, setReload] = useState(false);

    const navigate = useNavigate();

    
    const terminateProject = async (project) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            content: 'This action cannot be undone. Make sure this is the correct item.',
            okText: 'Confirm',
            okButtonProps: {
                danger: true,
            },
            onOk: async () => {
                const token = localStorage.getItem('accessToken');
                const tmp_data = {
                    "id": project.key,
                    'sizingId': project.sizingId,
                    'sizingtype': project.type
                };
                const resp = await deleteProject(token, tmp_data);
                if (resp) {
                    notification.success({
                        message: 'Success',
                        description: 'Project deleted successfully',
                        placement: 'bottomRight'
                    });
                    setReload(prev => !prev);
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const columns = useMemo(() => [
        {
            title: 'Last Edit',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Client Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Monitor Name',
            dataIndex: 'monitor',
            key: 'monitor',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                    <Link to={`/project-simple/${record.sizingId}`}>
                        <Tooltip title={!isBusinessAdmin ? "Only admins can perform actions" : ""}>
                            <Button type="primary" disabled={!isBusinessAdmin} style={{ marginRight: 8 }}>
                                View
                            </Button>
                        </Tooltip>
                    </Link>
                    <Tooltip title={!isBusinessAdmin ? "Only admins can perform actions" : ""}>
                        <Button type="primary" danger disabled={!isBusinessAdmin} onClick={() => terminateProject(record)}>Delete</Button>
                    </Tooltip>
                </span>
            ),
        },
    ], [isBusinessAdmin]);


    const quick_sizing_columns = useMemo(() => [
        {
            title: 'Last Edit',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Client Name',
            dataIndex: 'name',
            key: 'name',
        },
        // {
        //     title: 'Monitor Name',
        //     dataIndex: 'monitor',
        //     key: 'monitor',
        // },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                    <Link to={`/quick-sizing/${record.sizingId}`}>
                        <Tooltip title={!isBusinessAdmin ? "Only admins can perform actions" : ""}>
                            <Button type="primary" disabled={!isBusinessAdmin} style={{ marginRight: 8 }}>
                                View
                            </Button>
                        </Tooltip>
                    </Link>
                    <Tooltip title={!isBusinessAdmin ? "Only admins can perform actions" : ""}>
                        <Button type="primary" danger disabled={!isBusinessAdmin} onClick={() => terminateProject(record)}>Delete</Button>
                    </Tooltip>
                </span>
            ),
        },
    ], [isBusinessAdmin]);


    useEffect(() => {
        const fetchData = async () => {
            if (isConnectedToBusiness) {
                try {
                    const token = localStorage.getItem('accessToken');
                    const response_data = await fetchProjects(token);

                    const sizingProjects = response_data.filter(project => project.type === 'normal');
                    const formattedSizingProjects = sizingProjects.map(project => ({
                        key: project._id,
                        date: project.created,
                        name: project.clientName,
                        sizingId: project.sizingId,
                        monitor: project.monitor,
                        type: project.type
                    }));
                    setSizingData(formattedSizingProjects);

                    const quickProjects = response_data.filter(project => project.type === 'quick');
                    const formattedQuickProjects = quickProjects.map(project => ({
                        key: project._id,
                        date: project.created,
                        name: project.clientName,
                        sizingId: project.sizingId,
                        monitor: 'None',
                        type: project.type
                    }));
                    setQuickData(formattedQuickProjects);

                    // setProjects(response_data);


                    const userLevel = userData.privilage.level;
                   
                    setIsBusinessAdmin(userLevel >= 1);

                } catch (error) {
                    console.error('Failed to fetch projects:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
                setIsBusinessAdmin(false);
            }
        };

        fetchData();
    }, [isConnectedToBusiness, reload, userData]);

    const goToSizing = () => {
        navigate('/project-simple');
    };


    useEffect(() => {
        setItems([
            {
                key: '1',
                label: 'Monitor Sizing List',
                children: <Table dataSource={sizingData} columns={columns} />,
            },
            {
                key: '2',
                label: 'Quick Sizing List',
                children: <Table dataSource={quickData} columns={quick_sizing_columns} />,
            },
        ]);
    }, [sizingData, quickData, columns, quick_sizing_columns]);


    if (!isConnectedToBusiness) {
        return (
            <div>
                <h1>Projects</h1>
                <Alert message="You are not connected to a business. Please connect to a business to view projects." type="warning" showIcon />
            </div>
        );
    }

    return (
        <div>
            <h1>Projects</h1>
            <p>Select one of your current projects or create a new one.</p>
            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                <Link to="/project-simple">
                    <Tooltip title={!isBusinessAdmin ? "Only admins can create new sizings" : ""}>
                        <Button type="primary" icon={<PlusOutlined />} onClick={goToSizing} disabled={!isBusinessAdmin}>New Monitor Sizing</Button>
                    </Tooltip>
                </Link>
                <Link to="/quick-sizing">
                    <Tooltip title={!isBusinessAdmin ? "Only admins can create new quick sizings" : ""}>
                        <Button type="primary" icon={<ThunderboltOutlined />} disabled={!isBusinessAdmin}>New Quick Sizing</Button>
                    </Tooltip>
                </Link>
            </div>
      
                <div>
                    {loading ? (
                        <Skeleton active />
                    ) : (
                        <Tabs defaultActiveKey="1" items={items} />
                    )}
                </div>
        
        
        </div>
    );
}

Projects.propTypes = {
    userData: PropTypes.object.isRequired,
    isConnectedToBusiness: PropTypes.bool.isRequired,
};

export default Projects;