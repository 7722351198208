import React from 'react';
// import SmallText from './SmallText';

function Faq() {
    return (
        <div>
            <h1>Help/FAQ</h1>
            <p>Find answers to common questions</p>
            <h2>How it works?</h2>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/rqjSFJOoSTU"
                title="Scout Introduction for Solar Installers"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
            <h2>Sign Up - How to create an account?</h2>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fyr8s1l5LRs"
                title="Scout Quick Vid: How to Sign Up  (Create an account)"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
            <h2>Connecting the Scout to local WiFI</h2>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/vM3hKI-pnLU"
                title="Scout Quick Vid: Connecting the Scout to WiFi"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
            <h2>Monitoring - How to use the monitoring page?</h2>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/62CIvcaJxkA"
                title="Scout Quick Vid: Monitoring"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
            <h2>Projects - How to size a solar project?</h2>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NYp8UUUlcCg"
                title="Scout Quick Vid: Projects"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
            <h2>Overview of all features</h2>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/hcoLpkzi-4Q"
                title="Scout Monitor & Solar Sizing Training  - How to Get Started With Scout"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
            ></iframe>
            <h2>Where can I get hardware for my solar installation?</h2>
            <p>
                Cedar Solar (Phone 011 794 4664) is a solar hardware distributor and can provide you with a hardware quotation for your project. Send them the Scout Project Report and they will send you a quote.
            </p>
        </div>
    );
}

export default Faq;