// sizingComponentCalculations.js
import dayjs from 'dayjs';

import { round, mapValues } from 'lodash';
import { groupBy, partition, sumBy, meanBy } from 'lodash';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);


// import { format, toZonedTime } from 'date-fns-tz';
/**
 * @typedef {Object} Clamp
 * @property {string} id
 * @property {boolean} enabled
 * @property {boolean} minus
 */
 
/**
 * @typedef {Object} Panel
 * @property {string} name
 * @property {string} make - The manufacturer of the panel
 * @property {number} WP - Wattage of the panel
 * @property {number} VOC - Open circuit voltage of the panel
 * @property {number} VMP - Voltage at maximum power of the panel
 * @property {number} ISC - Short circuit current of the panel
 * @property {number} temp_coefficient_VC - Temperature coefficient of the panel
 * @property {number} price - Price of the panel
 * @property {number} loss_factor - Loss factor of the panel
 * @property {string} component_uuid - UUID of the panel component
 * @property {number} weight - Weight of the panel
 * @property {number} height - Height of the panel
 * @property {number} width - Width of the panel
 */

/**
 * @typedef {Object} MPPT
 * @property {string} name
 * @property {string} make
 * @property {number} WP
 * @property {number} VOC
 * @property {number} VMP
 * @property {number} ISC
 * @property {number} temp_coefficient_VC
 * @property {number} price
 * @property {number} kW
 * @property {number} loss_factor
 * @property {string} component_uuid
 */

/**
 * @typedef {Object} Inverter
 * @property {number} rated_output
 * @property {number} price
 * @property {number} parallel_limit
 * @property {number} max_input_voltage
 * @property {number} num_mppts
 * @property {number} num_inputs_per_mppts
 * @property {number} max_charge_per_mppts_isc
 * @property {string} design_voltage
 * @property {string} component_uuid
 * @property {MPPT[]} mppts
 */

/**
 * @typedef {Object} HourReading
 * @property {string} time
 * @property {number} mean_clamp1i
 * @property {number} mean_clamp1v
 * @property {number} mean_clamp2i
 * @property {number} mean_clamp2v
 * @property {number} mean_clamp3i
 * @property {number} mean_clamp3v
 */

/**
 * @typedef {Object} SizingReport
 * @property {number} dailyUseKwh
 * @property {number} peakCapacityCovered
 * @property {number} pvWattsAcAnnual
 * @property {HourReading[]} hourReadings

 * @property {Clamp[]} clamps
 * @property {Panel} panel
 * @property {number} noPanels
 * @property {{ inverters: Inverter[], panels: Panel[], panelCount: number }} components
 */



const pv_generation_mapping = {
  "00:00:00": 0,
  "00:15:00": 0,
  "00:30:00": 0,
  "00:45:00": 0,
  "01:00:00": 0,
  "01:15:00": 0,
  "01:30:00": 0,
  "01:45:00": 0,
  "02:00:00": 0,
  "02:15:00": 0,
  "02:30:00": 0,
  "02:45:00": 0,
  "03:00:00": 0,
  "03:15:00": 0,
  "03:30:00": 0,
  "03:45:00": 0,
  "04:00:00": 0,
  "04:15:00": 0,
  "04:30:00": 0,
  "04:45:00": 0,
  "05:00:00": 0,
  "05:15:00": 0,
  "05:30:00": 0,
  "05:45:00": 0,
  "06:00:00": 0,
  "06:15:00": 0.00364836693333333,
  "06:30:00": 0.0108408616666667,
  "06:45:00": 0.0180333564666667,
  "07:00:00": 0.0252258513333333,
  "07:15:00": 0.0541843873333333,
  "07:30:00": 0.122904795,
  "07:45:00": 0.1924322446,
  "08:00:00": 0.2619596942,
  "08:15:00": 0.3256520732,
  "08:30:00": 0.370284920133333,
  "08:45:00": 0.413439888733333,
  "09:00:00": 0.456594857533333,
  "09:15:00": 0.5017929118,
  "09:30:00": 0.559589993,
  "09:45:00": 0.6195274496,
  "10:00:00": 0.679464906133333,
  "10:15:00": 0.738151494066667,
  "10:30:00": 0.7796038916,
  "10:45:00": 0.815566365533333,
  "11:00:00": 0.851528839466667,
  "11:15:00": 0.887354644466667,
  "11:30:00": 0.9156358582,
  "11:45:00": 0.939610840933333,
  "12:00:00": 0.963585823466667,
  "12:15:00": 0.987560806066667,
  "12:30:00": 0.998846421133333,
  "12:45:00": 0.996448922866667,
  "13:00:00": 0.9940514246,
  "13:15:00": 0.991653926333333,
  "13:30:00": 0.979029881866667,
  "13:45:00": 0.946025017333333,
  "14:00:00": 0.9124600418,
  "14:15:00": 0.878895066,
  "14:30:00": 0.842397498266667,
  "14:45:00": 0.795378735266667,
  "15:00:00": 0.74742877,
  "15:15:00": 0.699478804666667,
  "15:30:00": 0.6475886032,
  "15:45:00": 0.568763029866667,
  "16:00:00": 0.484850590666667,
  "16:15:00": 0.400938151466667,
  "16:30:00": 0.3176539264,
  "16:45:00": 0.244579569133333,
  "17:00:00": 0.175052119533333,
  "17:15:00": 0.105524669933333,
  "17:30:00": 0.0365290711333333,
  "17:45:00": 0.0084989576,
  "18:00:00": 0.00610145933333333,
  "18:15:00": 0.00370396113333333,
  "18:30:00": 0.0013064628,
  "18:45:00": 0.00000185314,
  "19:00:00": 0,
  "19:15:00": 0,
  "19:30:00": 0,
  "19:45:00": 0,
  "20:00:00": 0,
  "20:15:00": 0,
  "20:30:00": 0,
  "20:45:00": 0,
  "21:00:00": 0,
  "21:15:00": 0,
  "21:30:00": 0,
  "21:45:00": 0,
  "22:00:00": 0,
  "22:15:00": 0,
  "22:30:00": 0,
  "22:45:00": 0,
  "23:00:00": 0,
  "23:15:00": 0,
  "23:30:00": 0,
  "23:45:00": 0
}

/**
 * Function to get PV generation based on hour string and pv_mapping.
 * 
 * @param {string} hourStr - The hour string in "HH:MM:SS" format.
 * @param {Object} pvMapping - The mapping of time to PV generation values.
 * 
 * @returns {number} The PV generation value for the given hour string.
 */
function getPvGeneration(hourStr, pvMapping) {
  return pvMapping[hourStr] || 0;
}

import logger from '../utils/logger';
logger.enableLogging('sizingComponentCalculations');

/**
 * Converts raw hourly readings to the desired format by combining wattages of all clamps.
 * 
 * @param {*} rawDataArray 
 * @returns 
 */
export const formatHourlyRawDataCombined_SingleClamp = (rawDataArray) => {
  // console.log("rawDataArray", rawDataArray);
  const combined_data = [];

  rawDataArray.forEach(rawData => {
      const time = rawData.time; // Use the time field from the JSON data

      // Calculate the total wattage using mean_i and mean_v

      const totalWatt = rawData.mean_watt; //total_power for old hourReadings

      combined_data.push({ time, max: totalWatt });
  });

  // console.log("combined_data", combined_data);
  return combined_data;
};

/**
 * Converts raw hourly readings to the desired format by combining wattages of all clamps.
 * 
 * @param {*} rawDataArray 
 * @returns 
 */
export const formatHourlyRawDataCombined = (rawDataArray) => {

  const combined_data = [];

  rawDataArray.forEach(rawData => {
      const time = rawData.clamp1v.time; // Assuming all times are the same for a given hour



      const clamp1watt_mean = rawData.clamp1v.value * rawData.clamp1i.value;
      const clamp2watt_mean = rawData.clamp2v.value * rawData.clamp2i.value;
      const clamp3watt_mean = rawData.clamp3v.value * rawData.clamp3i.value;

      const clamp1watt_max = rawData.clamp1v.max * rawData.clamp1i.max 
      const clamp2watt_max = rawData.clamp2v.max * rawData.clamp2i.max 
      const clamp3watt_max = rawData.clamp3v.max * rawData.clamp3i.max 
    

      const totalWatt = clamp1watt_mean + clamp2watt_mean + clamp3watt_mean;
      const maximum = clamp1watt_max + clamp2watt_max + clamp3watt_max;

      combined_data.push({ time, max: totalWatt, maximum: maximum });
  });

  return combined_data;
};

/** 
 * Calculates the inverter operating capacity graph data.
 * 
 * @param {Array} hourReadings - The hourly readings data.
 * @param {number} numInverters - The number of inverters.
 * @param {number} maxOutputPerInverter - The maximum output per inverter (in kW).
 * @returns {Array} - The formatted data for the inverter operating capacity chart.
 */
const calculateInverterOperatingCapacityGraphData = (hourReadings, numInverters, maxOutputPerInverter, isThreePhase) => {
  // Calculate the total max output for all inverters. Updated to use three phase now considering three phase
  const totalMaxOutput = (numInverters / (isThreePhase? 3 : 1 )) * maxOutputPerInverter;

  // const operatingCapacityProjections = formatHourlyRawDataCombined(hourReadings);

  // console.log("isThreePhase", isThreePhase);
  // console.log("numInverters", numInverters);
  console.log("maxOutputPerInverter", maxOutputPerInverter);
  console.log("totalMaxOutput", totalMaxOutput);

  // console.log("hourReadings", hourReadings); 
  // console.log("totalMaxOutput", totalMaxOutput);

  
  // Select a random 10% of the hourReadings
  // const sampleSize = Math.ceil(hourReadings.length * 0.1);
  // const sampledReadings = hourReadings.sort(() => 0.5 - Math.random()).slice(0, sampleSize);

  // we remove percentage in favor of the actual values for accuracy
  const sampledReadings = hourReadings;
   // Convert sample peaks to kilowatts and calculate operating capacity projections
   const operatingCapacityProjections = sampledReadings.map(reading => {
    // console.log("reading", reading);
    const totalPowerKw = reading.mean_watt / 1000; // Convert to kW total_power
    const operatingCapacity = (totalPowerKw / totalMaxOutput) * 100; // Percentage of max capacity
    return operatingCapacity;
  });

  // console.log("mean", sampledReadings[0].mean_watt);

  // Find the maximum operating capacity
  // const maxOperatingCapacity = totalMaxOutput;

  // console.log("maxOperatingCapacity", maxOperatingCapacity);

    // Calculate each value as a percentage of the maximum operating capacity
    const percentageValues = operatingCapacityProjections.map(value => (value));// / maxOperatingCapacity) * 100);
    // const percentageValues = operatingCapacityProjections.map(projection => ((projection.maximum/1000) / peakKW) * 100);

  // console.log("percentageValues", percentageValues);

  // Define the ranges
  const ranges = [
      { low: 0, high: 25 },
      { low: 25, high: 50 },
      { low: 50, high: 75 },
      { low: 75, high: 10000 }
  ];
  const rangeCounts = Array(ranges.length).fill(0);

  // Count the number of projections in each range
  percentageValues.forEach(projection => {
      ranges.forEach((range, index) => {
          if (range.low <= projection && projection <= range.high) {
              rangeCounts[index] += 1;
          }
      });
  });

  

  // console.log("numInverters", numInverters);
  // console.log("maxOutputPerInverter", maxOutputPerInverter);


  // Calculate the percentage of time in each range
  const totalProjections = percentageValues.length;
  const percentageTimeInRanges = rangeCounts.map(count => (count / totalProjections) * 100);

  // console.log("percentageTimeInRanges", percentageTimeInRanges)

  const xLabels = ["0-25%", "25-50%", "50-75%", "75-100%"];
  const yValues = percentageTimeInRanges;

  // console.log("percentageTimeInRanges", percentageTimeInRanges);

  const chartData = [{
      x: xLabels,
      y: yValues,
      type: 'bar',
      marker: { color: ['#1F77B4', '#1F77B4', '#1F77B4', '#FF7F0E'] },
      hoverinfo: 'x+y',
      name: 'Percentage Time in Range',
      text: yValues.map(value => `${value.toFixed(2)}%`),
      textposition: 'auto',
      insidetextanchor: 'middle'
  }];

  return chartData;
};

export const getAVGkw = (hourReadings) => {
  // Calculate the average power consumption per hour
  const avgKw = hourReadings.reduce((sum, reading) => sum + reading.mean_watt, 0) / hourReadings.length;
  return avgKw / 1000; // Convert to kW
}




export const dayNightAverage = (wattsPerHour) => {
  // Helper function to extract the date in YYYY-MM-DD format
  const getDateStr = (dateInput) => {
    // Convert the Date object to a string in YYYY-MM-DD format
    const dateObj = new Date(dateInput);

    if (!(dateObj instanceof Date)) { // Check if dateObj is an instance of Date
      return 'Invalid Date'; // or handle it as appropriate for your use case
    }
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Helper function to get the day of the week (0-6)
  const getDayOfWeek = (date) => new Date(date).getDay();

  // Step 1: Group watts per hour data by date and day (YYYY-MM-DD)
  const groupedData = groupBy(wattsPerHour, (item) => {
    return `${getDateStr(item.time)}-${getDayOfWeek(item.time)}`;
  });

  // Step 2: Partition each group into days and nights (8 AM to 4 PM)
  const partitionedData = mapValues(groupedData, (group) => {
    return partition(group, (item) => {
      const hours = new Date(item.time).getUTCHours();
      return hours >= 8 && hours <= 16;
    });
  });

  // Step 3: Calculate the total watts for days and nights separately
  const dayNightTotals = Object.values(partitionedData).map((piece) => {
    const dayTotal = sumBy(piece[0], 'mean_watt');
    const nightTotal = sumBy(piece[1], 'mean_watt');
    return { dayTotal, nightTotal };
  });

  // Step 4: Calculate the average watts for days and nights
  const dayAverage = roundTo(meanBy(dayNightTotals, 'dayTotal'), 0);
  const nightAverage = roundTo(meanBy(dayNightTotals, 'nightTotal'), 0);

  // Step 5: Return the results
  return { dayAverage, nightAverage };
};


/**
 * Calculate daily sizing view estimate
 * 
 * @param {Object} sizingReport - The sizing report containing various parameters.
 * @param {number} sizingReport.dailyUseKwh - The daily energy usage in kWh.
 * @param {number} sizingReport.solar_array_sizing - The peak capacity covered.
 * @param {number} sizingReport.pvWattsAcAnnual - The annual AC output of the PV system in watts.
 * @param {number} sizingReport.batteryCapacityKwh - The battery capacity in kWh.
 * @param {number} sizingReport.inverterCapacityKw - The inverter capacity in kW.
 * @param {Array<Object>} sizingReport.hourReadings - Array of hour readings.
 * @param {Array<Object>} sizingReport.clamps - Array of clamps.
 * @param {Object} sizingReport.panel - The panel object containing panel details.
 * @param {number} sizingReport.pvCount - The number of panels.
 * 
 * @returns {Object} The daily sizing view estimate results.
 * @returns {number} returns.dailyEstimate - The daily estimate in kWh.
 * @returns {number} returns.meanDayWatts - The mean watts per hour during the day.
 * @returns {number} returns.meanNightWatts - The mean watts per hour during the night.
 * @returns {number} returns.roofSize - The calculated roof size.
 * @returns {Object} returns.solarOversize - The solar oversize graph data.
 */
export const calculateDailySizingViewEstimate = (sizingReport) => {
  const {
    pvWattsAcAnnual,
    hourReadings,
    // clamps,
    panel,
    pvCount,
    system_results,
    //batteryCount,
    // overall
  } = sizingReport;

  // Calculate mean watts per hour for day and night
  // const meanDayWatts = meanWattsPerHourFromReadingsForDay(hourReadings, clamps);
  // const meanNightWatts = meanWattsPerHourFromReadingsForNight(hourReadings, clamps);

  // console.log("READINMGS", hourReadings);

  const newSplitter = dayNightAverage(hourReadings);
  // console.log("newSplitter", newSplitter);

  const meanDayWatts = newSplitter.dayAverage;
  const meanNightWatts = newSplitter.nightAverage;



  // const final_general_avg_kw = overall.avg_kwh; 
  // const final_general_avg_kw = overall.general_avg_kw;
  const final_general_avg_kw = getAVGkw(hourReadings);



  // console.log("mean day wattts", meanDayWatts);
  // console.log("mean night wattts", meanNightWatts);
  // console.log("general_avg_kw", final_general_avg_kw);




  let totalSum = 0;
  let total_items = 0;

  for (let time in pv_generation_mapping) {
      totalSum += pv_generation_mapping[time];
      total_items += 1;
  }
  
  console.log("Total Sum:", totalSum, " items ", total_items);

  // const percentage_rate = 1 - (totalSum/total_items); // assuming percentage rate based in the pv_generation_mapping
  

  // console.log("percentage_rate", percentage_rate);

  
  

  // Calculate max watts per five minutes
  // const maxFiveMinWatts = maxWattsPerFiveMin(fiveMinMaxReadings, clamps);
 
  // Ensure panel and noPanels are defined before calculating roof size
  let roofSize = 0;
  if (panel && panel.width && panel.height && pvCount) {
    roofSize = roofSizeCalc(panel, pvCount);
  } else {
    console.error("Invalid panel or pvCount data:", panel, pvCount);
  }

  const solar_array_sizing = system_results.solar_panel_result.pv_array_size; // the percentage of the solar array sizing
  // console.log("solar_array_sizing", solar_array_sizing/100);
  // we then get percent and perform transform on daily amount

  // const required_daytime_kwh = ((meanDayWatts / 1000) * average_daily_sunlight_hours); // the required energy during the daytime which is 8 hours
  // testing new method below, for nighttime energy calculation
  const required_daytime_kwh = meanDayWatts/1000;

  // const required_nighttime_kwh = ((meanNightWatts / 1000) * (24-average_daily_sunlight_hours)); // the required energy during the nighttime which is 16 hours
  const required_nighttime_kwh = meanNightWatts/1000;
 
  // Now we are using method from geoff system below
  // const solar_generated_kwh = (final_general_avg_kw * 24 * solar_array_sizing) / 100 ; 
  const solar_generated_kwh = (final_general_avg_kw * 24 * solar_array_sizing) / 100 ; 
  

  //const battery_backup_kwh = system_results.battery_result.backup_power_provided * batteryCount; // old and i think correct way is to show what it makes
  const battery_backup_kwh = system_results.battery_result.backup_power_required; // this shows what is required


  // Calculate solar oversize graph data
  const solarOversize = solarOversizeGraph(required_daytime_kwh,required_nighttime_kwh,solar_generated_kwh,battery_backup_kwh
  );



  // Example calculation (replace with actual logic)
  const dailyEstimate = round(
    (pvWattsAcAnnual / 365) * solar_array_sizing,
    2
  );

 


  return {
    dailyEstimate,
    meanDayWatts,
    meanNightWatts,
    roofSize,
    solarOversize,

  };
};

// Helper functions

/**
 * Calculate daily use kWh based on hour readings and clamps
 * @param {Object[]} hourReadings - Array of hour readings.
 * @param {Object[]} clamps - Array of clamps.
 * @returns {number} The daily use in kWh.
 */
export const calculateDailyUseKwh = (hourReadings) => {
  // Sum the total_kwh for all hour readings
  const totalKwh = hourReadings.reduce((sum, reading) => {
    return sum + reading.total_kwh;
  }, 0);
  return totalKwh; // Already in kWh
};

/**
 * Calculate peak capacity covered based on panels and inverters
 * @param {Array} panels
 * @param {Array} inverters
 * @returns {number}
 */
export const calculatePeakCapacityCovered = (panels, inverters) => {
  // Example logic: Calculate the ratio of total panel capacity to total inverter capacity
  const totalPanelCapacity = panels.reduce((sum, panel) => sum + panel.WP, 0);
  const totalInverterCapacity = inverters.reduce((sum, inverter) => sum + inverter.rated_output, 0);
  return totalPanelCapacity / totalInverterCapacity;
};

/**
 * Calculate PV watts AC annual based on panels
 * @param {Array} panels
 * @returns {number}
 */
export const calculatePvWattsAcAnnual = (panels) => {
  // Example logic: Sum the annual output of all panels
  return panels.reduce((sum, panel) => sum + panel.WP * 365, 0); // Assuming 1 WP produces 1 watt-hour per day
};

/**
 * Calculate five-minute max readings based on hour readings
 * @param {Array} hourReadings
 * @returns {Array}
 */
// export const calculateFiveMinMaxReadings = (hourReadings) => {
//   // Example logic: Find the maximum reading over five-minute intervals
//   return hourReadings.map(reading => ({
//     time: reading.time,
//     max_clamp1i: reading.mean_clamp1i,
//     max_clamp1v: reading.mean_clamp1v,
//     max_clamp2i: reading.mean_clamp2i,
//     max_clamp2v: reading.mean_clamp2v,
//     max_clamp3i: reading.mean_clamp3i,
//     max_clamp3v: reading.mean_clamp3v,
//   }));
// };


export const localgetHours = (time) => {
  return new Date(time).getUTCHours();
};

/**
 * Calculate the mean watts per hour for day based on hour readings
 * @param {Array} hourReadings - Array of hour readings.
 * @returns {number} The mean watts per hour for the day.
 */
export const meanWattsPerHourFromReadingsForDay = (hourReadings) => {
  // Filter readings for day hours (8 AM to 4 PM)
  const filteredForDay = hourReadings.filter((reading) => {
    const hour = localgetHours(reading.time);
    console.log("HOUR DAY", hour);
    return hour >= 8 && hour <= 16;
  });

  // Calculate the mean of (mean_i * mean_v) for the filtered readings
  const totalMeanIAndV = filteredForDay.reduce((acc, reading) => {
    acc += reading.mean_i * reading.mean_v;
    return acc;
  }, 0);

  const totalmeanWattd = filteredForDay.reduce((acc, reading) => {
    acc += reading.mean_watt;
    return acc;
  }, 0);

  const meanOfMeanIAndV = totalMeanIAndV / filteredForDay.length;
  const meanWattd = totalmeanWattd / filteredForDay.length;
  console.log("MEAN OF MEAN I AND V", meanOfMeanIAndV);

  return meanWattd;
};



export const meanWattsPerHourFromReadingsForNight = (hourReadings) => {
  // Filter readings for day hours (8 AM to 4 PM)
  const filteredForDay = hourReadings.filter((reading) => {
    const hour = localgetHours(reading.time);
    console.log("HOUR DAY", hour);
    return !(hour >= 8 && hour <= 16);
  });

  // Calculate the mean of (mean_i * mean_v) for the filtered readings
  const totalMeanIAndV = filteredForDay.reduce((acc, reading) => {
    acc += reading.mean_i * reading.mean_v;
    return acc;
  }, 0);

  const totalmeanWattd = filteredForDay.reduce((acc, reading) => {
    acc += reading.mean_watt;
    return acc;
  }, 0);

  const meanOfMeanIAndV = totalMeanIAndV / filteredForDay.length;
  const meanWattd = totalmeanWattd / filteredForDay.length;
  console.log("MEAN OF MEAN I AND V", meanOfMeanIAndV);

  return meanWattd;
};

// export const maxWattsPerFiveMin = (fiveMinMaxReadings, clamps) => {
//   if (!fiveMinMaxReadings || !Array.isArray(fiveMinMaxReadings)) {
//     console.error("Invalid fiveMinMaxReadings data:", fiveMinMaxReadings);
//     return [];
//   }

//   if (!clamps || !Array.isArray(clamps)) {
//     console.error("Invalid clamps data:", clamps);
//     return [];
//   }

//   const mins = fiveMinMaxReadings.map((a) =>
//     a.max_clamp1v < 1000 && a.max_clamp2v < 1000 && a.max_clamp3v < 1000 // Ensure all clamp voltages are below threshold
//       ? a
//       : {
//         ...a,
//         max_clamp1i: 0,
//         max_clamp1v: 0,
//         max_clamp2i: 0,
//         max_clamp2v: 0,
//         max_clamp3i: 0,
//         max_clamp3v: 0,
//       }
//   );

//   const wattMins = mins
//     .map((a) => ({
//       date: new Date(a.time),
//       clamp1W: a.max_clamp1i && clamps[0]?.enabled ? a.max_clamp1i * a.max_clamp1v : 0,
//       clamp2W: a.max_clamp2i && clamps[1]?.enabled ? a.max_clamp2i * a.max_clamp2v : 0,
//       clamp3W: a.max_clamp3i && clamps[2]?.enabled ? a.max_clamp3i * a.max_clamp3v : 0,
//     }))
//     .map((a) => ({
//       ...a,
//       totalMaxWatt:
//         a.clamp1W * (clamps[0] && clamps[0].minus ? -1 : 1) +
//         a.clamp2W * (clamps[1] && clamps[1].minus ? -1 : 1) +
//         a.clamp3W * (clamps[2] && clamps[2].minus ? -1 : 1),
//     }));

//   return wattMins;
// };

// export const wattsPerHour = (hourReadings, clamps) => {
//   if (!hourReadings || !Array.isArray(hourReadings)) {
//     console.error("Invalid hourReadings data:", hourReadings);
//     return [];
//   }

//   if (!clamps || !Array.isArray(clamps)) {
//     console.error("Invalid clamps data:", clamps);
//     return [];
//   }

//   const hours = hourReadings.map((a) =>
//     a.mean_clamp1v < 1000
//       ? a
//       : {
//         ...a,
//         mean_clamp1i: 0,
//         mean_clamp1v: 0,
//         mean_clamp2i: 0,
//         mean_clamp2v: 0,
//         mean_clamp3i: 0,
//         mean_clamp3v: 0,
//       }
//   );

//   const wattHours = hours.map((a) => ({
//     date: new Date(a.time),
//     clamp1W: a.mean_clamp1i && clamps[0]?.enabled ? a.mean_clamp1i * a.mean_clamp1v : 0,
//     clamp2W: a.mean_clamp2i && clamps[1]?.enabled ? a.mean_clamp2i * a.mean_clamp2v : 0,
//     clamp3W: a.mean_clamp3i && clamps[2]?.enabled ? a.mean_clamp3i * a.mean_clamp3v : 0,
//     totalWatt:
//       a.mean_clamp1i * a.mean_clamp1v +
//       a.mean_clamp2i * a.mean_clamp2v +
//       a.mean_clamp3i * a.mean_clamp3v,
//   }));

//   return wattHours;
// };

export const roofSizeCalc = (panel, noPanels) => {
  return round((((panel.width / 1000) * panel.height) / 1000) * noPanels, 2);
};

export const solarOversizeGraph = (dayNeeded, nightNeeded, solarMade, batteryMade) => {
  return [
    {
      label: 'Loads',
      Day: round(dayNeeded, 2),
      Night: round(nightNeeded, 2),
    },
    {
      label: 'System',
      Solar: round(solarMade, 2),
      Battery: round(batteryMade, 2),
    },
  ];
};

// /**
//  * Calculate the weekly energy generation based on the panel specifications and number of panels.
//  * 
//  * @param {Object} panel - The panel object containing panel details.
//  * @param {number} panel.WP - The wattage of a single panel.
//  * @param {number} noPanels - The number of panels.
//  * @param {number} [sunlightHours=5.9] - The average daily sunlight hours. Defaults to 5.9 hours.
//  * 
//  * @returns {number} The weekly energy generation in kilowatt-hours (kWh).
//  */
// const calculateWeeklyGeneration = (panel, noPanels, sunlightHours = 5.9) => {
//   // Step 1: Total Watt-Peak (Wp) Calculation
//   const totalWp = panel.WP * noPanels;

//   // Step 2: Daily Generation Calculation
//   const dailyGenerationWh = totalWp * sunlightHours;

//   // Step 3: Convert to Kilowatt-Hours (kWh)
//   const dailyGenerationKwh = dailyGenerationWh / 1000;

//   // Step 4: Weekly Generation Calculation
//   const weeklyGenerationKwh = dailyGenerationKwh * 7;

//   return weeklyGenerationKwh;
// };



function roundTo(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

// /**
//  * Convert a time string to a specific timezone.
//  * @param {string} timeStr - The time string to convert.
//  * @param {string} targetTimezone - The target timezone.
//  * @returns {string} The converted time string.
//  */
// const convertToTimezone = (timeStr, targetTimezone) => {
//   const date = new Date(timeStr);
//   const zonedDate = toZonedTime(date, targetTimezone);
//   return format(zonedDate, 'yyyy-MM-dd\'T\'HH:mm:ssXXX', { timeZone: targetTimezone });
// };



/**
 * Function to calculate weekly usage simulation.
 * 
 * @param {Object} sizingReport - The sizing report containing various parameters.
 * @param {number} sizingReport.dailyUseKwh - The daily energy usage in kWh.
 * @param {number} sizingReport.backupHours - The number of backup hours.
 * @param {number} sizingReport.peakCapacityCovered - The peak capacity covered.
 * @param {number} sizingReport.pvWattsAcAnnual - The annual AC output of the PV system in watts.
 * @param {number} sizingReport.batteryCapacityKwh - The battery capacity in kWh.
 * @param {number} sizingReport.inverterCapacityKw - The inverter capacity in kW.
 * @param {Object} sizingReport.panel - The panel object containing panel details.
 * @param {number} sizingReport.pvCount - The number of panels.
 * @param {Array} sizingReport.hourReadings - Array of hourly consumption data.
 * 
 * @returns {Object} The weekly usage simulation results with hourly data.
 */
export const calculateWeeklyUsageSimulation = (sizingReport) => {
  const {
    hourReadings, // Array of hourly consumption data
    inverterCapacityKw,
    panel,
    pvCount,
  } = sizingReport;

  // Format the raw hourly readings
  const formattedHourlyData = hourReadings.reduce((acc, reading) => {
    const date = dayjs(reading.time).format('YYYY-MM-DD');
    if (!acc[date]) {
        acc[date] = [];
    }
    acc[date].push({
        time: reading.time,
        totalWatt: reading.mean_watt/1000 // total_power
    });
    // console.log("START Date", dayjs(reading.time).format('YYYY-MM-DD HH:mm:ss'), "value", roundTo(reading.mean_watt/1000,2));
    return acc;
}, {});

  // Initialize weekly data arrays
  const weeklyData = Array.from({ length: 7 }, () => Array(24).fill(0));
  const countData = Array.from({ length: 7 }, () => Array(24).fill(0));

  // Populate each weekly day array with the totalWatt for each hour of the day
  const dates = Object.keys(formattedHourlyData);
  dates.forEach(date => {
 
    // Check what day of week that date is
    const dayOfWeek = dayjs(date).day(); // 0 (Sunday) to 6 (Saturday)
    const hourlyData = formattedHourlyData[date];

    if (hourlyData) {
      hourlyData.forEach(hourData => {
        const hour = dayjs(hourData.time).hour();
        // console.log("DIVE Date", dayjs(hourData.time).format('YYYY-MM-DD HH:mm:ss'), "value", hourData.totalWatt);
        weeklyData[dayOfWeek][hour] += hourData.totalWatt;
        countData[dayOfWeek][hour] += 1;
      });
    }
  });

  // console.log("finished weekly data", weeklyData);

  // Calculate averages
  const averageWeeklyData = weeklyData.map((dayData, dayIndex) => {
    return dayData.map((hourData, hourIndex) => {
      // console.log("SECOND Date", ['Sun', 'Mon', 'Tues', 'Wed', 'Th', 'Fri', 'Sat'][dayIndex], "index", countData[dayIndex][hourIndex], 'data', hourData, 'value', (hourData/countData[dayIndex][hourIndex]));
      return countData[dayIndex][hourIndex] > 0 ? roundTo((hourData / countData[dayIndex][hourIndex]), 2) : 0;
    });
  });







  // Generate labels and values for the chart
  const xLabels = [];
  for (let day = 0; day < 7; day++) {
    for (let hour = 0; hour < 24; hour++) {
      
      xLabels.push(`${['Sun', 'Mon', 'Tues', 'Wed', 'Th', 'Fri', 'Sat'][day]} ${hour}:00`);
    }
  }

  

  const yValues = averageWeeklyData.flat();

  const hourlyData = [];
  const pvGenerationKw = [];
  

  for (let i = 0; i < 7; i++) {
    for (let hour = 0; hour < 24; hour++) {
      // Generate the hour string in "HH:MM:SS" format
      const hourStr = `${String(hour+1).padStart(2, '0')}:00:00`;
      const PvGenRate = getPvGeneration(hourStr, pv_generation_mapping);
      const hourlyPvGenerationKw = roundTo((PvGenRate * (panel.WP / 1000)) * pvCount, 1); // Adjust for number of panels

      const hourDataEntry = {
        day: i + 1,
        hour,
        hourlyUseKw: averageWeeklyData[i][hour], // Use average weekly data
        hourlyPvGenerationKw,
        inverterCapacityKw,
      };
      hourlyData.push(hourDataEntry);
      pvGenerationKw.push(hourlyPvGenerationKw);
    }
  }

  return {
    hourlyData,
    hoursOfWeek: xLabels,
    hourlyUseKw: yValues,
    pvGenerationKw,
  };
};


// Function to calculate estimated component costs
const calculateEstimatedComponentCosts = (sizingReport) => {
  const { inverters, panels } = sizingReport.components.details;
  const totalCost = [
    ...inverters.flatMap(inverter => [inverter.price, ...inverter.mppts.map(mppt => mppt.price)]),
    ...panels.map(panel => panel.price)
  ].reduce((total, price) => total + price, 0);
  return totalCost;
};

export const totalWithMarkup = (components) => {
  // Example implementation (replace with actual logic)
  const total = components.reduce((acc, component) => acc + component.cost, 0);
  const markup = 1.2; // Example markup value
  return round(total * markup, 2);
};

// Function to calculate component configuration
const calculateComponentConfiguration = (inverters, panels, panelCount) => {
  return {
    inverters: inverters.length,
    mppts: inverters.reduce((total, inverter) => total + inverter.mppts.length, 0),
    panels: panels.length,
    panelCount,
  };
};


export const CalculateComponentSetup = (component_config_details) => {
  // takes the components and determines if low or high voltage. hard coded for now
  // const inverterComp = {
  //   'rated_output': 12, //kW
  //   'price': 1000,
  //   'parallel_limit': 3,
  //   'max_input_voltage': 1000,
  //   'num_mppts': 4,
  //   'num_inputs_per_mppts': 2,
  //   'max_charge_per_mppts_isc': 55,
  //   'design_voltage': "HV",
  // }
  const inverterComp = component_config_details.inverterComponent;
  // const PVComp = {
  //   'name': "PV Panel",
  //   'make': "SunPower",
  //   'WP': 555,
  //   'VOC': 49.72,
  //   'VMP': 40.99,
  //   'ISC': 14.12,
  //   'temp_coefficient_VC': -0.0028,
  //   'price': 3000,
  //   'kW': 0.47, //kW
  //   'height_mm': 2182,
  //   'width_mm': 1029,
  //   'weight': 26.1
  // }
  const PVComp = component_config_details.pvComponent;
  // const batteryComp = {
  //   'kwh': 8,
  //   'price': 1000,
  //   'peak_kw': 4,
  //   'loss_factor': 0.15,
  //   'DoD': 290.816,
  //   'max_dod_percentage': 0.8,
  //   'design_voltage': "HV",
  //   'max_per_stack': 12,
  //   'bmu_needed': true,
  //   'balance_between_inverters': true,
  // }
  const batteryComp = component_config_details.batteryComponent;


  const system_information = {
    'average_consumption_kW': component_config_details.overall_avg_kwh,
    'backup_hours': component_config_details.backup_hours,
    'peak_kw': component_config_details.overall_peak_watt/1000,
    // 'inverter_size_percent': 100,
    'pv_size_percent': component_config_details.pv_array_size,
    // 'battery_size_percent': 100,
    'number_of_inverters': component_config_details.inverterCount, // for three phase. pass through later
    'number_of_pv': component_config_details.pvCount, // passed later
    'number_of_batteries': component_config_details.batteryCount, // passed later
    'inverterComponent': inverterComp,
    'pvComponent': PVComp,
    'batteryComponent': batteryComp,
    // 'dailySizingViewEstimate_kWh': {
    //   'solar_generation': component_config_details.solar_generation,
    //   'day_load': component_config_details.day_load,
    //   'battery_storage': component_config_details.battery_storage,
    //   'night_load': component_config_details.night_load,
    // }
  }

  // console.log("SYSTEM INFO", system_information);

  const resultt = inverterComp.design_voltage === "HV" ? CalculateHighVoltageSetup(system_information) : CalculateLowVoltageSetup(system_information);
  return resultt;
}

// const CalculateHighVoltageSetup = (system_information) => {
//   // logger.log("sizingComponentCalculations", "Calculating High Voltage Setup");
//   const inverterComp = system_information.inverterComponent;
//   const pvComp = system_information.pvComponent;
//   const batteryComp = system_information.batteryComponent;

//   // Constants
//   // const inverter_max_mppts = inverterComp.num_mppts;
//   // const inverter_num_inputs_per_mppts = inverterComp.num_inputs_per_mppts;

//   const max_battery_per_stack = batteryComp.max_per_stack;

//   const max_pv_per_string = Math.floor(inverterComp.max_input_voltage / pvComp.VOC);

//   const number_of_pv = system_information.number_of_pv;
//   const number_of_batteries = system_information.number_of_batteries;
//   let number_of_inverters = system_information.number_of_inverters;

//   // Calculate the number of strings needed (rounded up)
//   const number_of_strings = Math.ceil(number_of_pv / max_pv_per_string);

//   // Calculate the number of inverters needed
//   const number_of_inverters_needed = Math.ceil(number_of_strings / inverterComp.num_mppts);

//   // console.log("number_of_inverters", number_of_inverters);
//   // console.log("number_of_inverters_needed for these panels", number_of_inverters_needed);

//   // Validate if enough inverters are available
//   if (number_of_inverters_needed > number_of_inverters) {
//     // console.log("Not enough inverters available. Adjusting");
//     number_of_inverters = number_of_inverters_needed;
//   }

//   // Start configuration
//   let config = [];

//   // Distribute strings across inverters and MPPTs
//   let string_count = 0;
//   let panels_remaining = number_of_pv;
//   let batteries_remaining = number_of_batteries;

//   for (let inverter = 1; inverter <= number_of_inverters; inverter++) {
//     let mppts = [];

//     for (let mppt = 1; mppt <= inverterComp.num_mppts; mppt++) {
//       if (panels_remaining <= 0) break; // Stop when all panels are assigned

//       // Calculate the number of panels per MPPT
//       const panels_in_string = Math.min(max_pv_per_string, Math.ceil(panels_remaining / (number_of_inverters * inverterComp.num_mppts - string_count)));
//       mppts.push({ mppt, panels: panels_in_string });

//       panels_remaining -= panels_in_string;
//       string_count++;
//     }

//     // Calculate the number of batteries for this inverter
//     let batteries_for_inverter = 0;
//     if (batteries_remaining > 0) {
//       batteries_for_inverter = Math.min(batteries_remaining, max_battery_per_stack);
//       batteries_remaining -= batteries_for_inverter;
//     }

//     // Add inverter configuration
//     config.push({
//       inverter,
//       mppts,
//       batteries: batteries_for_inverter
//     });
//   }

//   return config;
// };

const CalculateHighVoltageSetup = (system_information) => {
  const inverterComp = system_information.inverterComponent;
  const pvComp = system_information.pvComponent;
  const batteryComp = system_information.batteryComponent;

  // Get number of components
  const number_of_batteries = system_information.number_of_batteries;
  const number_of_pv = system_information.number_of_pv;
  let number_of_inverters = system_information.number_of_inverters;

  // Get constants from components
  const battery_voltage = batteryComp.voltage;
  const panel_VMP = pvComp.VMP;
  let max_battery_per_stack = batteryComp.max_per_stack;
  const max_strings_per_mppt = inverterComp.num_inputs_per_mppts;

  // Calculate the number of panels that fit within the max voltage limits
  const max_panels_per_string = Math.floor(inverterComp.max_input_voltage / pvComp.VOC);




  const safety_margin = 20; // 20% safety margin for cases where the min is larger than max when high number of batteries
  const safety_min_panels = Math.ceil((100-safety_margin) * max_panels_per_string / 100); // Safety margin for min panels per string
  const new_max_batteries = Math.ceil(safety_min_panels / 1.5); // 1.5 times the safety min panels


  //  this is done to ensure that the number of batteries doesnt exceed 20% under what the max of the panel can handle
  if(max_battery_per_stack > new_max_batteries){
    max_battery_per_stack = new_max_batteries;
  }


    // Calculate the number of inverters needed based on the number of batteries
    const number_of_inverters_needed = Math.ceil(number_of_batteries / max_battery_per_stack);

    // Validate if enough inverters are available
    if (number_of_inverters_needed > number_of_inverters) {
      number_of_inverters = number_of_inverters_needed;
    }

  // Start configuration
  let config = [];
  let panels_remaining = number_of_pv;
  let batteries_remaining = number_of_batteries;

  for (let inverter = 1; inverter <= number_of_inverters; inverter++) {
    let mppts = [];
    let batteries_for_inverter = 0;

    // Calculate the number of batteries for this inverter
    if (batteries_remaining > 0) {
      batteries_for_inverter = Math.min(batteries_remaining, max_battery_per_stack);
      batteries_remaining -= batteries_for_inverter;
    }

    // Calculate the total voltage for this inverter
    const total_voltage = batteries_for_inverter * battery_voltage;
    const min_panels_per_string = Math.ceil(total_voltage / panel_VMP); // Ensure we meet the minimum panels per string

    let inverter_details = {
      'inverter': inverter,
      'max_panels_per_string': max_panels_per_string,
      'min_panels_per_string': min_panels_per_string,
      'max_strings_per_mppt': max_strings_per_mppt,
    }
    // Distribute panels across MPPTs
    for (let mppt = 1; mppt <= inverterComp.num_mppts; mppt++) {
      if (panels_remaining <= 0) break; // Stop when all panels are assigned

      let strings = [];
      let panels_in_string = min_panels_per_string;

      // First, fill each string with at least the minimum number of panels
      while (panels_remaining >= min_panels_per_string && strings.length < max_strings_per_mppt) {
        strings.push(panels_in_string);
        panels_remaining -= panels_in_string;
      }

      // Now, fill the strings progressively while maintaining the minimum number of panels per string
      for (let i = 0; i < strings.length; i++) {
        // Distribute extra panels to each string up to max_panels_per_string
        while (strings[i] < max_panels_per_string && panels_remaining > 0) {
          strings[i]++;
          panels_remaining--;
        }
      }

      // If there are still panels left, continue adding to existing strings (ensure min per string)
      while (panels_remaining > 0 && strings.length < max_strings_per_mppt) {
        if (strings[0] < max_panels_per_string) {
          strings[0]++; // Add to the first string
          panels_remaining--;
        } else if (strings[1] && strings[1] < max_panels_per_string) {
          strings[1]++; // Add to the second string
          panels_remaining--;
        } else if (strings.length < max_strings_per_mppt) {
          strings.push(1); // Add a new string if possible
          panels_remaining--;
        }
      }

      mppts.push({ mppt, strings });
    }

    // Add inverter configuration
    config.push({
      inverter,
      mppts,
      batteries: batteries_for_inverter,
      inverter_details
    });
  }

  return config;
};



const CalculateLowVoltageSetup = (system_information) => {
  // logger.log("sizingComponentCalculations", "Calculating LOW Voltage Setup");

  const inverterComp = system_information.inverterComponent;
  const pvComp = system_information.pvComponent;

  // Constants
  const max_pv_per_string = Math.floor(inverterComp.max_input_voltage / pvComp.VOC);

  const number_of_pv = system_information.number_of_pv;
  const number_of_batteries = system_information.number_of_batteries;
  let number_of_inverters = system_information.number_of_inverters;

  // Calculate the number of strings needed (rounded up)
  const number_of_strings = Math.ceil(number_of_pv / max_pv_per_string);

  // Calculate the number of inverters needed
  const number_of_inverters_needed = Math.ceil(number_of_strings / inverterComp.num_mppts);

  // console.log("number_of_inverters", number_of_inverters);
  // console.log("number_of_inverters_needed for these panels", number_of_inverters_needed);

  // Validate if enough inverters are available
  if (number_of_inverters_needed > number_of_inverters) {
    // console.log("Not enough inverters available. Adjusting");
    number_of_inverters = number_of_inverters_needed;
  }

  // Calculate batteries per inverter
  const batteries_per_inverter = (number_of_batteries / number_of_inverters).toFixed(2);

  // Start configuration
  let config = [];

  // Distribute strings across inverters and MPPTs
  let string_count = 0;
  let panels_remaining = number_of_pv;

  for (let inverter = 1; inverter <= number_of_inverters; inverter++) {
    let mppts = [];

    for (let mppt = 1; mppt <= inverterComp.num_mppts; mppt++) {
      if (panels_remaining <= 0) break; // Stop when all panels are assigned

      // Calculate the number of panels per MPPT
      const panels_in_string = Math.min(max_pv_per_string, Math.ceil(panels_remaining / (number_of_inverters * inverterComp.num_mppts - string_count)));
      mppts.push({ mppt, panels: panels_in_string });

      panels_remaining -= panels_in_string;
      string_count++;
    }

    // Add inverter configuration
    config.push({
      inverter,
      mppts,
      batteries: parseFloat(batteries_per_inverter)
    });
  }

  return config;
}


// Utility functions
const transformClampsStatus = (clampsStatus) => {
  return Object.keys(clampsStatus).map(clampId => ({
    id: clampId,
    enabled: clampsStatus[clampId].enabled,
    minus: clampsStatus[clampId].deduct_from_total
  }));
};

const calculateSizingReport = (final_response) => {
  const {

    components,
    hourReadings,
    clampsStatus,
    system_results,
    average_day_kw,
    overall,
    batteryCount,
    inverterCount,
    pvCount, phase
  } = final_response;

    // get the local timezone to adjust the timestamps
    // const timezone = 'Africa/Johannesburg';

    // const formatted_hourReadings = hourReadings.map(reading => ({ ...reading, time: convertToTimezone(reading.time, timezone) }));

  const clamps = transformClampsStatus(clampsStatus);
  // const dailyUseKwh = calculateDailyUseKwh(hourReadings, clamps);
  const dailyUseKwh = average_day_kw * 24;

  if(system_results.solar_panel_result === undefined) {
    system_results.solar_panel_result = {
      panel_generation_kw: 0,
      no_units: 0,
      pv_array_size: 0,
      WP: 0
    }
  }
  const solar_coverage_kw = system_results.solar_panel_result.panel_generation_kw;
  const pvWattsAcAnnual = calculatePvWattsAcAnnual(components.details.panels);


  return {
    dailyUseKwh,
    solar_coverage_kw,
    pvWattsAcAnnual,
    batteryCapacityKwh: components.battery.kwh,
    inverterCapacityKw: components.inverter.rated_output,
    hourReadings,
    clamps,
    panel: components.details.panels[0],
    noPanels: pvCount,
    components,
    system_results,
    average_day_kw,
    batteryCount,
    inverterCount,
    pvCount,
    overall,
    phase
  };
};



const processSizingReport = (final_response, batteryCount, inverterCount, pvCount) => {


  // console.log("first report", final_response);
  final_response.batteryCount = batteryCount;
  final_response.inverterCount = inverterCount;
  final_response.pvCount = pvCount;
  const sizingReport = calculateSizingReport(final_response);
  const { system_results } = sizingReport;

  try {
    let dailySizingViewEstimate;
    let weeklyUsageSimulation;
    
    if (system_results.solar_panel_result.pv_array_size === 0) {
      dailySizingViewEstimate = null;
      weeklyUsageSimulation = null;
    } else {
      dailySizingViewEstimate = calculateDailySizingViewEstimate(sizingReport);
      weeklyUsageSimulation = calculateWeeklyUsageSimulation(sizingReport);
    }
    const estimatedComponentCosts = calculateEstimatedComponentCosts(sizingReport);
    const componentConfiguration = calculateComponentConfiguration(
      sizingReport.components.details.inverters,
      sizingReport.components.details.panels,
      pvCount
    );

    const inverterOperatingCapacityProjectionData = calculateInverterOperatingCapacityGraphData(sizingReport.hourReadings, inverterCount, sizingReport.inverterCapacityKw, sizingReport.phase === 'three' ? true : false);


    return {
      dailySizingViewEstimate,
      weeklyUsageSimulation,
      estimatedComponentCosts,
      componentConfiguration,
      inverterOperatingCapacityProjectionData
    };
  } catch (error) {
    console.error("Error processing sizing report:", error);
    return {
      dailySizingViewEstimate: null,
      weeklyUsageSimulation: null,
      estimatedComponentCosts: null,
      componentConfiguration: null,
      inverterOperatingCapacityProjectionData: null
    
    };
  }
};

// Ensure all functions are exported correctly
export {
  processSizingReport,
  calculateEstimatedComponentCosts,
  calculateComponentConfiguration,
};