import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select, message, Modal, Table, Alert, Flex } from 'antd';
import { fetchFeedback, pushFeedback, addFeedbackComment, closeTicket } from '../services/apiService';
import CommentsModal from './CommentsModal';


const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

function Feedback({ isConnectedToBusiness, userData, userEnabledDarkMode }) {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tickets, setTickets] = useState([]);

    const [comments, setComments] = useState([
        { user: 'User1', comment: 'This is a comment.', timestamp: '2023-10-01 10:00' },
        { user: 'User2', comment: 'This is another comment.', timestamp: '2023-10-01 10:05' },
      ]);

      const [visible, setVisible] = useState(false);

      const [currentTicketId, setCurrentTicketId] = useState(null);
      const [selectedTicket, setSelectedTicket] = useState({});




    useEffect(() => {
        const fetchTickets = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetchFeedback(token);
                // console.log("Feedback response", response);
                setTickets(response);
            } catch (error) {
                console.error('Failed to fetch tickets:', error);
            }
        };
if(isConnectedToBusiness){
        fetchTickets();
}
    }, [isConnectedToBusiness]);

    const onFinish = async (values) => {
        // console.log('Received values:', values);

        try {
            const temp_response = {
                "feedback": {
                    "type": values.category,
                    "subject": values.subject,
                    "description": values.description
                }
            }
            const token = localStorage.getItem('accessToken');
            const response = await pushFeedback(token, temp_response);
            if (response.error) {
                message.error(response.error + " Please wait 5 min"); // Display the error message to the user
            } else {
                message.success('Feedback submitted successfully');
                // console.log(response);
                
            }
            form.resetFields();
            setIsModalVisible(false);
            // Refresh tickets
            const updatedTickets = await fetchFeedback(token);
            setTickets(updatedTickets);
            
            
            
        } catch (error) {
            console.error('Failed to submit feedback:', error);
            message.error('Failed to submit feedback');
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const handleAddComment = async (comment) => {
        try {
            const ticketId = currentTicketId;
            const token = localStorage.getItem('accessToken');
            const coment = {
                "ticketId": ticketId,
                "comment": comment
            }
            await addFeedbackComment(token, coment);
            message.success('Comment added successfully');
           setVisible(false);
            // Refresh tickets
            const updatedTickets = await fetchFeedback(token);
            setTickets(updatedTickets);
        } catch (error) {
            console.error('Failed to add comment:', error);
            if(error.response.status === 403){
                setComments([...comments]);
            }
            message.error('Failed to add comment');
        }
    };
  

    const handleCloseTicket = async (ticketId, reason) => {
        try {
            const token = localStorage.getItem('accessToken');

            await closeTicket(token, ticketId, reason);
            message.success('Ticket closed successfully');
            // Close the confirmation modal
            Modal.destroyAll();
            // Refresh tickets
            const updatedTickets = await fetchFeedback(token);
            setTickets(updatedTickets);
        } catch (error) {
            console.error('Failed to close ticket:', error);
            message.error('Failed to close ticket');
        }
        return true;
    };


    

 

    const showConfirm = (ticketId) => {
        let reason = '';
        confirm({
            title: 'Are you sure you want to close this ticket?',
            content: (
                <div>
                    <p>This action cannot be undone. Please provide a parting comment below so we know why you closed the ticket?</p>
                    <TextArea
                        placeholder="Please provide a reason"
                        onChange={(e) => reason = e.target.value}
                        rows={4}
                    />
                    <br />
                </div>
            ),
          footer: (
            <div>
                <br />
            <Flex gap="small">
              <Button onClick={() => handleCloseTicket(ticketId, reason)} type="primary">
                Close
              </Button>
              
              <Button onClick={() => Modal.destroyAll()}>
                Cancel
              </Button>
              </Flex>
              </div>
          ),
          onCancel() {
            console.log('Cancel');
          },
        });
      };

    // const showCommentModal = (ticketId) => {
    //     Modal.confirm({
    //         title: 'Add Comment',
    //         content: (
    //             <Form
    //                 onFinish={(values) => {
    //                     handleAddComment(ticketId, values.comment);
    //                 }}
    //             >
    //                 <Form.Item
    //                     name="comment"
    //                     rules={[{ required: true, message: 'Please enter your comment!' }]}
    //                 >
    //                     <Input placeholder="Enter your comment" />
    //                 </Form.Item>
    //                 <Form.Item>
    //                     <Button type="primary" htmlType="submit">
    //                         Add Comment
    //                     </Button>
    //                 </Form.Item>
    //             </Form>
    //         ),
    //     });
    // }

    const handleOpenComments = (record) => {
        setVisible(true);
        setCurrentTicketId(record._id);
        setSelectedTicket(record);
        setComments(record.comments);
    };

    const columns = [
        {
            title: 'Category',
            dataIndex: ['feedback', 'type'],
            key: 'type',
            render: (text) => {
                if (text === 'general') {
                    return 'General Feedback';
                }
                if (text === 'bug') {
                    return 'Bug Report';
                }
                if (text === 'feature') {
                    return 'Feature Request';
                }
                return text.charAt(0).toUpperCase() + text.slice(1); // Capitalize the first letter for other categories
            },
        },
        {
            title: 'Subject',
            dataIndex: ['feedback', 'subject'],
            key: 'subject',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        // {
        //     title: 'Reviewed',
        //     dataIndex: ['status', 'resolved'],
        //     key: 'resolved',
        //     render: (resolved) => (resolved ? 'Yes' : 'No'),
        // },
        {
            title: 'Closed',
            dataIndex: ['status', 'closed'],
            key: 'closed',
            render: (_, record) => {
                if (record.status == 'closed') {
                    return new Date(record.updated_at).toLocaleString();
                }
                return 'No';
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Flex gap="small"> 
                <Button
                    type="primary"
                    disabled={record.status != 'open'}
                    onClick={() => showConfirm(record._id)}
                >
                    Close Ticket
                </Button>
                <Button
                    type="primary"
                   
                    onClick={() => handleOpenComments(record)}
                >
                    View Details
                </Button>
                </Flex>
            ),
        },
    ];

    if (!isConnectedToBusiness) {
        return (
            <div>
                <h1>Tickets</h1>
                <Alert message="You are not connected to a business. Please connect to a business to create and view tickets." type="warning" showIcon />
            </div>
        );
    }

    return (
        <div style={{ margin: '10px' }}>
            <h1>Feedback</h1>
            <p>Create tickets to report bugs or make feature requests for the new system. You can also create tickets for general feedback. Once closed, tickets will expire 1 month after being closed for record keeping sake.</p>
            <Button type="primary" onClick={showModal}>
                Create New Ticket
            </Button>
            <Table
                dataSource={tickets}
                columns={columns}
                rowKey="_id"
                style={{ marginTop: '20px' }}
                rowClassName={(record) => {
                    let className = '';
                    if (record.status === 'closed' && userEnabledDarkMode === false) {
                      className = 'closed-ticket';
                    }
                    if (record.status === 'closed' && userEnabledDarkMode === true) {
                      className += ' closed-ticket-dark';
                    }
                    return className.trim();
                  }}
            />

            {/* <DebugItem variable={tickets} name="tickets" /> */}
            <Modal title="Create New Ticket" open={isModalVisible} onCancel={handleCancel} footer={null}>
                <Form form={form} name="feedback" onFinish={onFinish}>
                    <Form.Item
                        name="category"
                        label="Category"
                        rules={[{ required: true, message: 'Please select a category!' }]}
                    >
                        <Select placeholder="Select a category">
                            <Option value="bug">Bug Report</Option>
                            <Option value="feature">Feature Request</Option>
                            <Option value="general">General / Other</Option>
                        </Select>
                    </Form.Item>
                    
                    <Form.Item
                        name="subject"
                        label="Subject"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please enter your message!' }]}
                    >
                        <TextArea rows={2} placeholder='A Subject Title for the issue / feedback'/>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Description"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: 'Please enter your message!' }]}
                    >
                        <TextArea rows={4} placeholder='Your detailed description of the issue / feedback'/>
                    </Form.Item>

                    <p>Once you have created the ticket, you will be able to add a comment to give more details.</p>
                    
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Open Ticket
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <CommentsModal comments={comments} visible={visible} onClose={() => setVisible(false)}
        onAddComment={handleAddComment} currentUserId={userData.uuid} ticket={selectedTicket}/>
        </div>
    );
}


Feedback.propTypes = {
    isConnectedToBusiness: PropTypes.bool.isRequired,
    userData: PropTypes.object.isRequired,
    userEnabledDarkMode: PropTypes.bool.isRequired,
}


export default Feedback;