import { theme } from 'antd';

const { defaultAlgorithm, darkAlgorithm} = theme;

export const darkTheme = {
  algorithm: darkAlgorithm,
};

export const superAdminTheme = {
  algorithm: defaultAlgorithm,
  token: {
    colorPrimary: '#722ed1', // Purple color
  
},
};

export const superAdminThemeDark = {
  algorithm: darkAlgorithm,
  token: {
    colorPrimary: '#722ed1', // Purple color
  
},
};

export const defaultTheme = {
  algorithm: defaultAlgorithm,
 

};


