import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import withDynamicWidth from './withDynamicWidth';

const WeeklyConsumptionChart = ({ data, width }) => {
    return (
        <Plot
            data={data}
            layout={{
                width: width,
                height: 400, // Set a fixed height
                title: 'Weekly Consumption Chart',
                xaxis: { 
                    title: { text: 'Time', font: { size: 18 } }, 
                    showgrid: true, 
                    zeroline: false, 
                    tickangle: -45,
                    tickvals: Array.from({ length: 7 }, (_, i) => i * 24), // Show ticks at the start of each day
                    ticktext: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] // Labels for each day
                },
                yaxis: { 
                    title: { text: 'kW', font: { size: 18 } }, 
                    showgrid: true, 
                    zeroline: true 
                },
                plot_bgcolor: '#ffffff',
                paper_bgcolor: '#ffffff',
                scrollZoom: true,
            }}
            config={{ responsive: true }}
        />
    );
};

WeeklyConsumptionChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
};

export default withDynamicWidth(WeeklyConsumptionChart);