import React from "react";
import PropTypes from "prop-types";
import Plot from "react-plotly.js";
import withDynamicWidth from "./withDynamicWidth";
import withTheme from "./withTheme";

const PeakDataChart = ({ data, width, themeLayout }) => {
  return (
    <div>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <Plot
        data={data}
        layout={{
          width: width,
          height: 400, // Set a fixed height
          xaxis: {
            title: { font: { size: 18 } },
            showgrid: false,
            zeroline: false,
            tickformat: "%d %b %Y",
            dtick: 'D1',
          },
          yaxis: {
            title: { text: "kW", font: { size: 18 } },
            showgrid: true,
            zeroline: true,
            dtick: 0.5,
          },

          barmode: "stack",

          scrollZoom: true,
          ...themeLayout,
        }}
      />
    </div>
  );
};

PeakDataChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  themeLayout: PropTypes.object.isRequired,
};

export default withDynamicWidth(withTheme(PeakDataChart));
