// src/utils/logger.js
const isDevelopment = 'development';

class Logger {
    constructor() {
        this.pageLogs = {};
    }

    enableLogging(page) {
        this.pageLogs[page] = true;
    }

    disableLogging(page) {
        this.pageLogs[page] = false;
    }

    log(page, ...args) {
        if (isDevelopment && this.pageLogs[page]) {
            console.log(`[${page}]`, ...args);
        }
    }

    warn(page, ...args) {
        if (isDevelopment && this.pageLogs[page]) {
            console.warn(`[${page}]`, ...args);
        }
    }

    error(page, ...args) {
        if (isDevelopment && this.pageLogs[page]) {
            console.error(`[${page}]`, ...args);
        }
    }
}

const logger = new Logger();
export default logger;