import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
      faHouse, faDiagramProject, faArrowRightFromBracket,
    faCircleQuestion, faStore, faCartShopping, faObjectGroup, faTicketSimple,
    faHardDrive,
    faLineChart,
    
    
    faBasketShopping,
    
    faSliders,
    faUserShield,
    faFolderOpen,
    faComments
} from '@fortawesome/free-solid-svg-icons';
// import { Badge } from 'antd';
function NavBar({ handleLogout, userData, theme }) {
    const location = useLocation();
    const selectedKey = location.pathname;

    return (
        <Menu theme={theme} mode="inline" selectedKeys={[selectedKey]}>
            <Menu.Item key="/" icon={<FontAwesomeIcon icon={faHouse} />}>
                <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="/profile" icon={<FontAwesomeIcon icon={faSliders} />}>
                <Link to="/profile">Settings</Link>
            </Menu.Item>
            {/* <Menu.Item key="/leads" icon={<FontAwesomeIcon icon={faAddressBook} />}>
                <Link to="/leads">Leads</Link>
            </Menu.Item> */}
           
            {userData.isSuperAdmin && (
                <Menu.SubMenu key="admin" icon={<FontAwesomeIcon icon={faUserShield} />} title="Super Admin">
  <Menu.Item key="/components" icon={<FontAwesomeIcon icon={faHardDrive} />}>
                    <Link to="/components">Components</Link>
                </Menu.Item>
                <Menu.Item key="/ticket-management" icon={<FontAwesomeIcon icon={faComments} />}>
                    <Link to="/ticket-management">Tickets</Link>
                </Menu.Item>
                </Menu.SubMenu>
            )}
          
          <Menu.SubMenu key="management" icon={<FontAwesomeIcon icon={faFolderOpen} />} title="Workspace">
        
    <Menu.Item key="/projects" icon={<FontAwesomeIcon icon={faDiagramProject} />}>
        <Link to="/projects">Projects</Link>
    </Menu.Item>
    {/* {userData.privilage && userData.privilage.level === "1" && ( */}
        <Menu.Item key="/networks" icon={<FontAwesomeIcon icon={faObjectGroup} />}>
            <Link to="/networks">Networks</Link>
        </Menu.Item>
    {/* )} */}
    <Menu.Item key="/monitors" icon={<FontAwesomeIcon icon={faLineChart} />}>
        <Link to="/monitors">Monitor Usage</Link>
    </Menu.Item>
</Menu.SubMenu>
{/* <Menu.Item key="/3Dtool" icon={<FontAwesomeIcon icon={faTv} />}>
    <Link to="/3Dtool">3D Tool</Link>
</Menu.Item> */}

            <Menu.SubMenu key="shopping" icon={<FontAwesomeIcon icon={faStore} />} title="Shopping" disabled>
                <Menu.Item key="/categories" icon={<FontAwesomeIcon icon={faCartShopping} />}>
                    <Link to="/categories">Store</Link>
                </Menu.Item>
                <Menu.Item key="/cart" icon={<FontAwesomeIcon icon={faBasketShopping} />}>
                    <Link to="/cart">Cart</Link>
                </Menu.Item>
            </Menu.SubMenu>
            
            <Menu.SubMenu key="help" icon={<FontAwesomeIcon icon={faCircleQuestion} />} title="Help">

            <Menu.Item key="/faq" icon={<FontAwesomeIcon icon={faCircleQuestion} />}>
                <Link to="/faq">FAQ</Link>
            </Menu.Item>
            <Menu.Item key="/tickets" icon={<FontAwesomeIcon icon={faTicketSimple} />}>
                <Link to="/tickets">Feedback</Link>
            </Menu.Item>
        </Menu.SubMenu>
            <Menu.Item key="/logout" icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />} onClick={handleLogout}>
                Logout
            </Menu.Item>
        </Menu>
    );
}

NavBar.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    userData: PropTypes.object.isRequired,
    theme: PropTypes.string.isRequired,
};

export default NavBar;