import React, { useEffect, useState } from "react";
import { Table, InputNumber, Collapse, Button, Alert, Flex, Space, Badge, notification } from "antd";
import { processSizingReport, CalculateComponentSetup } from "../sizingComponentCalculations"; // Correct import
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DailySizingChartComponent from "../charts/DailySizingChartComponent";
import WeeklySimulationChart from "../charts/WeeklySimulationChart";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlug,
    faMinus,
    faPlugCircleXmark,
    faGauge,
} from "@fortawesome/free-solid-svg-icons";
import InverterOperatingCapacityChart from "../charts/InverterOperatingCapacityChart";
import CostPlotChart from "../charts/CostPlotChart";
import { useNavigate } from "react-router-dom";

import logger from "../../utils/logger";
logger.enableLogging('SizingReport');

const { Panel } = Collapse;



const SizingReport = (reportResponse) => {
   
    
    
    const navigate = useNavigate();
    
    // const [tableColumns, setTableColumns] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [reportCostData, setReportCostData] = React.useState([]);
    const [monitorUUID, setMonitorUUID] = React.useState("null");

    const [batteryCompMeetsRequired, setBatteryCompMeetsRequired] = React.useState(true);
    const [inverterCompMeetsRequired, setInverterCompMeetsRequired] = React.useState(true);
    const [panelCompMeetsRequired, setPanelCompMeetsRequired] = React.useState(true);

    const [inverterCount, setInverterCount] = useState(0);
    const [batteryCount, setBatteryCount] = useState(0);
    const [pvCount, setPvCount] = useState(0);

    const [sizingLiveData, setSizingLiveData] = React.useState([]);

    const [reportData, setReportData] = React.useState(null);

    const [inverterCost, setInverterCost] = React.useState(0);
    const [batteryCost, setBatteryCost] = React.useState(0);
    const [panelCost, setPanelCost] = React.useState(0);

    const [configSetupData, setConfigSetupData] = React.useState([]);

    const [clampsStatus, setClampsStatus] = useState({
        clamp1: { name: "CT Clamp 1", enabled: true, deduct_from_total: false },
        clamp2: { name: "CT Clamp 2", enabled: true, deduct_from_total: false },
        clamp3: { name: "CT Clamp 3", enabled: true, deduct_from_total: false },
    });

    // const [pdfUrl, setPdfUrl] = useState("");

    // useEffect(() => {

    //     if( reportData && sizingLiveData){
    //     generatePDF();
    // }

    //     return () => {
    //         if (pdfUrl) {
    //           URL.revokeObjectURL(pdfUrl);
    //         }
    //       };
    // }, []);



    const navigateToQuotePage = () => {
        navigate('/quote', {
            state: {
                sizingData: {
                    monitorUUID,
                    clampsStatus,
                    configSetupData,
                    tableData,
                    inverterCount,
                    batteryCount,
                    pvCount
                }
            }
        });
    };





    async function generatePDF() {

        // notification to inform that it is generating
        
        notification.info({
            message: 'Generating PDF',
            description: 'Please wait while the PDF is being generated',
            placement: 'bottomRight'
        });

        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 15;

        // Add title
        pdf.setFontSize(25);
        pdf.text('Project Report', 10, position);
        position += 15;
    
        // Add user details
        pdf.setFontSize(12);

        let labelWidth = 0;

        // Client
        pdf.setFont('helvetica', 'normal');
        const clientLabel = 'Client:';
        pdf.text(clientLabel, 10, position);
        labelWidth = pdf.getTextWidth(clientLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${reportData.clientName}`, 10 + labelWidth, position);
        position += 6;

        // Monitor
        pdf.setFont('helvetica', 'normal');
        const monitorLabel = 'Monitor:';
        pdf.text(monitorLabel, 10, position);
        labelWidth = pdf.getTextWidth(monitorLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${reportData.monitor_uid}`, 10 + labelWidth, position); // Adding 2 for a small gap
        position += 6;

        // Monitoring Period
        pdf.setFont('helvetica', 'normal');
        const monitoringPeriodLabel = 'Monitoring Period:';
        pdf.text(monitoringPeriodLabel, 10, position);
        labelWidth = pdf.getTextWidth(monitoringPeriodLabel);

            // Function to format date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    });
};


        pdf.setFont('helvetica', 'bold');
        const startDate = formatDate(reportData.hourReadings[0].time);
const endDate = formatDate(reportData.hourReadings[reportData.hourReadings.length - 1].time);
pdf.text(` ${startDate} - ${endDate}`, 10 + labelWidth, position);
        position += 6;

        // Average Usage Per Day
        const average_daily_usage = sizingLiveData.dailySizingViewEstimate.solarOversize[0].Day + sizingLiveData.dailySizingViewEstimate.solarOversize[0].Night;
        pdf.setFont('helvetica', 'normal');
        const averageUsageLabel = 'Average Usage Per Day:';
        pdf.text(averageUsageLabel, 10, position);
        labelWidth = pdf.getTextWidth(averageUsageLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${average_daily_usage} kWh`, 10 + labelWidth, position);
        position += 6;

        // Highest Peak kW
        pdf.setFont('helvetica', 'normal');
        const highestPeakLabel = 'Highest Peak kW:';
        pdf.text(highestPeakLabel, 10, position);
        labelWidth = pdf.getTextWidth(highestPeakLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${reportData.system_results.inverter_result.peak_required_kw} kW`, 10 + labelWidth, position);
        position += 12;

        // Battery Bank Size
        pdf.setFont('helvetica', 'normal');
        const batteryBankSizeLabel = 'Battery Bank Size:';
        pdf.text(batteryBankSizeLabel, 10, position);
        labelWidth = pdf.getTextWidth(batteryBankSizeLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${reportData.system_results.backup_hours}h`, 10 + labelWidth, position);
        position += 6;

        // Inverter Size
        pdf.setFont('helvetica', 'normal');
        const inverterSizeLabel = 'Inverter Size:';
        pdf.text(inverterSizeLabel, 10, position);
        labelWidth = pdf.getTextWidth(inverterSizeLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${reportData.system_results.inverter_capacity_percent}%`, 10 + labelWidth, position);
        position += 6;

        // PV Array Size
        pdf.setFont('helvetica', 'normal');
        const pvArraySizeLabel = 'PV Array Size:';
        pdf.text(pvArraySizeLabel, 10, position);
        labelWidth = pdf.getTextWidth(pvArraySizeLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${reportData.system_results.solar_panel_result.pv_array_size}%`, 10 + labelWidth, position);
        position += 12;


        // // Add title text
        // pdf.setFont('helvetica', 'bold');
        // pdf.setFontSize(18);
        // pdf.text('Monitoring', 10, position);
        // pdf.setFontSize(12);
        // position += 6;

        // pdf.setFont('helvetica', 'normal');
        // pdf.text(`TODO! Implement Charts here`, 10, position);
        // position += 12;

        // Add title text
        pdf.setFont('helvetica', 'bold');
        // set underline

        pdf.setFontSize(18);
        pdf.text('Sizing Results', 10, position);
        pdf.setFontSize(12);
        position += 10;

        pdf.setFont('helvetica', 'normal');

        // process the sizing charts first
        const graphIds = ['weeklyChart', 'dailyChart', 'inverterChart']; // Replace with your actual graph IDs
        const graphTexts = ['Weekly Usage Simulation:', 'Daily Sizing View Estimate:', 'Inverter Operating Capacity Projection (% of time):']; // Corresponding texts for each graph

        for (let i = 0; i < graphIds.length; i++) {
            const graph = document.getElementById(graphIds[i]);
            if (graph) {
                const canvas = await html2canvas(graph, { scale: 2 });
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 190; // A4 width in mm minus margins
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                if (position + imgHeight + 10 > 297) {
                    pdf.addPage();
                    position = 10;
                }

                // Add text before each graph
                pdf.setFontSize(12);
                pdf.text(graphTexts[i], 10, position);
                position += 10;

                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                position += imgHeight + 10;
            } else {
                console.error(`Element with ID ${graphIds[i]} not found.`);
            }
        }

        // add system comp recommendations
        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(18);
        pdf.text('System Recommendations', 10, position);
        pdf.setFont('helvetica', 'normal');
        pdf.setFontSize(12);
        position += 12;

            // process the results
        const specsGraphs = ['compsTable', 'compsLayout']; // Replace with your actual graph IDs
        const specsTexts = ['Table of recommended components:', 'Recommended component configuration as per spec requirements:']; // Corresponding texts for each graph

        for (let i = 0; i < specsGraphs.length; i++) {
            const graph = document.getElementById(specsGraphs[i]);
            if (graph) {
                const canvas = await html2canvas(graph, { scale: 2 });
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 190; // A4 width in mm minus margins
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                if (position + imgHeight + 10 > 297) {
                    pdf.addPage();
                    position = 10;
                }

                // Add text before each graph
                pdf.setFontSize(12);
                pdf.text(specsTexts[i], 10, position);
                position += 10;

                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                position += imgHeight + 10;
            } else {
                console.error(`Element with ID ${specsGraphs[i]} not found.`);
            }
        }
        
        // // inverter
        // pdf.setFont('helvetica', 'normal');
        // const inverterRecommendedLabel = 'Inverter:';
        // pdf.text(inverterRecommendedLabel, 10, position);
        // labelWidth = pdf.getTextWidth(inverterRecommendedLabel);

        // pdf.setFont('helvetica', 'bold');
        // pdf.text(` ${reportData.system_results.inverter_result.peak_required_kw} kW`, 10 + labelWidth, position);
        // position += 12;
        


        pdf.save("sizing_report.pdf");
        // below is for viewing the pdf in the browser
        // const pdfBlob = pdf.output('blob');
        // const url = URL.createObjectURL(pdfBlob);
        // setPdfUrl(url);
       
    }



    // Whenever changes are made to the count of the components, we pass them and calculate the sizing report details
    useEffect(() => {

        const processReport = async () => {
            const new_live_sizing_data = await processSizingReport(reportData, batteryCount, inverterCount, pvCount);
            setSizingLiveData(new_live_sizing_data);
        };

        if (reportData && batteryCount && inverterCount && pvCount !== undefined && pvCount !== null) {
            processReport();
        }
    }, [pvCount, batteryCount, inverterCount, reportData]);

    

    useEffect(() => {
        logger.log("SizingReport","Loaded Sizing Page 2");
        logger.log("SizingReport","Report Response: ", reportResponse);
        // Check if reportResponse is an empty object
        if (Object.keys(reportResponse.reportResponse).length === 0) {
            return;
        } else {
            logger.log("SizingReport", "IS RESPONSE", Object.keys(reportResponse).length);
        }


        const cleaned_Data = reportResponse.reportResponse;




        const inverter_comp_results = cleaned_Data.system_results.inverter_result;
        inverter_comp_results["component_uuid"] = cleaned_Data.components.inverter.component_uuid;
        const battery_comp_results = cleaned_Data.system_results.battery_result;
        battery_comp_results["component_uuid"] = cleaned_Data.components.battery.component_uuid;
        let solar_panel_results = {};

        if (cleaned_Data.system_results.solar_panel_result) {
            solar_panel_results = cleaned_Data.system_results.solar_panel_result;
            solar_panel_results["component_uuid"] = cleaned_Data.components.solar.component_uuid;
            setPvCount(solar_panel_results.no_units);
        }


        setClampsStatus(cleaned_Data.clampsStatus);
        setMonitorUUID(cleaned_Data.monitor_uid);

        setBatteryCount(battery_comp_results.no_units);
        setInverterCount(inverter_comp_results.no_units);


        // Process sizing report asynchronously
        const processReport = async () => {
            const new_live_sizing_data = await processSizingReport(cleaned_Data, battery_comp_results.no_units, inverter_comp_results.no_units, pvCount);
            setSizingLiveData(new_live_sizing_data);
        };

        processReport();





        // const barData = [
        //     {
        //         x: ["0-25%", "25-50%", "50-75%", "75-100%"],
        //         y: inverter_comp_results.operating_capacity_projections,
        //         type: "bar",
        //         marker: { color: "#1F77B4" },
        //         text: inverter_comp_results.operating_capacity_projections.map(
        //             (value) => `${value.toFixed(2)}%`
        //         ),
        //         textposition: "auto",
        //     },
        // ];
        // setInverterOperatingCapacityProjectionData(barData);

        // const final_cost_of_inverters = parseFloat(
        //     inverter_comp_results.cost
        // ).toFixed(2);
        // const final_cost_of_batteries = parseFloat(
        //     battery_comp_results.cost
        // ).toFixed(2);
        // const finalInverterSavings = parseFloat(
        //     cleaned_Data.final_inverter_savings
        // ).toFixed(2);
        // const finalBatterySavings = parseFloat(
        //     cleaned_Data.final_battery_savings
        // ).toFixed(2);

        setReportData(cleaned_Data);
        // setReportCostData(pieChartData);


        // setTotalSavings((parseFloat(finalBatterySavings) + parseFloat(finalInverterSavings)).toFixed(2));
        setInverterCost(parseFloat(inverter_comp_results.cost) || 0);
        setBatteryCost(parseFloat(battery_comp_results.cost) || 0);
        if (solar_panel_results && solar_panel_results.panel_cost) {
            setPanelCost(parseFloat(solar_panel_results.panel_cost) || 0);
        }


        const tableDatra =
            [
                {
                    key: "1",
                    category: "Inverter Technical",
                    title: "Deye SUN-8K",
                    component_uuid: inverter_comp_results.component_uuid,
                    output:
                        inverter_comp_results.inverter_rated_output_kw.toFixed(0) * inverter_comp_results.no_units,
                    requirement: inverter_comp_results.peak_required_kw.toFixed(2) + " kW",
                    quantity: inverter_comp_results.no_units,
                    calcOutput: inverter_comp_results.inverter_rated_output_kw,
                    calcCost: inverter_comp_results.cost_per_with_factor,
                    component_cost: inverter_comp_results.cost_per_inverter,
                },
                {
                    key: "2",
                    category: "Battery Technical",
                    title: battery_comp_results.battery_name,
                    component_uuid: battery_comp_results.component_uuid,
                    output: battery_comp_results.backup_power_provided.toFixed(2) * battery_comp_results.no_units,
                    requirement:
                        battery_comp_results.backup_power_required.toFixed(2) + " kWh",
                    quantity: battery_comp_results.no_units,
                    calcOutput: battery_comp_results.backup_power_provided,
                    calcCost: battery_comp_results.cost,
                    component_cost: battery_comp_results.cost,
                }

            ];

        if (solar_panel_results && solar_panel_results.name) {
            tableDatra.push({
                key: "3",
                category: "Panel Technical",
                title: solar_panel_results.name,
                component_uuid: solar_panel_results.component_uuid,
                output: (solar_panel_results.WP * solar_panel_results.no_units).toFixed(0),
                requirement: (solar_panel_results.required_solar_capacity_kw * 1000).toFixed(0) + " W",
                quantity: solar_panel_results.no_units,
                calcOutput: solar_panel_results.WP,
                calcCost: solar_panel_results.panel_price,
                component_cost: solar_panel_results.cost_per_panel,
            });
        }

        setTableData(tableDatra);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportResponse]);



    useEffect(() => {
        if (reportData) {
            determineSetup(reportData, batteryCount, inverterCount, pvCount);
        }
    }, [reportData, batteryCount, inverterCount, pvCount]);

    useEffect(() => {
        const pieChartData = [
            {
                values: [batteryCost, inverterCost, panelCost],
                labels: ["Total Battery Cost (ZAR)", "Total Inverter Cost (ZAR)", "Total Panel Cost (ZAR)"],
                type: "pie",
                hole: 0.6,
                textinfo: "label+value",
                texttemplate: "%{label}: R%{value}",
                textposition: "outside",
                automargin: true,
                marker: {
                    colors: ["#FF6347", "#1F77B4", "#F1E15B"], // Battery cost in red, inverter cost in blue
                },
            },
        ];
        setReportCostData(pieChartData);
    }, [inverterCost, batteryCost, panelCost]);


    const handleQuantityChange = (value, record) => {
        const newData = [...tableData];
        const index = newData.findIndex((item) => record.key === item.key);

        let newInverterCount = inverterCount;
        let newBatteryCount = batteryCount;
        let newPvCount = pvCount;

        let item_requirement = record.requirement.split(" ")[0];

        // let final_output = 0;


        if (index !== -1) {
            const item = newData[index];
            const newOutput = item.calcOutput * value;
            // final_output = newOutput;

            newData[index] = {
                ...item,
                quantity: value,
                output: newOutput.toFixed(0),
            };
            setTableData(newData);

            if (item.category === "Inverter Technical") {
                const new_cost = parseFloat(item.calcCost * value);
                setInverterCost(new_cost);
                newInverterCount = value; // Update inverter count
            } else if (item.category === "Battery Technical") {
                const new_cost = parseFloat(item.calcCost * value);
                setBatteryCost(new_cost);
                newBatteryCount = value; // Update battery count
            } else if (item.category === "Panel Technical") {
                const new_cost = parseFloat(item.calcCost * value);
                setPanelCost(new_cost);
                newPvCount = value; // Update battery count
            }
        }

        return {
            newData,
            newInverterCount,
            newBatteryCount,
            newPvCount,
            // final_output,
            item_requirement
        };
    };

    const editableColumns = [
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
        },
        {
            title: "Recommended Components",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Components Output",
            dataIndex: "output",
            key: "output",
            render: (text, record) => {
                // Add the appropriate unit based on the category
                let unit;
                let meetsRequirement;
                switch (record.category) {
                    case "Inverter Technical":
                        unit = " kW";
                        meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
                        setInverterCompMeetsRequired(meetsRequirement);
                        break;
                    case "Battery Technical":
                        unit = " kWh";
                        meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
                        setBatteryCompMeetsRequired(meetsRequirement);
                        break;
                    case "Panel Technical":
                        unit = " W";
                        meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
                        setPanelCompMeetsRequired(meetsRequirement);
                        break;
                    default:
                        unit = "";
                        meetsRequirement = true;
                }


            
                // Render with Badge
                return (
                    <div>
                        {!meetsRequirement ? (
                            <Badge status="warning" text={`${text}${unit}`} />
                        ) : (
                            `${text}${unit}`
                        )}
                    </div>
                );
            },
        },
        {
            title: "Requirement",
            dataIndex: "requirement",
            key: "requirement",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (text, record) => (
                <InputNumber
                    min={1}
                    value={text}
                    onChange={(value) => handleComponentQuanityChange(value, record)}
                />
            ),
        },
    ];


    const handleComponentQuanityChange = (value, record) => {

        const { newData, newInverterCount, newBatteryCount, newPvCount } = handleQuantityChange(value, record);


        // if (final_output < item_requirement) {
        //     setComponentsMeetRequirements(false);
        // }else {
        //     setComponentsMeetRequirements(true);
        // }
        setTableData(newData);
        setInverterCount(newInverterCount);
        setBatteryCount(newBatteryCount);
        setPvCount(newPvCount);
    
    };

    const formatConfigSetupData = (data) => {
        logger.log("SizingReport","INVERT SS: ", data);
        return (
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
               {(Array.isArray(data) ? data : []).map(inverter => (
                    <div key={inverter.inverter} style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                        <strong>Inverter {inverter.inverter}:</strong>
                        {inverter.mppts.map(mppt => (
                            <div key={mppt.mppt} style={{ paddingLeft: '20px' }}>
                                MPPT {mppt.mppt}: (String {mppt.mppt}: {mppt.panels} panels)
                            </div>
                        ))}
                        <div style={{ paddingLeft: '20px' }}>
                            Batteries: {inverter.batteries}
                        </div>
                    </div>
                ))}
            </div>
        );
    };




    const determineSetup = (report_data, batteryCount, inverterCount, pvCount) => {
        const component_config_details = {
            inverterComponent: report_data.components.inverter,
            batteryComponent: report_data.components.battery,
            pvComponent: report_data.components.solar,
            overall_avg_kwh: report_data.system_results.overall_avg_kwh,
            backup_hours: report_data.system_results.backup_hours,
            overall_peak_watt: report_data.overall.overall_peak_watt,
            batteryCount: batteryCount,
            inverterCount: inverterCount,
            pvCount: pvCount,
            pv_array_size: report_data.system_results.solar_panel_result.pv_array_size,
        }
        logger.log("SizingReport","Component Config Details: ", component_config_details);
        const setupData = CalculateComponentSetup(component_config_details);
        logger.log("SizingReport","Component Config Data: ", setupData);
        setConfigSetupData(setupData);
    };


    if (!reportData || Object.keys(reportData).length === 0) {
        return <div>No data available</div>;
    }

    return (
        <div>
            <h1>Sizing Report</h1>
            <p>Below are the results calculated from the previous step.</p>
            

            <div>

                <Space size="small" className="monitor_block">
                    <FontAwesomeIcon
                        icon={
                            faGauge
                        }
                    />
                    {monitorUUID}
                </Space>
                <br />
                <br />

                {clampsStatus && (
                    <ul className="horizontal-list">
                        <li>
                            <Space size="small">
                                <FontAwesomeIcon
                                    icon={
                                        clampsStatus["clamp1"]["enabled"]
                                            ? faPlug
                                            : faPlugCircleXmark
                                    }
                                />
                                {clampsStatus["clamp1"]["name"]}
                                {clampsStatus["clamp1"]["deduct_from_total"] && (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        style={{ float: "right" }}
                                    />
                                )}
                            </Space>
                        </li>
                        <li>
                            <Space size="small">
                                <FontAwesomeIcon
                                    icon={
                                        clampsStatus["clamp2"]["enabled"]
                                            ? faPlug
                                            : faPlugCircleXmark
                                    }
                                />
                                {clampsStatus["clamp2"]["name"]}
                                {clampsStatus["clamp2"]["deduct_from_total"] && (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        style={{ float: "right" }}
                                    />
                                )}
                            </Space>
                        </li>
                        <li>
                            <Space size="small">
                                <FontAwesomeIcon
                                    icon={
                                        clampsStatus["clamp3"]["enabled"]
                                            ? faPlug
                                            : faPlugCircleXmark
                                    }
                                />
                                {clampsStatus["clamp3"]["name"]}
                                {clampsStatus["clamp3"]["deduct_from_total"] && (
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        style={{ float: "right" }}
                                    />
                                )}
                            </Space>
                        </li>
                    </ul>

                )}
                <br />
            </div>

            <div>
                {reportData && reportData.system_results.type === 'hybrid' && (
                    <div>
                        <p>Weekly Simulation View Estimate</p>
                        <div id="weeklyChart">
                        {sizingLiveData && (
                            
                            <WeeklySimulationChart
                                weeklyUsageSimulation={sizingLiveData.weeklyUsageSimulation}
                            />
                            
                        )}
                        </div>
                    </div>
                )}
                <br />
            </div>

            <div>
                {reportData && reportData.system_results.type === 'hybrid' && (
                    <div>
                        <p>Daily Sizing View Estimate</p>
                        <div id="dailyChart">
                        {sizingLiveData && (
                            <DailySizingChartComponent dailySizingViewEstimate={sizingLiveData.dailySizingViewEstimate} />
                        )}
                        </div>
                    </div>
                )} 
                <br />
            </div>


            <div id="inverterChart">
               
                {sizingLiveData && sizingLiveData.inverterOperatingCapacityProjectionData && (
                    <InverterOperatingCapacityChart data={sizingLiveData.inverterOperatingCapacityProjectionData} />
                )}
                <br />
            </div>







            <div>
                <p>Estimated Costs of Main Components</p>
                <p>
                    Costing results are based on using our recommended components below.
                </p>
                <div>

                    {/* <Plot
                    data={reportCostData}
                    layout={{
                        width: 800,
                        autosize: true,
                        plot_bgcolor: "#ffffff",
                        paper_bgcolor: "#ffffff",
                        annotations: [
                            {
                                font: { size: 15 },
                                showarrow: false,
                                text: `R${(inverterCost + batteryCost).toFixed(2)}`,
                                x: 0.5,
                                y: 0.5,
                            },
                        ],
                    }}
                /> */}
                    <CostPlotChart costPlotData={{ reportCostData, inverterCost, batteryCost, panelCost }} />
                </div>
                {/* <p>Suggested Inverter: 1 x Deye SUN-8K</p> */}
                <br></br>
            </div>

            <div id="compsTable">
               


                <Table
                    dataSource={tableData}
                    columns={editableColumns}
                    pagination={false}
                    bordered
                    title={() => "Recommended Components Based On Calculations"}
                    footer={() =>
                        "* Component recommendations take into account the configuration and minimums required to setup the system optimally."
                    }
                />
                 {(!batteryCompMeetsRequired || !inverterCompMeetsRequired || !panelCompMeetsRequired) && (
                    <span>
                        <br />
                        <Alert message="CAUTION" description="Some of your components don't meet their required output in the table above. Only proceed if you know what you are doing" type="warning" showIcon />
                        
                    </span>
                )}
            </div>


            <br />
            <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }} id="compsLayout">

                <pre>{formatConfigSetupData(configSetupData)}</pre>

            </div>

            {/* {pdfUrl && (
        <iframe
          src={pdfUrl}
          width="100%"
          height="1000px"
          title="PDF Preview"
        ></iframe>
      )} */}

            <br />


            <Collapse >
                <Panel header="View First Calculation Results Debug Information" key="1">
                    <div className="debug-element">
                        <pre>{JSON.stringify(reportData, null, 2)}</pre>
                    </div>
                </Panel>
            </Collapse>
            <br />

                    {/* <pre>{JSON.stringify(sizingLiveData)}</pre> */}
            <br />
            <Collapse >
                <Panel header="View Second Calculation Results Debug Information" key="2">
                    <div className="debug-element">
                        <pre>{JSON.stringify(sizingLiveData, null, 2)}</pre>
                    </div>
                </Panel>
            </Collapse>
            <br />


            <Flex gap={"small"}>
            <Button type="primary" onClick={navigateToQuotePage}>Generate Quote</Button>
                <Button type="primary" onClick={() => generatePDF()}>Download PDF</Button>
            </Flex>
        </div>
    );
};

export default SizingReport;
