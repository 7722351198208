import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { confirmResetToken, setNewPassword } from '../services/apiService'; // Adjust the import path as necessary

const ResetPasswordPage = () => {

    const { token } = useParams();
    const [tokenValid, setTokenValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await confirmResetToken(token);
                // console.log("response", response);
                if (response.status === 'success') {
                    setTokenValid(true);
                } else {
                    message.error('Invalid or expired token.');
                    navigate('/login');
                }
            } catch (error) {
                console.error('Token verification failed:', error);
                message.error('Token verification failed.');
                navigate('/login');
            }
        };

        if (token) {
            verifyToken();
        } else {
            message.error('No token provided.');
            navigate('/login');
        }
    }, [token, navigate]);

    const onFinish = async (values) => {
        if (values.newPassword !== values.confirmPassword) {
            message.error('Passwords do not match.');
            return;
        }

        setLoading(true);

        try {
            const response = await setNewPassword(token, values.newPassword);
            if (response.status === 'success') {
                message.success('Password reset successfully.');
                navigate('/login');
            } else {
                message.error('Failed to reset password.');
            }
        } catch (error) {
            console.error('Password reset failed:', error);
            message.error('Password reset failed.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <h1>Reset Password</h1>
            <pre>{token}</pre>
            {tokenValid ? (
                <Form onFinish={onFinish} style={{ maxWidth: '300px' }}>
                    <Form.Item
                        name="newPassword"
                        rules={[{ required: true, message: 'Please input your new password!' }]}
                    >
                        <Input.Password placeholder="New Password" />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        rules={[{ required: true, message: 'Please confirm your new password!' }]}
                    >
                        <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                <p>Verifying token...</p>
            )}
        </div>
    );
};

export default ResetPasswordPage;