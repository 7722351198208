import { Button, Card, Breadcrumb, Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCategories } from '../services/apiService';

function Categories() {
    const [categories, setCategories] = React.useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('accessToken');
            const data = await fetchCategories(token);
            setCategories(data);
            setLoading(false);
        };

        fetchData();
    }, []);

    const breadcrumbItems = [
        {
            title: 'Shopping',
        },
    ];

    return (
        <div>
            <Breadcrumb items={breadcrumbItems} />
            <h1>Categories</h1>
            <p>Navigate different categories of products</p>
            <div>
                <div style={{ display: 'flex', gap: 'large', flexWrap: 'wrap' }}>
                    {loading ? (
                        Array(4).fill().map((_, index) => (
                            <Card key={index} style={{ width: 250 }}>
                                <Skeleton.Image style={{ width: '200px', height: '150px' }} />
                                <Skeleton active title={false} paragraph={{ rows: 1 }} />
                                <Skeleton active title={false} paragraph={{ rows: 2 }} />
                                <Skeleton.Button active style={{ width: 100 }} />
                            </Card>
                        ))
                    ) : (
                        categories.map(category => (
                            <Card key={category._id} style={{ width: 250 }}>
                                <img src={category.Image[0].url} alt={category.Title} style={{ width: '200px' }} />
                                <h2>{category.Title}</h2>
                                <p>{category.Description}</p>
                                <Button onClick={() => navigate(`/products?category=${category.id}&title=${encodeURIComponent(category.Title)}`)}>See Products</Button>
                            </Card>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default Categories;