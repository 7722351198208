import React, { useEffect, useState, useRef } from 'react';

const withDynamicWidth = (WrappedComponent) => {
    const WithDynamicWidth = (props) => {
        const [width, setWidth] = useState(0);
        const chartRef = useRef(null);

        useEffect(() => {
            const handleResize = () => {
                if (chartRef.current) {
                    setWidth(chartRef.current.offsetWidth);
                }
            };

            // Set initial width
            handleResize();

            // Add event listener for window resize
            window.addEventListener('resize', handleResize);

            // Cleanup event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        return (
            <div ref={chartRef} style={{ width: '100%' }}>
                <WrappedComponent {...props} width={width} />
            </div>
        );
    };

    WithDynamicWidth.displayName = `WithDynamicWidth(${getDisplayName(WrappedComponent)})`;

    return WithDynamicWidth;
};

const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export default withDynamicWidth;