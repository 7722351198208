import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tour, Button } from 'antd';

const TourComponent = ({ steps, tourKey }) => {
    const [tourOpen, setTourOpen] = useState(false);

    useEffect(() => {
        const tourCompleted = localStorage.getItem(tourKey);
        if (!tourCompleted) {
            setTourOpen(true);
        }
    }, [tourKey]);

    const handleTourClose = () => {
        setTourOpen(false);
        localStorage.setItem(tourKey, 'true');
    };

    const handleRedoTour = () => {
        setTourOpen(true);
        localStorage.removeItem(tourKey);
    };

    return (
        <>
            <Button type="default" onClick={handleRedoTour} style={{ marginLeft: '10px' }}>Redo Tour</Button>
            <Tour
                open={tourOpen}
                onClose={handleTourClose}
                steps={steps.map(step => ({
                    ...step,
                    target: step.targetId ? document.getElementById(step.targetId) : step.target,
                }))}
            />
            <br />
            <br />
        </>
    );
};

TourComponent.propTypes = {
    steps: PropTypes.array.isRequired,
    tourKey: PropTypes.string.isRequired,
};

export default TourComponent;