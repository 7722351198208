import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Input, notification, Table, Alert, Row, Col, Flex, Modal, Form } from 'antd';
// import SmallText from './SmallText'; // Adjust the import path as necessary
import { useLocation } from 'react-router-dom';
// import { fetchOrders, pushOrder } from '../services/apiService';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { fetchUserData } from '../services/apiService';

import logger from "../utils/logger";
logger.enableLogging('ClientQuotePage');

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        logger.log("ClientQuotePage", `selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
    }),
};

function ClientQuotePage({ isConnectedToBusiness }) {
    const location = useLocation();
    const [ClientQuotePageData, setClientQuotePageData] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [count, setCount] = useState(0); // Used to track new row keys
    const [userData, setUserData] = useState({});

    const [customerData, setCustomerData] = useState({
        'name': '',
        'email': '',
        'phone': '',
        'address': '',
        'ref_number': '',
    });

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleOk = (values) => {
      setCustomerData(values);
      setIsModalVisible(false);
    };

    useEffect(() => {
        if (location.state && location.state.sizingData) {
            const { sizingData } = location.state;
            setClientQuotePageData(sizingData);
            localStorage.setItem('ClientQuotePage', JSON.stringify(sizingData));
            // logger.log("ClientQuotePage", sizingData);
            // now to fetch user data
            const fetchData = async () => {

                const token = localStorage.getItem('accessToken');
                const userDataResponse = await fetchUserData(token);
                // const userData_temp = await userDataResponse.json();
                // logger.log("userData", userDataResponse.data);
                const tmp_cust = customerData;
                tmp_cust.name = userDataResponse.data.fullname;
                setCustomerData(tmp_cust);
                setUserData(userDataResponse.data);
            }
            fetchData();
        } else {
            let tempData = localStorage.getItem('ClientQuotePage');
            if (tempData === null || tempData === '{}') {
                tempData = {};
            } else {
                tempData = JSON.parse(tempData);
            }
            // logger.log("ClientQuotePage", tempData);
            setClientQuotePageData(tempData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state]);

    useEffect(() => {
        if (ClientQuotePageData.tableData) {
            const items = ClientQuotePageData.tableData;
            const aggregatedItems = items.reduce((acc, item) => {
                const existingItem = acc.find(i => i.key === item.key);
                if (existingItem) {
                    existingItem.quantity += item.quantity;
                } else {
                    acc.push({ ...item, quantity: item.quantity });
                }
                return acc;
            }, []);

            const newData = aggregatedItems.map((item) => ({
                key: item.key,
                title: item.title,
                quantity: item.quantity,
                stock: item.stock,
                markup: 10,
                id: item.id,
                price: item.component_cost,
                productCode: item.component_uuid,
                subtotal: item.component_cost * item.quantity,
                markupPrice: item.component_cost * 1.1,
                subMarkupPrice: item.component_cost * item.quantity * 1.1,
            }));

            setDataSource(newData);
            setCount(newData.length);
        }
    }, [ClientQuotePageData]);

    const handleDelete = () => {
        const updatedItems = dataSource.filter(item => !selectedRowKeys.includes(item.key));
        setDataSource(updatedItems);
        const updatedClientQuotePageData = { ...ClientQuotePageData, tableData: updatedItems };
        setClientQuotePageData(updatedClientQuotePageData);
        localStorage.setItem('ClientQuotePage', JSON.stringify(updatedClientQuotePageData));
        setSelectedRowKeys([]);

        notification.success({
            message: 'Success',
            description: 'Selected items have been removed from the ClientQuotePage',
            placement: 'bottomRight'
        });
    };

    const totalExVAT = dataSource.reduce((sum, item) => sum + item.subMarkupPrice, 0);
    const VAT = totalExVAT * 0.15;
    const total = totalExVAT + VAT;

    const handleAddRow = () => {
        const newRow = {
            key: count + 1,
            title: '',
            quantity: 1,
            stock: '',
            price: 0,
            subtotal: 0,
            markup: 10,
            markupPrice: 0,
            subMarkupPrice: 0,
        };
        setDataSource([...dataSource, newRow]);
        setCount(count + 1);
    };

    const handleEdit = (key) => {
        setEditingKey(key);
    };

    const handleSave = () => {
        setEditingKey('');
    };

    const handleInputChange = (key, dataIndex, value) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
            const item = newData[index];
            item[dataIndex] = value;
            if (dataIndex === 'quantity' || dataIndex === 'price') {

                item.subtotal = item.price * item.quantity; // Update price calculation
                item.markupPrice = item.price * (1 + (item.markup / 100)); // Update markup price calculation
                item.subMarkupPrice = item.subtotal * (1 + (item.markup / 100)); // Update markup subtotal calculation
            }
            setDataSource(newData);
        }
    };

    
    // const generatePDF = () => {
    //     const doc = new jsPDF();
    //     doc.text("Client Quote report for SAMPLE_NAME 2024/09/09", 10, 10);
    //     doc.save("client_quote.pdf");
       
    // }

    const generatePDF = () => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 10;
    
        // Add title
        pdf.setFontSize(25);
        pdf.text('Solar Quote', 10, position);
        position += 15;
    
        // Add user details
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`${userData.fullname}`, 10, position);
        position += 5;
        pdf.text(`${userData.email}`, 10, position);
        position += 10;
    
        // Add quote text
        pdf.setFontSize(20);
        pdf.text('Quote', 10, position);
        pdf.setFontSize(12);
        position += 10;
    
        // Add customer info
        pdf.setFont('helvetica', 'normal');
        // pdf.text('Customer Info:', 10, position);
        // position += 5;
        pdf.text(`Customer: ${customerData.name}`, 10, position);
        position += 5;
        pdf.text(`Phone Nr: ${customerData.phone}`, 10, position);
        position += 5;
        pdf.text(`Email: ${customerData.email}`, 10, position);
        position += 5;
        pdf.text(`Address: ${customerData.address}`, 10, position);
        position += 5;
        pdf.text(`QUOTE REF: ${customerData.ref_number}`, 10, position);
        position += 10;
        pdf.setFont('helvetica', 'normal');
    
        // Calculate totals
        const totalExVAT = dataSource.reduce((sum, item) => sum + item.subMarkupPrice, 0);
        const VAT = totalExVAT * 0.15;
        const total = totalExVAT + VAT;
    
        // Create a new table with specific columns and cells
        const tableData = dataSource.map(item => [
            item.title,
            item.quantity,
            new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(item.markupPrice),
            new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(item.subMarkupPrice)
        ]);
    
        // Add totals rows
        tableData.push([
            { content: 'Total (ex VAT)', colSpan: 3, styles: { halign: 'right' } },
            new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(totalExVAT)
        ]);
    
        tableData.push([
            { content: 'VAT', colSpan: 3, styles: { halign: 'right' } },
            new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(VAT)
        ]);
    
        tableData.push([
            { content: 'Total (inc VAT)', colSpan: 3, styles: { halign: 'right' } },
            new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(total)
        ]);
    
        pdf.autoTable({
            startY: position,
            head: [['Name', 'QTY', 'Price', 'Sub Total']],
            body: tableData,
            theme: 'grid',
            styles: { fontSize: 10 },
            headStyles: { fillColor: '#BDBDBD' },
            margin: { top: 10, left: 10, right: 10 }
        });
    
        // Update position after table
        position = pdf.autoTable.previous.finalY + 10;
    
        // Add text
        const text = `By accepting this quotation we accept that you agree to our terms and conditions set out by ${userData.fullname}`;
const wrappedText = pdf.splitTextToSize(text, 180); // 180 is the width in mm for the text to wrap
pdf.text(wrappedText, 10, position);
        // pdf.text(`By accepting this quotation we accept that you agree to our terms and conditions set out by ${customerData.name}`, 10, position);
        position += 20;

        pdf.setFontSize(8);

        pdf.text(`Generated by: ${userData.fullname} (${userData.email}) on Mon Oct 14 2024`, 10, position);
    
        pdf.save('quote.pdf');
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                editingKey === record.key ? (
                    <Input
                        value={text}
                        onChange={(e) => handleInputChange(record.key, 'title', e.target.value)}
                    />
                ) : (
                    <span onClick={() => handleEdit(record.key)}>{text || 'Click to edit'}</span>
                )
            )
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => (
                editingKey === record.key ? (
                    <Input
                        value={text}
                        type="number"
                        onChange={(e) => handleInputChange(record.key, 'quantity', e.target.value)}
                    />
                ) : (
                    <span onClick={() => handleEdit(record.key)}>{text || 'Click to edit'}</span>
                )
            )
        },
        {
            title: 'Price (no markup)',
            dataIndex: 'price',
            key: 'price',
            render: (text) => new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(text),
        },
        {
            title: 'Sub Total (no markup)',
            dataIndex: 'subtotal',
            key: 'subtotal',
            render: (text) => new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(text),
        },
        {
            title: 'Markup',
            dataIndex: 'markup',
            key: 'markup',
            render: (text) => `${(text).toFixed(2)}%`,
        },
        {
            title: 'Price (Markup)',
            dataIndex: 'markupPrice',
            key: 'markupPrice',
            render: (text) => new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(text),
        },
        {
            title: 'Sub Total (Markup)',
            dataIndex: 'subMarkupPrice',
            key: 'subMarkupPrice',
            render: (text) => new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(text),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, record) => (
                editingKey === record.key ? (
                    <Button onClick={() => handleSave(record.key)}>Save</Button>
                ) : (
                    <Button onClick={() => handleEdit(record.key)}>Edit</Button>
                )
            ),
        },
    ];



    if (!isConnectedToBusiness) {
        return (
            <div>
                <h1>ClientQuotePage</h1>
                <Alert message="You are not connected to a business. Please connect to a business to view and manage your ClientQuotePage." type="warning" showIcon />
            </div>
        );
    }

    return (
      <div>
        <h1>Client Quote</h1>
        <p>
          Use the table below to make a quote for you client. Add items to the
          quote and edit the line items as you would like. We have added the
          minimum bill of materials you need for the sizing done.
        </p>
        <p>
          You can download the quote as a PDF. You can also add these items to
          your cart and checkout to place an order (we&apos;ll only add the
          items that we stock).
        </p>
        {/* <pre>{JSON.stringify(userData, null, 2)}</pre> */}

        <Row>
          <Col span={12}>
            <h2>Client Info</h2>
            <p>
              Name: {customerData.name}
              <br />
              Email: {customerData.email}
              <br />
              Phone: {customerData.phone}
              <br />
              Address: {customerData.address}
            </p>
            <Button type="primary" onClick={showModal}>Edit Client Info</Button>
            <Modal
        title="Edit Client Info"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          initialValues={customerData}
          onFinish={handleOk}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: false, message: 'Please input the name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            
            rules={[{ required: false, message: 'Please input the email!' }]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: false, message: 'Please input the phone number!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: false, message: 'Please input the address!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
          </Col>
          <Col span={12}>
            <h2>Your Info</h2>
            <p>
              {userData.fullname}
              <br />
              {userData.email}
              <br />
              {userData.phone_number}
            </p>
            <p>
              VAT no:
              <br />
              *You can edit this information on you profile page
            </p>
          </Col>
        </Row>
        <Divider />

        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
            onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
          }}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          locale={{ emptyText: "No Items In ClientQuotePage Yet" }}
          summary={() => (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={columns.length - 1} align="right">
                  <strong>Total (ex VAT)</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  R {totalExVAT.toFixed(2)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={columns.length - 1} align="right">
                  <strong>VAT</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>R {VAT.toFixed(2)}</Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={columns.length - 1} align="right">
                  <strong>Total (inc VAT)</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell>R {total.toFixed(2)}</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )}
        />
        <Row style={{ marginTop: "16px" }}>
          <Col>
            <Button onClick={handleAddRow} style={{ marginRight: "8px" }}>
              Add Row
            </Button>
            <Button
              danger
              onClick={handleDelete}
              disabled={selectedRowKeys.length === 0}
            >
              Delete Selected
            </Button>
          </Col>
        </Row>

        <Row style={{ marginTop: "16px" }}>
          <Col>
          <Flex gap="small">
          <Button type="primary" onClick={generatePDF}>Download Quote PDF</Button>
          <Button type="primary" disabled>Add To Cart</Button>
          {/* <Button>Close</Button> */}
          </Flex>
          </Col>
        </Row>
       
      </div>
    );
}

ClientQuotePage.propTypes = {
    isConnectedToBusiness: PropTypes.bool.isRequired,
};

export default ClientQuotePage;
