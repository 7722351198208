import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

const LineChartComponent = ({ data, format, userEnabledDarkMode }) => {
    const [width, setWidth] = useState(0);
    const chartRef = useRef(null);
    const [layout, setLayout] = useState({});

    useEffect(() => {
        const handleResize = () => {
            if (chartRef.current) {
                setWidth(chartRef.current.offsetWidth);
            }
        };

        // Set initial width
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const darkModeLayout = {
            paper_bgcolor: '#141414',
            plot_bgcolor: '#141414',
            font: {
                color: '#ffffff'
            },
            xaxis: {
                tickfont: {
                    color: '#ffffff'
                },
                gridcolor: '#303030'
            },
            yaxis: {
                tickfont: {
                    color: '#ffffff'
                },
                gridcolor: '#303030'
            },
            legend: {
                font: {
                    color: '#ffffff'
                }
            }
        };

        const lightModeLayout = {
            paper_bgcolor: '#ffffff',
            plot_bgcolor: '#ffffff',
            font: {
                color: '#000000'
            },
            xaxis: {
                tickfont: {
                    color: '#000000'
                },
                gridcolor: '#e0e0e0'
            },
            yaxis: {
                tickfont: {
                    color: '#000000'
                },
                gridcolor: '#e0e0e0'
            },
            legend: {
                font: {
                    color: '#000000'
                }
            }
        };

        setLayout(prevLayout => ({
            ...prevLayout,
            ...(userEnabledDarkMode ? darkModeLayout : lightModeLayout),
            barmode: format === 'hourly' ? 'stack' : undefined,
            title: format === 'hourly' ? 'kWh Readings for Clamps' : 'kW Readings for Clamps',
            xaxis: {
                ...prevLayout.xaxis,
                title: 'Time',
                tickformat: '%H:%M', // Format ticks to show hours and minutes
            },
            yaxis: {
                ...prevLayout.yaxis,
                title: format === 'hourly' ? 'kWh' : 'kW',
                // dtick: 0.2,
            },
            legend: {
                ...prevLayout.legend,
                orientation: 'h',
                x: 0,
                y: 1.1,
            },
            width: width,
            height: 400, // Set a fixed height
        }));
    }, [userEnabledDarkMode, format, width]);

    const config = {
        responsive: true,
    };

    return (
        <div ref={chartRef} style={{ width: '100%' }}>
            <Plot data={data} layout={layout} config={config} />
        </div>
    );
};

LineChartComponent.propTypes = {
    data: PropTypes.array.isRequired,
    format: PropTypes.string.isRequired,
    userEnabledDarkMode: PropTypes.bool.isRequired,
};

export default LineChartComponent;