import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { Flex, Skeleton, Breadcrumb, notification, Button } from 'antd';
import { fetchProductById, pushToCart } from '../services/apiService';

function Product() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const product = queryParams.get('product'); // Get the category from the query parameters
    const category = queryParams.get('category'); // Get the category from the query parameters

    const [productData, setProductData] = React.useState([]);
    // const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('accessToken');
            const data = await fetchProductById(token, product);
            setProductData(data[0]);
            setLoading(false);
        };
        fetchData();
    }, [product]);



    if (loading) {
        return (
            <Flex gap={'large'}>
                <Skeleton width={200} height={200} />
                <div>
                    <Skeleton width={100} />
                    <Skeleton width={50} />
                    <Skeleton width={300} />
                </div>
            </Flex>
        );
    }

    function handleAddToCart(productData) {
        let data = {
          "quantity": productData['CPT Stock'],
          "price": productData['Retail Price'],
          "code": productData.Code,
          "description": productData.Description,
          "category": category,
        //   "image": productData.Image[0].url,
          "productID": productData.id,
          "stock": productData['CPT Stock']
        }
        console.log("data", data);
        pushToCart(data);
        notification.success({
          message: 'Success',
          description: productData.Code + ' added to cart',
          placement: 'bottomRight'
        });
    
      }


    return <div>
        <Breadcrumb>
            <Breadcrumb.Item>Shopping</Breadcrumb.Item>
            <Breadcrumb.Item>{category}</Breadcrumb.Item>
            <Breadcrumb.Item>{productData.Code}</Breadcrumb.Item>
        </Breadcrumb>
        {/* <pre>{JSON.stringify(productData)}</pre> */}
        <h1>{productData.Code}</h1>
        <div>
            <Flex gap={'large'}>
                <img src={productData.Image[0].url} style={{ width: '200px' }} />

                <div>
                    <p>R {productData['Retail Price']}</p>
                    <p>Stock {productData['CPT Stock']}</p>
                    <p>{productData.Description}</p>
                    <Button onClick={() => handleAddToCart(productData)} type="primary" disabled={product["CPT Stock"] === 0}>ADD TO CART</Button>
                </div>
            </Flex>
        </div>

    </div>;
}



export default Product;