import React from 'react';
import { Card } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';



const FeedbackCard = () => {
  return (
    <Card style={{ maxWidth: 300, margin: '0 auto', textAlign: 'center', border: '1px solid #e0e0e0', borderRadius: '8px' }}>
      <SmileOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
      <p className="feedback_text" style={{ color: '#1890ff', fontSize: '14px', marginTop: '10px' }}>
        Do you have any feedback or ideas? We would love to hear them! Feel free to go to the <Link to="/tickets" style={{ color: '#1890ff', textDecoration: 'underline' }}>feedback page</Link> to let us know!
      </p>
    </Card>
  );
};

export default FeedbackCard;