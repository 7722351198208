import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Divider, Input, notification, Table, Alert } from 'antd';
import SmallText from './SmallText'; // Adjust the import path as necessary

// import { fetchOrders, pushOrder } from '../services/apiService';
import { fetchProductById, pushOrder } from '../services/apiService';

// rowSelection object indicates the need for row selection
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
    }),
};

function Cart({ isConnectedToBusiness, userData }) {
    const selectionType = 'checkbox';
    const [cartData, setCartData] = useState({
        user: userData.uuid,
        business: 'some business',
        items: []
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [addDelivery, setAddDelivery] = useState(false);

    // const [orderData, setOrderData] = useState({});

    const handleCheckboxChange = (e) => {
        setAddDelivery(e.target.checked);
    };

    let dataSource = [];
    let items = [];

    const columns = [
        {
            title: 'Product Code',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Product Name',
            dataIndex: 'productCode',
            key: 'productCode',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: 'Item Price',
            dataIndex: 'price',
            key: 'price',
            render: (text) => `R ${text.toFixed(2)}`,
        },
        {
            title: 'Price',
            dataIndex: 'newprice',
            key: 'newprice',
            render: (text) => `R ${text.toFixed(2)}`,
        }
    ];

    useEffect(() => {
        let tempData = localStorage.getItem('cart');
        if (tempData === null || tempData === '{}') {
            tempData = {};
        } else {
            tempData = JSON.parse(tempData);
        }
        // console.log("Cart", tempData);
        setCartData(tempData);
    }, []);

    if (cartData.items) {
        items = cartData.items;

        // Aggregate items by product code
        const aggregatedItems = items.reduce((acc, item) => {
            const existingItem = acc.find(i => i.code === item.code);
            if (existingItem) {
                existingItem.quantity += item.quantity;
            } else {
                acc.push({ ...item, quantity: item.quantity });
            }
            return acc;
        }, []);

        dataSource = aggregatedItems.map((item) => ({
            key: item.code, // Use item.code as the key
            quantity: item.quantity,
            stock: item.stock, // Assuming stock is a separate field
            id: item.id,
            price: item.price,
            productCode: item.code,
            newprice: item.price * item.quantity,
            productName: item.name,
        }));
    }

    const handleDelete = () => {
        const updatedItems = items.filter(item => !selectedRowKeys.includes(item.code));
        const updatedCartData = { ...cartData, items: updatedItems };
        setCartData(updatedCartData);
        localStorage.setItem('cart', JSON.stringify(updatedCartData));
        setSelectedRowKeys([]);

        notification.success({
            message: 'Success',
            description: 'Selected items have been removed from the cart',
            placement: 'bottomRight'
        });
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('accessToken');

        // Fetch product details for each item
        const newItems = await Promise.all(items.map(async (item) => {
            const productDetails = await fetchProductById(token, item.id);
            return {
                ...item,
                product: productDetails[0]
            };
        }));

        const new_order = {
            items: newItems,
            uid: userData.uuid,
            total: total,
            totalExVAT: totalExVAT,
            VAT: VAT,
            delivery: addDelivery,
            reference: '',
            pickupLocation: 'Johannesburg',
            status: 'pending'
        };

        // Proceed with order submission logic here
        console.log(new_order);
        const order_response = await pushOrder(token, new_order);
        if (order_response.status === '200') {
            notification.success({
                message: 'Success',
                description: 'Order has been submitted',
                placement: 'bottomRight'
            });
        } else {
            notification.error({
                message: 'Error',
                description: 'Order submission failed',
                placement: 'bottomRight'
            });
        }
        // now to clear cart
        const updatedCartData = { items: [] };
        setCartData(updatedCartData);
        localStorage.setItem('cart', JSON.stringify(updatedCartData));

    };


    const totalExVAT = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const VAT = totalExVAT * 0.15;
    const total = totalExVAT + VAT;


    if (!isConnectedToBusiness) {
        return (
            <div>
                <h1>Cart</h1>
                <Alert message="You are not connected to a business. Please connect to a business to view and manage your cart." type="warning" showIcon />
            </div>
        );
    }

    return (
        <div>
            <h1>Cart</h1>
            {/* <pre>{JSON.stringify(selectedRowKeys)}</pre> */}
            <p>Use the table below to remove or update the quantity of products you want to order. Please go to the shopping page to add more products.

                Details in your profile will be used for delivery address and VAT number.</p>
            <div>


                <Divider />

                <Table
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                        onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    locale={{ emptyText: 'No Items In Cart Yet' }}
                    summary={() => (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={columns.length} align="right">
                                    <strong>Total (ex VAT)</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    R {totalExVAT.toFixed(2)}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={columns.length} align="right">
                                    <strong>VAT</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    R {VAT.toFixed(2)}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={columns.length} align="right">
                                    <strong>Total</strong>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    R {total.toFixed(2)}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    )}
                />
                <br />
                <Button danger onClick={handleDelete} disabled={selectedRowKeys.length === 0}>Remove Selected From Cart</Button>
                <br />
                <br />
                <Checkbox onChange={handleCheckboxChange}>
                    Add Delivery to Order (we will contact you to confirm delivery details)
                </Checkbox>
                <SmallText>Pickup Location (closest warehouse) : Johannesburg</SmallText>
                <Input placeholder="Optional Reference/Purchase Number" />
                <br />
                <br />
                <Button type="primary" onClick={handleSubmit} disabled={cartData.items.length === 0}>Submit to Scout</Button>
            </div>
        </div>
    );
}

Cart.propTypes = {
    isConnectedToBusiness: PropTypes.bool.isRequired,
    userData: PropTypes.object.isRequired,
};

export default Cart;