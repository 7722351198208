import React from 'react';

import ErrorBoundary from './ErrorBoundary';
import PropTypes from 'prop-types';

const ErrorBoundaryWrapper = ({ children }) => {


  return <ErrorBoundary >{children}</ErrorBoundary>;
};

ErrorBoundaryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundaryWrapper;