import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import withDynamicWidth from './withDynamicWidth';
import withTheme from './withTheme';

const DailyKWHChart = ({ data, width, themeLayout }) => {
    return (
      <Plot
        data={data}
        layout={{
          width: width,
          height: 400, // Set a fixed height
          xaxis: {
            title: { font: { size: 18 } },
            showgrid: false,
            zeroline: false,
            tickformat: "%d %b %Y",
            dtick: 'D1', // Set dtick to 'D1' to ensure each tick represents one day
          },
          yaxis: {
            title: { text: "Daily kWh", font: { size: 18 } },
            showgrid: true,
            zeroline: true,
            dtick: 0.5,
          },

          barmode: "stack",
          scrollZoom: true,
          ...themeLayout,
        }}
      />
    );
};

DailyKWHChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    themeLayout: PropTypes.object.isRequired,
};


export default withDynamicWidth(withTheme(DailyKWHChart));