import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { confirmInvite } from '../services/apiService'; // Adjust the import path as needed
import { message } from 'antd';
import logger from '../utils/logger';
logger.enableLogging('InviteConfirmation');

const InviteConfirmationPage = ({isConnectedToBusiness}) => {
   
    const { token } = useParams();
    
    const [resulttext, setResulttext] = useState('Confirming your invite...');

    useEffect(() => {
        const confirmUserEmail = async () => {
            try {
                const result = await confirmInvite(token);
                logger.log("InviteConfirmation", "result", result);
                setResulttext(result);
                
                if (result.status === 'success') {
                    message.success('Your invite has been confirmed successfully!');
                } else {
                    message.error('Invalid invite token.');
                }
            } catch (error) {
                message.error('Failed to confirm your invite. Please try again later.');
            }
        };

        confirmUserEmail();
    }, [token]);

    if(isConnectedToBusiness){
        return ("You are already connected to a business. Please disconnect to connect to another business.");
    }

    return (
        <div>
            <pre>{JSON.stringify(resulttext['status'])}</pre>
            {resulttext['status'] === 'loading' && <p>Confirming your invite...</p>}
            {resulttext['status'] === 'success' && <p>Your invite has been confirmed successfully!</p>}
            {resulttext['status'] === 'failure' && <p>Failed to confirm your invite. Please try again later.</p>}
        </div>
    );
};

InviteConfirmationPage.propTypes = {
    isConnectedToBusiness: PropTypes.bool,
};

export default InviteConfirmationPage;