// src/components/Version.js
import React from 'react';

const Version = () => {
    const version = '1.3.0'; // Replace with your actual version number
    return (
        <div style={{ position: 'fixed', bottom: 10, right: 10, color: 'gray' }}>
            Beta Version: {version}
        </div>
    );
};

export default Version;