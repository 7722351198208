import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Steps, Button, Layout, Alert } from "antd";
import Sizing from "./sizing_components/Sizing";
import SizingReport from "./sizing_components/SizingReport";
import logger from "../utils/logger";
const { Step } = Steps;
const { Content } = Layout;

logger.enableLogging('MonitorSizing');

const MonitorSizing = ({ isConnectedToBusiness, userEnabledDarkMode }) => {
    const [current, setCurrent] = useState(0);
    const [reportResponse, setReportResponse] = useState(null);


    // const USERDARKMODE = userEnabledDarkMode === true;

    const next = useCallback(() => {
        setCurrent((prevCurrent) => prevCurrent + 1);
    }, []);

    const prev = () => {
        setCurrent((prevCurrent) => prevCurrent - 1);
    };

    useEffect(() => {
        if (reportResponse !== null) {
            // logger.log("MonitorSizing", "reportResponse is ", reportResponse);
            next();
        } else {
            logger.log("MonitorSizing", "reportResponse is null");
            // message.error("Please generate a sizing report before proceeding to the next step. Was NULL");
        }
    }, [reportResponse, next]);

    const steps = [
        {
            title: "Sizing",
            content: <Sizing setReportResponse={setReportResponse} userEnabledDarkMode={userEnabledDarkMode} />,
        },
        {
            title: "Sizing Report and Adjustments",
            content: <SizingReport reportResponse={reportResponse} userEnabledDarkMode={userEnabledDarkMode} />,
        },
    ];

    if (!isConnectedToBusiness) {
        return (
            <div>
                <h1>Sizings</h1>
                <Alert message="You are not connected to a business. Please connect to a business work with sizings" type="warning" showIcon />
            </div>
        );
    }

    return (
        <div>
           
            <Layout style={{ padding: "24px" }}>
                <Content>
                    <Steps current={current}>
                        {steps.map((item, index) => (
                            <Step key={index} title={item.title} />
                        ))}
                    </Steps>
                    <div style={{ marginTop: "24px" }}>
                        {steps[current] && steps[current].content ? steps[current].content : <div>Loading...</div>}
                    </div>
                    <div style={{ marginTop: "24px" }}>
                        {current > 0 && (
                            <Button style={{ marginRight: "8px" }} onClick={() => prev()}>
                                Previous Step
                            </Button>
                        )}
                        {current > 0 && current < steps.length - 1 && (
                            <Button type="primary" onClick={() => next()}>
                                Next
                            </Button>
                        )}
                    </div>
                </Content>
            </Layout>
        </div>
    );
};

MonitorSizing.propTypes = {
    isConnectedToBusiness: PropTypes.bool.isRequired,
    userEnabledDarkMode: PropTypes.bool.isRequired,
};

export default MonitorSizing;