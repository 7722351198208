import React from 'react';
import PropTypes from 'prop-types';

const withTheme = (WrappedComponent) => {
  const WithThemeComponent = ({ userEnabledDarkMode, ...props }) => {
    const darkModeLayout = {
      paper_bgcolor: '#141414',
      plot_bgcolor: '#141414',
      font: {
        color: '#ffffff'
      },
      xaxis: {
        tickfont: {
          color: '#ffffff'
        },
        gridcolor: '#303030'
      },
      yaxis: {
        tickfont: {
          color: '#ffffff'
        },
        gridcolor: '#303030'
      },
      legend: {
        font: {
          color: '#ffffff'
        }
      }
    };

    const lightModeLayout = {
      paper_bgcolor: '#ffffff',
      plot_bgcolor: '#ffffff',
      font: {
        color: '#000000'
      },
      xaxis: {
        tickfont: {
          color: '#000000'
        },
        gridcolor: '#e0e0e0'
      },
      yaxis: {
        tickfont: {
          color: '#000000'
        },
        gridcolor: '#e0e0e0'
      },
      legend: {
        font: {
          color: '#000000'
        }
      }
    };

    const themeLayout = userEnabledDarkMode ? darkModeLayout : lightModeLayout;

    return <WrappedComponent {...props} themeLayout={themeLayout} />;
  };

  WithThemeComponent.propTypes = {
    userEnabledDarkMode: PropTypes.bool.isRequired,
  };

  return WithThemeComponent;
};

export default withTheme;