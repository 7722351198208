import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select, message, Modal, Table, Alert } from 'antd';
import { fetchFeedback, pushFeedback, closeTicket } from '../services/apiService';

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

function Feedback({ isConnectedToBusiness }) {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        const fetchTickets = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetchFeedback(token);
                // console.log("Feedback response", response);
                setTickets(response);
            } catch (error) {
                console.error('Failed to fetch tickets:', error);
            }
        };
if(isConnectedToBusiness){
        fetchTickets();
}
    }, [isConnectedToBusiness]);

    const onFinish = async (values) => {
        console.log('Received values:', values);

        try {
            const temp_response = {
                "feedback": {
                    "type": values.category,
                    "message": values.message
                }
            }
            const token = localStorage.getItem('accessToken');
            const response = await pushFeedback(token, temp_response);
            if (response.error) {
                message.error(response.error + " Please wait 5 min"); // Display the error message to the user
            } else {
                message.success('Feedback submitted successfully');
                console.log(response);
                
            }
            form.resetFields();
            setIsModalVisible(false);
            // Refresh tickets
            const updatedTickets = await fetchFeedback(token);
            setTickets(updatedTickets);
            
            
            
        } catch (error) {
            console.error('Failed to submit feedback:', error);
            message.error('Failed to submit feedback');
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCloseTicket = async (ticketId) => {
        try {
            const token = localStorage.getItem('accessToken');
            await closeTicket(token, ticketId);
            message.success('Ticket closed successfully');
            // Refresh tickets
            const updatedTickets = await fetchFeedback(token);
            setTickets(updatedTickets);
        } catch (error) {
            console.error('Failed to close ticket:', error);
            message.error('Failed to close ticket');
        }
    };

    const showConfirm = (ticketId) => {
        confirm({
            title: 'Are you sure you want to close this ticket?',
            content: 'This action cannot be undone.',
            onOk() {
                handleCloseTicket(ticketId);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const columns = [
        {
            title: 'Category',
            dataIndex: ['feedback', 'type'],
            key: 'type',
            render: (text) => {
                if (text === 'general') {
                    return 'General Feedback';
                }
                if (text === 'bug') {
                    return 'Bug Report';
                }
                if (text === 'feature') {
                    return 'Feature Request';
                }
                return text.charAt(0).toUpperCase() + text.slice(1); // Capitalize the first letter for other categories
            },
        },
        {
            title: 'Message',
            dataIndex: ['feedback', 'message'],
            key: 'message',
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Reviewed',
            dataIndex: ['status', 'resolved'],
            key: 'resolved',
            render: (resolved) => (resolved ? 'Yes' : 'No'),
        },
        {
            title: 'Closed',
            dataIndex: ['status', 'closed'],
            key: 'closed',
            render: (_, record) => {
                if (record.status.closed) {
                    return new Date(record.status.closed_at).toLocaleString();
                }
                return 'No';
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button
                    type="primary"
                    disabled={record.status.closed}
                    onClick={() => showConfirm(record._id)}
                >
                    Close Ticket
                </Button>
            ),
        },
    ];

    if (!isConnectedToBusiness) {
        return (
            <div>
                <h1>Tickets</h1>
                <Alert message="You are not connected to a business. Please connect to a business to create and view tickets." type="warning" showIcon />
            </div>
        );
    }

    return (
        <div style={{ margin: '10px' }}>
            <h1>Feedback</h1>
            <p>Create tickets to report bugs or make feature requests for the new system. You can also create tickets for general feedback. Once closed, tickets will expire 1 month after being closed.</p>
            <Button type="primary" onClick={showModal}>
                Create New Ticket
            </Button>
            <Table
                dataSource={tickets}
                columns={columns}
                rowKey="_id"
                style={{ marginTop: '20px' }}
                rowClassName={(record) => (record.status.closed ? 'closed-ticket' : '')}
            />
            <Modal title="Create New Ticket" open={isModalVisible} onCancel={handleCancel} footer={null}>
                <Form form={form} name="feedback" onFinish={onFinish}>
                    <Form.Item
                        name="category"
                        label="Category"
                        rules={[{ required: true, message: 'Please select a category!' }]}
                    >
                        <Select placeholder="Select a category">
                            <Option value="bug">Bug Report</Option>
                            <Option value="feature">Feature Request</Option>
                            <Option value="general">General Feedback</Option>
                        </Select>
                    </Form.Item>
                    
                    <Form.Item
                        name="message"
                        label="Message"
                        rules={[{ required: true, message: 'Please enter your message!' }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}


Feedback.propTypes = {
    isConnectedToBusiness: PropTypes.bool.isRequired,
}


export default Feedback;