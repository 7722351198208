import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Select, Flex, Checkbox, Spin, message, Alert, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong, faRightLong } from '@fortawesome/free-solid-svg-icons';
import { Radio } from 'antd';
import { fetchMonitorData, fetchMonitors, fetchAggregatedMonitorData, fetchCreditBalance } from '../services/apiService';
// import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { calculatePeakData, calculateConsumptionAvgCapacityGraphData, calculateDayNightSplit, lineChartData, dailyKWHGraph, weeklySimulationChartData, formatHourlyRawData, CalculateHourlyKwGraph } from './Calculations';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import LineChartComponent from './charts/LineChartComponent';

import { useErrorHandler } from './Util';
import DailyKWHChart from './charts/DailyKWHChart';
import PeakDataChart from './charts/PeakDataChart';
import DayNightSplitChart from './charts/DayNightSplitChart';
import WeeklyConsumptionChart from './charts/WeeklyConsumptionChart';
import ConsumptionCapacityChart from './charts/ConsumptionCapacityChart';
import HourlyKwChart from './charts/HourlyKwChart';

import { Link } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";

import logger from '../utils/logger';
logger.enableLogging('Monitors');

function Monitors({ isConnectedToBusiness, userEnabledDarkMode }) {
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);
    dayjs.extend(utc);
    dayjs.extend(timezone);

    // const [minutelyData, setMinutelyData] = useState([]);

    const navigate = useNavigate();

    const { monitorId } = useParams();

    const { Option } = Select;

    const plotRef = useRef();

    const [isNoData, setIsNoData] = useState(false);

    const [chartType, setChartType] = useState('minutely');
    const [loading, setLoading] = useState(true);
    const [isLoadingCharts, setIsLoadingCharts] = useState(true);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
    const [business_monitors, setBusinessMonitors] = useState([]);
    const [selectedMonitorId, setSelectedMonitorId] = useState(null);

    const [startDate, setStartDate] = useState(dayjs().tz('Etc/GMT').subtract(2, 'day').startOf('day').add(1, 'minute'));
    const [endDate, setEndDate] = useState(dayjs().tz('Etc/GMT').subtract(1, 'day').endOf('day'));

    // const [creditStartDate, setCreditStartDate] = useState(dayjs().tz('Etc/GMT').subtract(30, 'day').startOf('day').add(1, 'minute'));


    const [currentDay, setCurrentDay] = useState(dayjs().tz('Etc/GMT').subtract(1, 'day'));
    const [IsCurrentDay, setIsCurrentDay] = useState(false);

    // New data handling
    const [monitorData, setMonitorData] = useState([]); // data for that day for the selected monitor

    const [isClamp1, setIsClamp1] = useState(true);
    const [isClamp2, setIsClamp2] = useState(true);
    const [isClamp3, setIsClamp3] = useState(true);

    // const [dailyDataAggregation, setDailyDataAggregation] = useState([]);
    const [peakData, setPeakData] = useState([]);
    const [dailyKWData, setDailyKWData] = useState([]);
    const [dayNightSplit, setDayNightSplit] = useState([]);
    const [weeklyConsumptionData, setWeeklyConsumptionData] = useState([]);
    const [consumptionAvgProjection, setConsumptionAvgProjection] = useState([]);

    const [rangedLineChartData, setRangedLineChartData] = useState([]);

    const [highestPeak, setHighestPeak] = useState(0);
    const [averageDailyUsage, setAverageDailyUsage] = useState(0);

    const [newLineData, setNewLineData] = useState([]);

    // const [isThreePhase, setIsThreePhase] = useState(false);

    const [newRawHourlyReadings, setNewRawHourlyReadings] = useState([]);

    // Cache for monitor data
    const [monitorDataCache, setMonitorDataCache] = useState({});

    const [searchValue, setSearchValue] = useState('');
    const [filteredMonitors, setFilteredMonitors] = useState(business_monitors);

    const [expiryDate, setExpiryDate] = useState('');

    // const [creditCoveragePercentage, setCreditCoveragePercentage] = useState(0);


    // const [exportData, setExportData] = useState('');

    const [isThreePhase, setIsThreePhase] = useState(false);


    useEffect(() => {
        setFilteredMonitors(
            business_monitors.filter((monitor) =>
                monitor.displayName.toLowerCase().includes(searchValue.toLowerCase())
            )
        );
    }, [searchValue, business_monitors]);

    const handlePhase = (e) => {
        setIsThreePhase(e.target.checked);
    };


    const handleError = useErrorHandler();

    const fetchAndSetMonitorData = useCallback(async (date, monitorId) => {
        const formatted_date = date.format('YYYY-MM-DD');
        const cacheKey = `${monitorId}-${formatted_date}`;

        if (monitorDataCache[cacheKey]) {
            setMonitorData(monitorDataCache[cacheKey]);
        } else {
            try {
                const token = localStorage.getItem('accessToken');
                const temp_monitorData = await fetchMonitorData(token, formatted_date, monitorId);
                setMonitorData(temp_monitorData);
                setMonitorDataCache((prevCache) => ({
                    ...prevCache,
                    [cacheKey]: temp_monitorData,
                }));
            } catch (error) {
                handleError(error);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monitorDataCache]);



    const onChange1 = (e) => {
        // logger.log('radio checked', e.target.value);
        setChartType(e.target.value);
    };

    // as the monitor is selected we get the id of the monitor
    const handleChange = useCallback(async (value) => {
        setSelectedMonitorId(value);
        // logger.log('Selected Monitor ID:', value);
        await fetchAndSetMonitorData(currentDay, value);
    }, [currentDay, fetchAndSetMonitorData]);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    const handleClamp1Change = (e) => {
        setIsClamp1(e.target.checked);
    };

    const handleClamp2Change = (e) => {
        setIsClamp2(e.target.checked);
    };

    const handleClamp3Change = (e) => {
        setIsClamp3(e.target.checked);
    };

    const handleStartDateChange = (date) => {
        if (date && date.isValid()) {
            const newStartDate = dayjs(date).startOf('day').add(1, 'minute').tz('Etc/GMT');
            // console.log("newStartDate", newStartDate);
            if (newStartDate.isAfter(endDate)) {
                message.error('Start date must be before the end date.');
            } else {
                setStartDate(newStartDate);
            }
        }
    };

    const handleEndDateChange = (date) => {
        if (date && date.isValid()) {
            const newEndDate = dayjs(date).endOf('day').tz('Etc/GMT');
            // console.log("newEndDate", newEndDate);
            if (newEndDate.isBefore(startDate)) {
                message.error('End date must be after the start date.');
            } else {
                setEndDate(newEndDate);
            }
        }
    };

    const handleDateChange = async (date) => {
        if (selectedMonitorId) {
            setCurrentDay(date);
            await fetchAndSetMonitorData(date, selectedMonitorId);
        }
    };


    function goBackDay() {
        if (selectedMonitorId) {
            const newDay = dayjs(currentDay).subtract(1, 'day');
            setCurrentDay(newDay);
            fetchAndSetMonitorData(newDay, selectedMonitorId);
        }
    }

    function goForwardDay() {
        if (selectedMonitorId) {
            const newDay = dayjs(currentDay).add(1, 'day');
            setCurrentDay(newDay);
            fetchAndSetMonitorData(newDay, selectedMonitorId);
        }
    }

    // useEffect(() => {
    //     setIsCurrentDay(dayjs().isSame(currentDay, 'day'));
    // }, [currentDay]);

    // useEffect(() => {
    //     const fetchMonitorsInfo = async () => {
    //         try {
    //             console.log("fetching monitors info");
    //             const token = localStorage.getItem('accessToken');
    //             const monitors_response = await fetchMonitors(token);
    //             logger.log("here are monitors: ", monitors_response);
    //             setBusinessMonitors(monitors_response);
    //             //setSelectedMonitorId(monitors_response[0].id);
    //             handleChange(monitors_response[0].id);
    //             setLoading(false);
    //         } catch (error) {
    //             handleError(error);
    //         }
    //     };

    //     if (isConnectedToBusiness) {
    //     fetchMonitorsInfo();
    //     }
    // }, [ isConnectedToBusiness ]);

    const fetchMonitorsInfo = useCallback(async () => {
        try {
            if (monitorId) {
                setSelectedMonitorId(monitorId);
            }
            // console.log("fetching monitors info");
            const token = localStorage.getItem('accessToken');
            const monitors_response = await fetchMonitors(token, true);
            // logger.log("here are monitors: ", monitors_response);
            setBusinessMonitors(monitors_response);
            setLoading(false);
            console.log("PASSED ID", monitorId);

            if (!monitorId) {
                setSelectedMonitorId(monitors_response[0].id);
            }

        } catch (error) {
            handleError(error);
        }
    }, [handleError]);

    // useEffect(() => {
    //     if (isConnectedToBusiness) {
    //         fetchMonitorsInfo();
    //     }
    // }, [isConnectedToBusiness]);

    // useEffect(() => {
    //     if (selectedMonitorId) {
    //         fetchAndSetMonitorData(currentDay, selectedMonitorId);
    //     }
    // }, [selectedMonitorId, currentDay]);

    // useEffect(() => {
    // Runs once on page load
    // const fetchCreditDetails = async () => {
    //     const token = localStorage.getItem('accessToken');
    // const creditStartPoint = await fetchCreditStartPoint(token);
    // console.log("Credit Start Point", creditStartPoint);
    // setCreditStartDate(dayjs(creditStartPoint).tz('Etc/UTC'));
    //     };
    //     fetchCreditDetails();
    // }, []);




    const fetchAggregatedData = useCallback(async () => {
        try {
            setIsLoadingCharts(true);



            const token = localStorage.getItem('accessToken');
            const strt = startDate.startOf('day').tz('Etc/GMT').format('YYYY-MM-DD');
            const endd = endDate.endOf('day').tz('Etc/GMT').format('YYYY-MM-DD');
            // console.log("fetching aggregated data");
            const business_credits = await fetchCreditBalance(token);
            setIsLoadingCharts(true);
            const aggregatedData = await fetchAggregatedMonitorData(token, strt, endd, selectedMonitorId, business_credits);

            setExpiryDate(aggregatedData.expiry);

            // setMinutelyData(aggregatedData.minute_data);
            // below is used to inform user of the credit coverage percentage
            // setExpiryDate(dayjs().tz('Etc/GMT').subtract(2, 'day').endOf('day'))

            // // Calculate the total duration of the selected range in days
            // const totalDuration = endDate.diff(startDate, 'day');

            // // Calculate the duration from the start date to the expiry date in days
            // const coveredDuration = dayjs().tz('Etc/GMT').subtract(2, 'day').endOf('day').diff(startDate, 'day');

            // // Calculate the percentage of the range covered by the expiry date
            // const coveragePercent = Math.min((coveredDuration / totalDuration) * 100, 100); // Ensure it doesn't exceed 100%


            // setCreditCoveragePercentage(coveragePercent);


            // console.log("DATA", aggregatedData);

            if (aggregatedData.data.length == 0) {
                setIsLoadingCharts(false);
                setIsNoData(true);
                return;
            }


            if (aggregatedData.data && typeof aggregatedData.data === 'object') {
                const clamp1vData = aggregatedData.data.clamp1v.data;
                const clamp1iData = aggregatedData.data.clamp1i.data;
                const clamp2vData = aggregatedData.data.clamp2v.data;
                const clamp2iData = aggregatedData.data.clamp2i.data;
                const clamp3vData = aggregatedData.data.clamp3v.data;
                const clamp3iData = aggregatedData.data.clamp3i.data;

                const newRawHourlyReadings = clamp1vData.map((_, index) => ({
                    clamp1v: clamp1vData[index],
                    clamp1i: clamp1iData[index],
                    clamp2v: clamp2vData[index],
                    clamp2i: clamp2iData[index],
                    clamp3v: clamp3vData[index],
                    clamp3i: clamp3iData[index],
                }));

                // logger.log("New Raw Hourly Readings", newRawHourlyReadings);

                setNewRawHourlyReadings(newRawHourlyReadings);

                // setLoading(false);

                // setExportData(newRawHourlyReadings);
            } else {
                console.error("aggregatedData.data is not an object", aggregatedData.data);
            }
        } catch (error) {
            handleError(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, selectedMonitorId]);

    useEffect(() => {

        if (isConnectedToBusiness) {
            // console.log("isConnectedToBusiness", isConnectedToBusiness);
            fetchMonitorsInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnectedToBusiness]);

    useEffect(() => {
        if (selectedMonitorId) {
            fetchAndSetMonitorData(currentDay, selectedMonitorId);
        }
    }, [selectedMonitorId, currentDay, fetchAndSetMonitorData]);

    useEffect(() => {
        if (selectedMonitorId) {
            fetchAggregatedData();
        }
    }, [selectedMonitorId, startDate, endDate, fetchAggregatedData]);

    useEffect(() => {
        setIsCurrentDay(dayjs().isSame(currentDay, 'day'));
    }, [currentDay]);

    useEffect(() => {
        const formatted_hourly_data = formatHourlyRawData(newRawHourlyReadings);

        const max_formatted_hourly_data = formatHourlyRawData(newRawHourlyReadings, true);

        // const isThreePhase = false;

        const newDailyKWData = dailyKWHGraph(newRawHourlyReadings, startDate, endDate, isClamp1, isClamp2, isClamp3, isThreePhase);
        const newPeakData = calculatePeakData(max_formatted_hourly_data, isClamp1, isClamp2, isClamp3, startDate, endDate, isThreePhase);
        const newDayNightSplit = calculateDayNightSplit(formatted_hourly_data, isClamp1, isClamp2, isClamp3, startDate, endDate, isThreePhase);
        const newHourlyKwData = CalculateHourlyKwGraph(newRawHourlyReadings, startDate, endDate, isClamp1, isClamp2, isClamp3, isThreePhase);
        setRangedLineChartData(newHourlyKwData);

        const weekly_response = weeklySimulationChartData(newRawHourlyReadings);
        const newWeeklyConsumptionData = weekly_response.newWeeklyConsumptionData;

        setHighestPeak(roundTo(weekly_response.highestPeak, 2));
        setAverageDailyUsage(roundTo(weekly_response.singleValueDailyAvg, 2));

        const newconsumptionAvgProjection = calculateConsumptionAvgCapacityGraphData(newRawHourlyReadings);

        setConsumptionAvgProjection(newconsumptionAvgProjection);

        setDailyKWData(newDailyKWData);
        setPeakData(newPeakData);
        setDayNightSplit(newDayNightSplit);
        setWeeklyConsumptionData(newWeeklyConsumptionData);
        setIsLoadingCharts(false);
    }, [isClamp1, isClamp2, isClamp3, newRawHourlyReadings, startDate, endDate, isThreePhase]);


    useEffect(() => {
        const newLineData = lineChartData(monitorData, currentDay, chartType);
        setNewLineData(newLineData);
    }, [monitorData, currentDay, chartType]);


    // useEffect(() => {
    //     const newLineData = lineChartData(monitorData, currentDay, chartType);
    //     setNewLineData(newLineData);
    // }, [monitorData, currentDay, chartType]);

    const exportToCSV = () => {

        // const csvData = convertToCSVMinute(minutelyData);
        // switch back to hourly data as per requested
        const csvData = convertToCSV(newRawHourlyReadings);
        // setExportData(csvData);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', "export_" + startDate + "_" + selectedMonitorId + ".csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    // const convertToCSVMinute = (data) => {
    //     // this is the new minutely one, which needs to be worked on
    //     const headers = ['time'];
    //     const rows = [];

    //     // Iterate over the data
    //     data.forEach((item) => {
    //         // Start with a row containing the time
    //         const row = { time: item.time };

    //         // Iterate over the keys in the item
    //         Object.keys(item).forEach((key) => {
    //             if (key === 'time') return; // Skip the 'time' key

    //             // Add headers dynamically for 'value' and 'max'
    //             const valueHeader = `${key}`;
     

    //             if (!headers.includes(valueHeader)) {
    //                 headers.push(valueHeader);
    //             }
           

    //             // Add values to the row, defaulting to 0 for missing 'value' or 'max'
    //             row[valueHeader] = item[key] || 0; // Assuming `item[key]` is the value
             
    //         });

    //         // Add the constructed row to the rows array
    //         rows.push(row);
    //     });

    //     // Convert to CSV string
    //     let csvStr = headers.join(',') + '\n';
    //     rows.forEach((row) => {
    //         const rowStr = headers.map((header) => row[header] !== undefined ? row[header] : 0).join(',');
    //         csvStr += rowStr + '\n';
    //     });

    //     return csvStr;
    // };

    const convertToCSV = (data) => {
        // THIS IS THE ORIGINAL HOURLY ONE
        const headers = ['time'];
        const rows = [];

        // Iterate over the data
        data.forEach((item) => {
            // Start with a row containing the time
            const row = { time: item.clamp1v.time };


            // Iterate over the keys in the item
            Object.keys(item).forEach((key) => {
                if (!headers.includes(`${key} value`)) {
                    headers.push(`${key} value`);
                    headers.push(`${key} max`);
                }
                row[`${key} value`] = item[key].value || 0;
                row[`${key} max`] = item[key].max || 0;
            });

            // Add the constructed row to the rows array
            rows.push(row);
        });

        // Convert to CSV string
        let csvStr = headers.join(',') + '\n';
        rows.forEach((row) => {
            const rowStr = headers.map((header) => row[header] !== undefined ? row[header] : 0).join(',');
            csvStr += rowStr + '\n';
        });

        return csvStr;
    };

    useEffect(() => {
        if (isGeneratingPDF) {
            generatePDF();
        }
    }, [isGeneratingPDF]);

    const handlePDF = () => {
        // console.log("Generating PDF");
        setIsGeneratingPDF(true);
        // generatePDF();
    };

    const generatePDF = () => {


        // const input = plotRef.current;
        const graphIds = ['graph0', 'graph1', 'graph2', 'graph3', 'graph4', 'graph5', 'graph6']; // Replace with your actual graph IDs
        const graphTexts = [`Clamp KW for ${dayjs(currentDay).format('DD MMM')}`, `Hourly Kw for ${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`, `Daily kWh for ${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`, `Highest Peak Recorded Per Day kW for ${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`, `Day/Night Consumption Breakdown kWH for ${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`, `Weekly Average consumption in kW for ${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`, `Average Consumption Capacity Projections (% of time) for ${dayjs(startDate).format('DD MMM YYYY')} - ${dayjs(endDate).format('DD MMM YYYY')}`]; // Corresponding texts for each graph
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 15;

        // <p>Monitor: <strong>{selectedMonitorId}</strong></p>
        // <p>Monitoring Period: <strong>{dayjs(startDate).startOf('day').format('DD MMM HH:mm')} to {dayjs(endDate).endOf('day').format('DD MMM HH:mm')}</strong></p>
        // <p>Credit End Date: <strong>{dayjs(expiryDate).format('DD MMM HH:mm')}</strong></p>
        // <p>Average Usage Per Day: <strong>{averageDailyUsage} kWh</strong></p>
        // <p>Highest Peak kW: <strong>{highestPeak} kW</strong></p>

        // Add title
        pdf.setFontSize(25);
        pdf.text('Monitoring Report', 10, position);
        position += 15;

        // Add user details
        pdf.setFontSize(12);

        let labelWidth = 0;

        // Monitor
        pdf.setFont('helvetica', 'normal');
        const monitorLabel = 'Monitor:';
        pdf.text(monitorLabel, 10, position);
        labelWidth = pdf.getTextWidth(monitorLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${selectedMonitorId}`, 10 + labelWidth, position); // Adding 2 for a small gap
        position += 6;

        // Monitoring Period
        pdf.setFont('helvetica', 'normal');
        const monitoringPeriodLabel = 'Monitoring Period:';
        pdf.text(monitoringPeriodLabel, 10, position);
        labelWidth = pdf.getTextWidth(monitoringPeriodLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${dayjs(startDate).startOf('day').format('DD MMM HH:mm')} - ${dayjs(endDate).endOf('day').format('DD MMM HH:mm')}`, 10 + labelWidth, position);
        position += 6;

        // Average Usage Per Day
        pdf.setFont('helvetica', 'normal');
        const averageUsageLabel = 'Average Usage Per Day:';
        pdf.text(averageUsageLabel, 10, position);
        labelWidth = pdf.getTextWidth(averageUsageLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${roundTo(averageDailyUsage,2)} kWh`, 10 + labelWidth, position);
        position += 6;

        // Highest Peak kW
        pdf.setFont('helvetica', 'normal');
        const highestPeakLabel = 'Highest Peak kW:';
        pdf.text(highestPeakLabel, 10, position);
        labelWidth = pdf.getTextWidth(highestPeakLabel);

        pdf.setFont('helvetica', 'bold');
        pdf.text(` ${roundTo(highestPeak,2)} kW`, 10 + labelWidth, position);
        position += 10;

        // Add title text
        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(18);
        pdf.text('Graphs', 10, position);
        pdf.setFontSize(12);
        position += 10;

        pdf.setFont('helvetica', 'normal');

        graphIds.forEach((id, index) => {
            const graph = document.getElementById(id);
            if (graph) {
                html2canvas(graph, { scale: 2 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = 210; // A4 width in mm
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    if (position + imgHeight + 10 > 297) {
                        pdf.addPage();
                        position = 10;
                    }

                    // Add text before each graph
                    pdf.setFontSize(12);
                    pdf.text(graphTexts[index], 10, position);
                    position += 10;

                    pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                    position += imgHeight + 10;

                    if (index === graphIds.length - 1) {
                        const date = new Date();
                        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                        const formattedDate = date.toLocaleDateString('en-US', options).replace(/[/]/g, '-');
                        pdf.save(`monitor_report_${selectedMonitorId}_${formattedDate}.pdf`);
                        setIsGeneratingPDF(false);
                    }
                });
            } else {
                console.error(`Element with ID ${id} not found.`);
            }
        });


    };

    // is used to cancel request and go to home page
    const handleCancel = () => {

        navigate('/'); // Navigate to the home page
    };

    function roundTo(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    if (!isConnectedToBusiness) {
        return (
            <div>
                <h1>Monitors Usage</h1>
                <Alert message="You are not connected to a business. Please connect to a business to view monitors." type="warning" showIcon />
            </div>
        );
    }

    return (
        <div>
            <h1>Monitors Usage</h1>
            <p>Select a monitor to see usage.</p>

            {isNoData && (
                <Alert message="No data found for the selected monitor and date range. Select another monitor, or make sure your account balance is not too low" type="error" showIcon />

            )}
            <br />

            {loading && (
                <Modal
                    open={loading}
                    footer={null}
                    closable={false}
                    centered
                    style={{ textAlign: 'center' }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h3>Loading...</h3>


                        <Spin spinning={loading} indicator={antIcon} />


                    </div>
                </Modal>

            )}

            {isGeneratingPDF && (
                <Modal
                    open={isGeneratingPDF}
                    footer={null}
                    closable={false}
                    centered
                    style={{ textAlign: 'center' }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h3>Generating PDF...</h3>


                        <Spin spinning={isGeneratingPDF} indicator={antIcon} />


                    </div>
                </Modal>
            )}

            {isLoadingCharts && (
                <Modal
                    open={isLoadingCharts}
                    footer={null}
                    closable={false}
                    centered
                    style={{ textAlign: 'center' }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h3>Fetching Data...</h3>


                        <Spin spinning={isLoadingCharts} indicator={antIcon} />

                        <br />
                        <br />
                        <Button type="primary" onClick={handleCancel}>Cancel</Button>


                    </div>
                </Modal>

            )}


            {/* <pre>{JSON.stringify(endDate.format(), null, 2)}</pre> */}

            <div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', backgroundColor: userEnabledDarkMode ? '#141414' : 'white' }}>


                {!loading ? (
                    business_monitors && business_monitors.length > 0 ? (
                        <Select
                            placeholder="Select an item"
                            onChange={handleChange}
                            value={selectedMonitorId}
                            showSearch
                            style={{ flex: 1 }}
                            filterOption={false} // Disable default filtering
                            onSearch={(value) => setSearchValue(value)} // Update search value
                        >
                            {filteredMonitors.map((monitor) => (
                                <Option key={monitor.id} value={monitor.id}>
                                    {monitor.displayName}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <p>No monitors available. Please make sure to register them to your account in the business management in the <b><Link to="/profile">Settings Page</Link></b></p>
                    )
                ) : (
                    <Spin indicator={antIcon} />
                )}

                <br />
                <p>Monitor: <strong>{selectedMonitorId}</strong></p>
                <p>Monitoring Period: <strong>{dayjs(startDate).startOf('day').format('DD MMM HH:mm')} to {dayjs(endDate).endOf('day').format('DD MMM HH:mm')}</strong></p>
                <p>Valid Credit Expiry:  <strong>{dayjs(expiryDate).format('DD MMM YY HH:mm')}</strong></p>
                <p>Average Usage Per Day: <strong>{roundTo(averageDailyUsage,2)} kWh</strong></p>
                <p>Highest Peak kW: <strong>{roundTo(highestPeak,2)} kW</strong></p>
                {/* <Progress percent={creditCoveragePercentage}  /> */}
            </div>
            <br />
            <div style={{ border: '1px solid black', borderRadius: '8px', padding: '10px', backgroundColor: userEnabledDarkMode ? '#141414' : 'white' }}>
                {!loading && monitorData ? (
                    <div>
                        <Flex style={{ justifyContent: 'space-between' }}>
                            <Flex gap="small">
                                <Button onClick={goBackDay}><FontAwesomeIcon icon={faLeftLong} /></Button>
                                <DatePicker value={currentDay} onChange={handleDateChange} />
                                <Button disabled={IsCurrentDay} onClick={goForwardDay}><FontAwesomeIcon icon={faRightLong} /></Button>
                            </Flex>
                            <Flex gap="small">
                                <Radio.Group onChange={onChange1} value={chartType} buttonStyle="solid">
                                    <Radio.Button value={'minutely'}>MINUTE GRAPH</Radio.Button>
                                    <Radio.Button value={'hourly'}>HOURLY GRAPH</Radio.Button>
                                </Radio.Group>
                            </Flex>
                        </Flex>
                        <p className='graph-label'>
                            {chartType === 'minutely' ? 'Clamp KW' : 'Hourly Clamps KWH'} for {dayjs(currentDay).format('DD MMM')}
                        </p>
                        <div id='graph0'>
                            <LineChartComponent data={newLineData} format={chartType} userEnabledDarkMode={userEnabledDarkMode} />
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
            <br />
            <div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', backgroundColor: userEnabledDarkMode ? '#141414' : 'white' }}>
                <Flex gap="small">
                    <DatePicker value={startDate} onChange={handleStartDateChange} />
                    <DatePicker value={endDate} onChange={handleEndDateChange} disabledDate={(current) => current && current.isAfter(expiryDate, 'day')} />
                </Flex>
                <br />
                <Checkbox checked={isClamp1} onChange={handleClamp1Change}>CT1</Checkbox>
                <Checkbox checked={isClamp2} onChange={handleClamp2Change}>CT2</Checkbox>
                <Checkbox checked={isClamp3} onChange={handleClamp3Change}>CT3</Checkbox>
                {/* Add below a check box for if three phase */}
                <br />
                <br />
                <Checkbox checked={isThreePhase} onChange={handlePhase}>Three Phase</Checkbox>
                <div ref={plotRef} style={{ padding: '10px' }}>
                    <p className='graph-label'>Hourly Kw for {dayjs(startDate).startOf('day').format('DD MMM')} to {dayjs(endDate).endOf('day').format('DD MMM')}</p>
                    <p className='subtitle_text'>*to a max of 14 days after first date</p>
                    <div>
                        {!loading && rangedLineChartData ? (
                            <div id='graph1'>

                                <HourlyKwChart data={rangedLineChartData} userEnabledDarkMode={userEnabledDarkMode} />
                            </div>
                        ) : (
                            <div>loading</div>
                        )}
                    </div>
                    <p className='graph-label'>Daily kWh for {dayjs(startDate).startOf('day').format('DD MMM')} to {dayjs(endDate).endOf('day').format('DD MMM')}</p>

                    <div>
                        {!loading && dailyKWData ? (
                            <div id='graph2'>

                                <DailyKWHChart data={dailyKWData} userEnabledDarkMode={userEnabledDarkMode} />
                            </div>
                        ) : (
                            <div>loading</div>
                        )}
                    </div>
                    <p className='graph-label'>Highest Peak Recorded Per Day kW for {dayjs(startDate).format('HH:mm DD MMM')} to {dayjs(endDate).endOf('day').format('HH:mm DD MMM')}</p>

                    <p>This is the maximum of the clamps selected, added together per day</p>
                    <div>
                        {!loading && peakData ? (
                            <div id='graph3'>
                                <PeakDataChart data={peakData} userEnabledDarkMode={userEnabledDarkMode} />
                            </div>
                        ) : (
                            <div>loading</div>
                        )}
                    </div>
                    <p className='graph-label'>Day/Night Consumption Breakdown kWH for 00:00 {dayjs(startDate).startOf('day').format('DD MMM')} to 23:59 {dayjs(endDate).endOf('day').format('DD MMM')}</p>

                    <div>
                        {!loading && dayNightSplit ? (
                            <div id='graph4'>
                                <DayNightSplitChart data={dayNightSplit} userEnabledDarkMode={userEnabledDarkMode} />
                            </div>
                        ) : (
                            <div>loading</div>
                        )}
                    </div>
                    <p className='graph-label'>Weekly Average consumption in kW for 00:00 {dayjs(startDate).startOf('day').format('DD MMM')} to 23:59 {dayjs(endDate).endOf('day').format('DD MMM')}</p>
                    <p>{ }</p>
                    <div>
                       
                          {  !loading && weeklyConsumptionData ? (
                                <div id='graph5'>
                                    <WeeklyConsumptionChart data={weeklyConsumptionData} userEnabledDarkMode={userEnabledDarkMode} />
                                </div>
                            ) : (
                                <div>Loading...</div>
                            )}
                        

                    </div>
                    <p className='graph-label'>Average Consumption Capacity Projections (% of time) for 00:00 {dayjs(startDate).startOf('day').format('DD MMM')} to 23:59 {dayjs(endDate).endOf('day').format('DD MMM')}</p>
                    <div>
                        {!loading && consumptionAvgProjection ? (
                            <div id='graph6'>
                                <ConsumptionCapacityChart data={consumptionAvgProjection} userEnabledDarkMode={userEnabledDarkMode} />
                            </div>
                        ) : (
                            <div>loading</div>
                        )}
                    </div>
                </div>



                <br />

                {/* <pre>{JSON.stringify(isGeneratingPDF)}</pre> */}
                <Flex gap="small">
                    <Button type="primary" onClick={handlePDF}>Generate Report PDF</Button>
                    <Button type="primary" onClick={exportToCSV}>Export Minutely Data to CSV</Button>
                </Flex>
            </div>

            {/* <pre>{exportData}</pre> */}

        </div>
    );
}

Monitors.propTypes = {
    isConnectedToBusiness: PropTypes.bool.isRequired,
    userEnabledDarkMode: PropTypes.bool.isRequired,
};

export default Monitors;