import { useState } from 'react';

import { Form, Input, Button, message } from 'antd';
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
// import { api_endpoint } from './apiConfig';
import sha256 from 'crypto-js/sha256';
import { registerUser } from '../services/apiService';

const Register = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const generateHashId = (email) => {
        return sha256(email).toString();
    };

    const onFinish = async (values) => {
        setLoading(true);
        const { email, fullname, phoneNumber, password } = values;
        const username = generateHashId(email);

        const payload = {
            email,
            fullname,
            phoneNumber,
            password,
            username // Include username if required by backend
        };

        try {
            const response = await registerUser(payload);
            if (response.status === 'success') {
                message.success('Registration successful! Please verify your account by following the link in your email.');
                setTimeout(() => {
                    navigate('/login');
                }, 2000); // Wait for 2 seconds before navigating
            } else {
                message.error('Registration failed: ' + (response.errors ? response.errors.join(', ') : 'Unknown error'));
            }
        } catch (error) {
            message.error('Registration failed: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <h1>Scout Energy</h1>
            <Form onFinish={onFinish} style={{ maxWidth: '300px' }}>
                <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item name="fullname" rules={[{ required: true, message: 'Please input your full name!' }]}>
                    <Input placeholder="Full Name" />
                </Form.Item>
                <Form.Item name="phoneNumber" rules={[{ required: true, message: 'Please input your phone number!' }]}>
                    <Input placeholder="Phone Number" />
                </Form.Item>
                <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        Register
                    </Button>
                </Form.Item>
            </Form>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to="/login">
                    <Button type="link">Login</Button>
                </Link>
            </div>
        </div>
    );
};


export default Register;