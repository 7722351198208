import { Button, Card, Flex, Breadcrumb, notification } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {  fetchProducts, pushToCart } from '../services/apiService';



function Products() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category'); // Get the category from the query parameters
  const category_name = queryParams.get('title'); // Get the category from the query parameters
  const [products, setProducts] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessToken');
      console.log("category is ", category);
      const data = await fetchProducts(token, category);
      setProducts(data);
    };
    fetchData();
  }, [category]);


  function handleAddToCart(productData) {
    let data = {
      "quantity": productData['CPT Stock'],
      "price": productData['Retail Price'],
      "code": productData.Code,
      "description": productData.Description,
      "category": category,
      "image": productData.Image[0].url,
      "productID": productData.id,
      "stock": productData['CPT Stock']
    }
    console.log("data", data);
    pushToCart(data);
    notification.success({
      message: 'Success',
      description: productData.Code + ' added to cart',
      placement: 'bottomRight'
    });

  }


  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>Shopping</Breadcrumb.Item>
        <Breadcrumb.Item>{category_name}</Breadcrumb.Item>
      </Breadcrumb>
      <h1>{category_name}</h1>

      <p>Range of Solar Modules (Panels)</p>
      <div>
        <Flex gap="large" wrap>
          {products.map(product => (
            <Card key={product.id} style={{ width: 300, display: 'flex', flexDirection: 'column', height: 'auto' }}>
            <img src={product.Image[0].url} alt={product.Image[0].title} style={{ width: '200px' }} />
            <h2>{product.Title}</h2>
            <p>{product.Description}</p>
            <p>R {product["Namibia Gold Price"]}</p>
            <p>Stock: {product["CPT Stock"]}</p>
            <div style={{ marginTop: 'auto', display: 'flex', gap: '10px' }}>
              <Button onClick={() => navigate(`/product?product=${product.id}&category=${category_name}`)}>SEE DETAILS</Button>
              <Button onClick={() => handleAddToCart(product)} type="primary" disabled={product["CPT Stock"] === 0}>ADD TO CART</Button>
            </div>
          </Card>
          ))}
        </Flex>
      </div>
    </div>
  );
}

export default Products;