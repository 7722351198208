import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

const LineChartComponent = ({ data, format }) => {
    const [width, setWidth] = useState(0);
    const chartRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (chartRef.current) {
                setWidth(chartRef.current.offsetWidth);
            }
        };

        // Set initial width
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const layout = {
        barmode: format === 'hourly' ? 'stack' : undefined,
        title: format === 'hourly' ? 'kWh Readings for Clamps' : 'kW Readings for Clamps',
        xaxis: {
            title: 'Time',
            tickformat: '%H:%M', // Format ticks to show hours and minutes
            // dtick: 3600000, // Set tick interval to 1 hour (in milliseconds)
            //range: ['00:00', '24:00'], // Set the range from 00:00 to 24:00 hours
        },
        yaxis: { title: format === 'hourly' ? 'kWh' : 'kW', },
        legend: {
            orientation: 'h',
            x: 0,
            y: 1.1,
        },
        width: width,
        height: 400, // Set a fixed height

    };

    const config = {
        responsive: true,
    };

    return (
        <div ref={chartRef} style={{ width: '100%' }}>
            <Plot data={data} layout={layout} config={config} />
        </div>
    );
};

LineChartComponent.propTypes = {
    data: PropTypes.array.isRequired,
    format: PropTypes.string.isRequired,
};

export default LineChartComponent;