import React, { useEffect, useState } from 'react';
import { Button, Flex, Radio, Checkbox, notification, Tooltip, List, Typography, Row, Col, Select, Modal, Table, InputNumber, Alert, Badge } from 'antd';
import Input from 'antd/es/input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1, fa3, faBatteryFull, faSun, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Slider } from 'antd';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { pushQuickSizingProject, fetchQuickSizingProject, fetchQuickSizingReport, fetchComponents } from '../services/apiService';
import { useParams } from "react-router-dom";
import { CalculateComponentSetup } from "./sizingComponentCalculations"; // Correct import
import logger from "../utils/logger";
import CostPlotChart from "./charts/CostPlotChart";
logger.enableLogging('QuickSizing');

function QuickSizing() {
  const { sizing_id } = useParams(); // Get the UUID from the URL parameters
  const { Text } = Typography;
  const [clientName, setClientName] = useState('');
  const { Option } = Select;
  const [phase, setPhase] = useState('single');
  const [systemType, setSystemType] = useState('backup');
  const [batteryCapacity, setbatteryCapacity] = useState(5);
  const [inverterCapacity, setinverterCapacity] = useState(5);
  const [solarCapacity, setsolarCapacity] = useState(5);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [componentsData, setComponentsData] = useState({
    inverters: {},
    batteries: {},
    panels: {},
  });
  const [selectedInverterMake, setSelectedInverterMake] = useState(null);
  const [selectedBatteryMake, setSelectedBatteryMake] = useState(null);
  const [selectedSolarMake, setSelectedSolarMake] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  //   const [modalContent, setModalContent] = useState('');
  const [tableData, setTableData] = useState([]);

  const [voltageType, setVoltageType] = useState("HV");

  const [batteryCompMeetsRequired, setBatteryCompMeetsRequired] = React.useState(true);
  const [inverterCompMeetsRequired, setInverterCompMeetsRequired] = React.useState(true);
  const [panelCompMeetsRequired, setPanelCompMeetsRequired] = React.useState(true);

  const isThreePhase = phase === "three";

  const [configSetupData, setConfigSetupData] = React.useState([]);

  const [reportData, setReportData] = React.useState(null);
  const [inverterCount, setInverterCount] = useState(0);
  const [batteryCount, setBatteryCount] = useState(0);
  const [pvCount, setPvCount] = useState(0);

  const [inverterCost, setInverterCost] = React.useState(0);
  const [batteryCost, setBatteryCost] = React.useState(0);
  const [panelCost, setPanelCost] = React.useState(0);

  const [reportCostData, setReportCostData] = React.useState([]);

  // Effect to enable all clamps if threePhase is selected
  useEffect(() => {
    if (!isThreePhase) {
      setVoltageType("LV");
    }
  }, [isThreePhase]);

  const groupComponentsByMake = (components) => {
    return components.reduce((acc, component) => {
      const { make } = component;
      if (!acc[make]) {
        acc[make] = [];
      }
      acc[make].push(component);
      return acc;
    }, {});
  };

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    async function fetchData() {
      const response = await fetchQuickSizingProject(token, sizing_id);
      const data = response.data;
      setClientName(data.clientName);
      setPhase(data.phase);
      setSystemType(data.systemType);
      if (data.systemType === 'hybrid') {
        setsolarCapacity(data.solarCapacity);
      }
      setSelectedInverterMake(data.selectedInverterMake);
      setSelectedBatteryMake(data.selectedBatteryMake);
      setbatteryCapacity(data.batteryCapacity);
      setinverterCapacity(data.inverterCapacity);
      setAcceptTerms(data.acceptTerms);
    }

    async function fetchComponentsData() {
      const components_data = await fetchComponents(token);
      const groupedComponentsData = {
        inverters: groupComponentsByMake(components_data.inverters),
        batteries: groupComponentsByMake(components_data.batteries),
        panels: groupComponentsByMake(components_data.panels),
      };
      setComponentsData(groupedComponentsData);
    }

    fetchComponentsData();

    if (sizing_id) {
      fetchData();
      // logger.log("QuickSizing", "setting data");
    }
  }, [sizing_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const design_voltage = voltageType === "HV" ? "HV" : "LV";
    const data = {
      clientName,
      phase,
      systemType,
      selectedInverterMake,
      selectedBatteryMake,
      selectedSolarMake,
      batteryCapacity,
      inverterCapacity,
      acceptTerms,
      solarCapacity,
      design_voltage
    };

    if (sizing_id) {
      data["_id"] = sizing_id;
    }

    // logger.log("QuickSizing", "Pushing...", data);

    const token = localStorage.getItem('accessToken');
    await pushQuickSizingProject(token, data);

    notification.success({
      message: 'Form Submitted',
      description: 'Quick Monitor Sizing form submitted successfully',
      placement: 'bottomRight'
    });

    const quickReport = await fetchQuickSizingReport(token, data);
    // logger.log("QuickSizing", quickReport);

    // Process the quickReport to extract table data
    const cleaned_Data = quickReport;
    const inverter_comp_results = cleaned_Data.system_results.inverter_result;
    inverter_comp_results["component_uuid"] = cleaned_Data.components.inverter.component_uuid;
    const battery_comp_results = cleaned_Data.system_results.battery_result;
    battery_comp_results["component_uuid"] = cleaned_Data.components.battery.component_uuid;
    let solar_panel_results = {};

    //  set costs
    setInverterCost(inverter_comp_results.final_cost_with_factor);
    setBatteryCost(battery_comp_results.total_cost);
  
    
    if (cleaned_Data.system_results.solar_panel_result) {
      solar_panel_results = cleaned_Data.system_results.solar_panel_result;
      solar_panel_results["component_uuid"] = cleaned_Data.components.solar.component_uuid;
      setPvCount(solar_panel_results.no_units);
      setPanelCost(solar_panel_results.summed_cost);
  }

    setBatteryCount(battery_comp_results.no_units);
    setInverterCount(inverter_comp_results.no_units);






    const tableDatra = [
      {
        key: "1",
        category: "Inverter Technical",
        title: "Deye SUN-8K",
        component_uuid: inverter_comp_results.component_uuid,
        output: (inverter_comp_results.inverter_rated_output_kw * inverter_comp_results.no_units).toFixed(0),
        requirement: inverter_comp_results.peak_required_kw.toFixed(2) + " kW",
        quantity: inverter_comp_results.no_units,
        calcOutput: inverter_comp_results.inverter_rated_output_kw,
        calcCost: inverter_comp_results.cost_per_with_factor,
        component_cost: inverter_comp_results.cost_per_inverter,
      },
      {
        key: "2",
        category: "Battery Technical",
        title: battery_comp_results.battery_name,
        component_uuid: battery_comp_results.component_uuid,
        output: (battery_comp_results.backup_power_provided * battery_comp_results.no_units).toFixed(2),
        requirement: battery_comp_results.backup_power_required.toFixed(2) + " kWh",
        quantity: battery_comp_results.no_units,
        calcOutput: battery_comp_results.backup_power_provided,
        calcCost: battery_comp_results.cost,
        component_cost: battery_comp_results.cost,
      }
    ];

    if (solar_panel_results && solar_panel_results.name) {
      tableDatra.push({
        key: "3",
        category: "Panel Technical",
        title: solar_panel_results.name,
        component_uuid: solar_panel_results.component_uuid,
        output: (solar_panel_results.WP * solar_panel_results.no_units).toFixed(0),
        requirement: (solar_panel_results.required_solar_capacity_kw * 1000).toFixed(0) + " W",
        quantity: solar_panel_results.no_units,
        calcOutput: solar_panel_results.WP,
        calcCost: solar_panel_results.panel_price,
        component_cost: solar_panel_results.cost_per_panel,
      });
    }

    setReportData(cleaned_Data);

    setTableData(tableDatra);
    // setModalContent(JSON.stringify(quickReport, null, 2)); // Format the response as JSON
    setIsModalVisible(true); // Show the modal
  };

  const handleModalOk = () => {
    generatePDF();
    // setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  
  const handleQuantityChange = (value, record) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => record.key === item.key);

    let newInverterCount = inverterCount;
    let newBatteryCount = batteryCount;
    let newPvCount = pvCount;

    let item_requirement = record.requirement.split(" ")[0];

    // let final_output = 0;


    if (index !== -1) {
        const item = newData[index];
        const newOutput = item.calcOutput * value;
        // final_output = newOutput;

        newData[index] = {
            ...item,
            quantity: value,
            output: newOutput.toFixed(0),
        };
        setTableData(newData);

        if (item.category === "Inverter Technical") {
            const new_cost = parseFloat(item.calcCost * value);
            setInverterCost(new_cost);
            newInverterCount = value; // Update inverter count
        } else if (item.category === "Battery Technical") {
            const new_cost = parseFloat(item.calcCost * value);
            setBatteryCost(new_cost);
            newBatteryCount = value; // Update battery count
        } else if (item.category === "Panel Technical") {
            const new_cost = parseFloat(item.calcCost * value);
            setPanelCost(new_cost);
            newPvCount = value; // Update battery count
        }
    }

    return {
        newData,
        newInverterCount,
        newBatteryCount,
        newPvCount,
        // final_output,
        item_requirement
    };
};

useEffect(() => {
  const pieChartData = [
      {
          values: [batteryCost, inverterCost, panelCost],
          labels: ["Total Battery Cost (ZAR)", "Total Inverter Cost (ZAR)", "Total Panel Cost (ZAR)"],
          type: "pie",
          hole: 0.6,
          textinfo: "label+value",
          texttemplate: "%{label}: R%{value}",
          textposition: "outside",
          automargin: true,
          marker: {
              colors: ["#FF6347", "#1F77B4", "#F1E15B"], // Battery cost in red, inverter cost in blue
          },
      },
  ];
  setReportCostData(pieChartData);
}, [inverterCost, batteryCost, panelCost]);


  const handleComponentQuanityChange = (value, record) => {
    // const newData = [...tableData];
    // const index = newData.findIndex((item) => record.key === item.key);

    // if (index !== -1) {
    //   const item = newData[index];
    //   const newOutput = item.calcOutput * value;

    //   newData[index] = {
    //     ...item,
    //     quantity: value,
    //     output: newOutput.toFixed(0),
    //   };
    //   setTableData(newData);
    // }
    const { newData, newInverterCount, newBatteryCount, newPvCount } = handleQuantityChange(value, record);

    setTableData(newData);
    setInverterCount(newInverterCount);
    setBatteryCount(newBatteryCount);
    setPvCount(newPvCount);
  };

  const editableColumns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Recommended Components",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Components Output",
      dataIndex: "output",
      key: "output",
      render: (text, record) => {
        // Add the appropriate unit based on the category
        let unit;
        let meetsRequirement;
        switch (record.category) {
          case "Inverter Technical":
            unit = " kW";
            meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
            setInverterCompMeetsRequired(meetsRequirement);
            break;
          case "Battery Technical":
            unit = " kWh";
            meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
            setBatteryCompMeetsRequired(meetsRequirement);
            break;
          case "Panel Technical":
            unit = " W";
            meetsRequirement = text >= parseFloat(record.requirement.split(" ")[0]);
            setPanelCompMeetsRequired(meetsRequirement);
            break;
          default:
            unit = "";
            meetsRequirement = true;
        }



        // Render with Badge
        return (
          <div>
            {!meetsRequirement ? (
              <Badge status="warning" text={`${text}${unit}`} />
            ) : (
              `${text}${unit}`
            )}
          </div>
        );
      },
    },
    {
      title: "Requirement",
      dataIndex: "requirement",
      key: "requirement",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <InputNumber
          min={1}
          value={text}
          onChange={(value) => handleComponentQuanityChange(value, record)}
        />
      ),
    },
  ];

  const inputValues = [
    { label: 'Client Name', value: clientName },
    { label: 'Phase', value: phase },
    { label: 'System Type', value: systemType },
    { label: 'Inverter Make', value: selectedInverterMake },
    { label: 'Battery Make', value: selectedBatteryMake },
    { label: 'Solar Make', value: selectedSolarMake },
    { label: 'Battery Capacity', value: batteryCapacity + "%" },
    { label: 'Inverter Capacity', value: inverterCapacity + "%" },
    { label: 'Solar Capacity', value: solarCapacity + "%" },
    { label: 'Design Voltage', value: voltageType },
  ];

  const half = Math.ceil(inputValues.length / 2);
  const firstHalf = inputValues.slice(0, half);
  const secondHalf = inputValues.slice(half);


  const formatConfigSetupData = (data) => {
    // logger.log("SizingReport","INVERT SS: ", data);
    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
           {(Array.isArray(data) ? data : []).map(inverter => (
                <div key={inverter.inverter} style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <strong>Inverter {inverter.inverter}:</strong>
                    {inverter.mppts.map(mppt => (
                        <div key={mppt.mppt} style={{ paddingLeft: '20px' }}>
                            MPPT {mppt.mppt}: (String {mppt.mppt}: {mppt.panels} panels)
                        </div>
                    ))}
                    <div style={{ paddingLeft: '20px' }}>
                        Batteries: {inverter.batteries}
                    </div>
                </div>
            ))}
        </div>
    );
};

const determineSetup = (report_data, batteryCount, inverterCount, pvCount) => {
  const component_config_details = {
      inverterComponent: report_data.components.inverter,
      batteryComponent: report_data.components.battery,
      pvComponent: report_data.components.solar,
      overall_avg_kwh: report_data.system_results.overall_avg_kwh,
      backup_hours: report_data.system_results.backup_hours,
      overall_peak_watt: report_data.overall.overall_peak_watt,
      batteryCount: batteryCount,
      inverterCount: inverterCount,
      pvCount: pvCount,
      pv_array_size: report_data.system_results.solar_panel_result.pv_array_size,
  }
  // logger.log("SizingReport","Component Config Details: ", component_config_details);
  const setupData = CalculateComponentSetup(component_config_details);
  // logger.log("SizingReport","Component Config Data: ", setupData);
  // we set the setup data value, but also update table with new mins? no we give alert that they need more comps
  setConfigSetupData(setupData);
  // setSuggestedInverterCount(setupData.length);
};

useEffect(() => {
  if (reportData) {
      determineSetup(reportData, batteryCount, inverterCount, pvCount);
  }
}, [reportData, batteryCount, inverterCount, pvCount]);




async function generatePDF() {

  // notification to inform that it is generating
  
  notification.info({
      message: 'Generating PDF',
      description: 'Please wait while the PDF is being generated',
      placement: 'bottomRight'
  });

  const pdf = new jsPDF('p', 'mm', 'a4');
  let position = 15;

  // Add title
  pdf.setFontSize(25);
  pdf.text('Project Quick Report', 10, position);
  position += 15;

  // Add user details
  pdf.setFontSize(12);

  let labelWidth = 0;


  // Project name
  pdf.setFont('helvetica', 'normal');
  const projectNameLabel = 'Project Name:';
  pdf.text(projectNameLabel, 10, position);
  labelWidth = pdf.getTextWidth(projectNameLabel);

  pdf.setFont('helvetica', 'bold');
  pdf.text(` ${clientName}`, 10 + labelWidth, position);
  position += 6;




   // Phase
   pdf.setFont('helvetica', 'normal');
   const phaseLebel = 'Phase:';
   pdf.text(phaseLebel, 10, position);
   labelWidth = pdf.getTextWidth(phaseLebel);
 
   pdf.setFont('helvetica', 'bold');
   pdf.text(` ${phase}`, 10 + labelWidth, position);
   position += 6;

   // System Type
   pdf.setFont('helvetica', 'normal');
   const systemTypeLabel = 'System Type:';
   pdf.text(systemTypeLabel, 10, position);
   labelWidth = pdf.getTextWidth(systemTypeLabel);
 
   pdf.setFont('helvetica', 'bold');
   pdf.text(` ${systemType}`, 10 + labelWidth, position);
   position += 6;

     // Inverter Make
     pdf.setFont('helvetica', 'normal');
     const invMake = 'Inverter Make:';
     pdf.text(invMake, 10, position);
     labelWidth = pdf.getTextWidth(invMake);
   
     pdf.setFont('helvetica', 'bold');
     pdf.text(` ${selectedInverterMake}`, 10 + labelWidth, position);
     position += 6;

      // Battery Make
      pdf.setFont('helvetica', 'normal');
      const batMake = 'Battery Make:';
      pdf.text(batMake, 10, position);
      labelWidth = pdf.getTextWidth(batMake);
    
      pdf.setFont('helvetica', 'bold');
      pdf.text(` ${selectedBatteryMake}`, 10 + labelWidth, position);
      position += 6;

       // Battery Make
       pdf.setFont('helvetica', 'normal');
       const panleMake = 'Solar Make:';
       pdf.text(panleMake, 10, position);
       labelWidth = pdf.getTextWidth(panleMake);
     
       pdf.setFont('helvetica', 'bold');
       pdf.text(` ${selectedSolarMake}`, 10 + labelWidth, position);
       position += 6;

  // Inverter Size
  pdf.setFont('helvetica', 'normal');
  const inverterSizeLabel = 'Inverter Size:';
  pdf.text(inverterSizeLabel, 10, position);
  labelWidth = pdf.getTextWidth(inverterSizeLabel);

  pdf.setFont('helvetica', 'bold');
  pdf.text(` ${inverterCapacity}kW`, 10 + labelWidth, position);
  position += 6;

    // Battery Bank Size
    pdf.setFont('helvetica', 'normal');
    const batteryBankSizeLabel = 'Battery Bank Size:';
    pdf.text(batteryBankSizeLabel, 10, position);
    labelWidth = pdf.getTextWidth(batteryBankSizeLabel);
  
    pdf.setFont('helvetica', 'bold');
    pdf.text(` ${batteryCapacity}kWh`, 10 + labelWidth, position);
    position += 6;

  // PV Array Size
  pdf.setFont('helvetica', 'normal');
  const pvArraySizeLabel = 'PV Array Size:';
  pdf.text(pvArraySizeLabel, 10, position);
  labelWidth = pdf.getTextWidth(pvArraySizeLabel);

  pdf.setFont('helvetica', 'bold');
  pdf.text(` ${solarCapacity}kW`, 10 + labelWidth, position);
  position += 6;

     // Battery Bank Size
     pdf.setFont('helvetica', 'normal');
     const voltageTypeLabel = 'Design Voltage:';
     pdf.text(voltageTypeLabel, 10, position);
     labelWidth = pdf.getTextWidth(voltageTypeLabel);
   
     pdf.setFont('helvetica', 'bold');
     pdf.text(` ${voltageType}`, 10 + labelWidth, position);
     position += 12;

  // add system comp recommendations
  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(18);
  pdf.text('System Recommendations', 10, position);
  pdf.setFont('helvetica', 'normal');
  pdf.setFontSize(12);
  position += 12;

      // process the results
  const specsGraphs = ['compsTable', 'compsLayout', 'costCart']; // Replace with your actual graph IDs
  const specsTexts = ['Table of recommended components:', 'Recommended component configuration as per spec requirements:', 'Estimated Cost Breakdown']; // Corresponding texts for each graph

  for (let i = 0; i < specsGraphs.length; i++) {
      const graph = document.getElementById(specsGraphs[i]);
      if (graph) {
          const canvas = await html2canvas(graph, { scale: 2 });
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 190; // A4 width in mm minus margins
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          if (position + imgHeight + 10 > 297) {
              pdf.addPage();
              position = 10;
          }

          // Add text before each graph
          pdf.setFontSize(12);
          pdf.text(specsTexts[i], 10, position);
          position += 10;

          pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
          position += imgHeight + 10;
      } else {
          console.error(`Element with ID ${specsGraphs[i]} not found.`);
      }
  }
  
  // // inverter
  // pdf.setFont('helvetica', 'normal');
  // const inverterRecommendedLabel = 'Inverter:';
  // pdf.text(inverterRecommendedLabel, 10, position);
  // labelWidth = pdf.getTextWidth(inverterRecommendedLabel);

  // pdf.setFont('helvetica', 'bold');
  // pdf.text(` ${reportData.system_results.inverter_result.peak_required_kw} kW`, 10 + labelWidth, position);
  // position += 12;
  


  pdf.save("quick_sizing_report.pdf");
  // below is for viewing the pdf in the browser
  // const pdfBlob = pdf.output('blob');
  // const url = URL.createObjectURL(pdfBlob);
  // setPdfUrl(url);
  setIsModalVisible(false);
 
}


  return (
    <div>
      <h1>QuickSizing</h1>
      <form onSubmit={handleSubmit}>
        <Flex gap="middle" horizontal>
          <div style={{ width: '50%' }}>
            <h2>Inputs</h2>
            <p>
              The quick sizing if for a basic installation. Add a project name and use the sliders to get the inputs you need for the project. Then click the button to get the results.
            </p>
            <Input placeholder="Client Name/Project" value={clientName} onChange={(e) => setClientName(e.target.value)} required />
            <br></br>
            <br></br>
            <Flex gap="small">
              <label style={{ fontSize: '16px', color: '#888', marginTop: "auto", marginBottom: "auto" }}>Phase</label>
              <Radio.Group onChange={(e) => setPhase(e.target.value)} value={phase} buttonStyle="solid">
                <Radio.Button value={'single'} ><FontAwesomeIcon icon={fa1} /> SINGLE</Radio.Button>
                <Radio.Button value={'three'}><FontAwesomeIcon icon={fa3} /> THREE</Radio.Button>
              </Radio.Group>
            </Flex>
            <br></br>
            <Flex gap="small">
              <label
                style={{
                  fontSize: "16px",
                  color: "#888",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                Voltage Type
              </label>
              <Radio.Group
                onChange={(e) => setVoltageType(e.target.value)}
                value={voltageType}
                disabled={!isThreePhase}

              >
                <Radio.Button value={"HV"}>
                  <FontAwesomeIcon icon={fa1} /> High Voltage
                </Radio.Button>
                <Radio.Button value={"LV"}>
                  <FontAwesomeIcon icon={fa3} /> Low Voltage
                </Radio.Button>
              </Radio.Group>
            </Flex>
            <br></br>
            <Flex gap="small">
              <label style={{ fontSize: '16px', color: '#888', marginTop: "auto", marginBottom: "auto" }}>System Type</label>
              <Radio.Group onChange={(e) => setSystemType(e.target.value)} value={systemType} buttonStyle="solid" >
                <Radio.Button value={'backup'}><FontAwesomeIcon icon={faBatteryFull} /> BACKUP</Radio.Button>
                <Radio.Button value={'hybrid'}><FontAwesomeIcon icon={faSun} /> HYBRID/OFF GRID</Radio.Button>
              </Radio.Group>
            </Flex>
            <br></br>
            <div>
              <Flex gap="small">
                <label
                  style={{
                    fontSize: "16px",
                    color: "#888",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  Inverter Make
                </label>
                <Select
                  placeholder="Select Inverter Make"
                  onChange={(value) => setSelectedInverterMake(value)}
                  value={selectedInverterMake}
                  required
                >
                  {Object.keys(componentsData.inverters).map((make) => (
                    <Option key={make} value={make}>
                      {make}
                    </Option>
                  ))}
                </Select>
              </Flex>
              <br />
              <Flex gap="small">
                <label
                  style={{
                    fontSize: "16px",
                    color: "#888",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  Battery Make
                </label>
                <Select
                  placeholder="Select Battery Make"
                  onChange={(value) => setSelectedBatteryMake(value)}
                  value={selectedBatteryMake}
                  required
                >
                  {Object.keys(componentsData.batteries).map((make) => (
                    <Option key={make} value={make}>
                      {make}
                    </Option>
                  ))}
                </Select>
              </Flex>
              <br />
              {systemType === 'hybrid' && (
                <div>
                  <Flex gap="small">
                    <label
                      style={{
                        fontSize: "16px",
                        color: "#888",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Solar Make
                    </label>
                    <Select
                      placeholder="Select the Solar make"
                      onChange={(value) => setSelectedSolarMake(value)}
                      value={selectedSolarMake}
                      required
                    >
                      {Object.keys(componentsData.panels).map((make) => (
                        <Option key={make} value={make}>
                          {make}
                        </Option>
                      ))}
                    </Select>
                  </Flex>
                  <br />
                </div>
              )}
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <p>Battery Capacity - {batteryCapacity}kWh <Tooltip title="The amount of energy the battery can store">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                style={{ marginLeft: "8px" }}
              />
            </Tooltip></p>
            <Slider value={batteryCapacity} onChange={setbatteryCapacity} max={100} min={2} />

            <p>Inverter Peak Capacity - {inverterCapacity}kW <Tooltip title="The maximum amount of power the inverter can supply">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                style={{ marginLeft: "8px" }}
              />
            </Tooltip></p>
            <Slider value={inverterCapacity} onChange={setinverterCapacity} min={3} max={500} />

            {systemType === 'hybrid' && <div>
              <p>Solar Capacity - {solarCapacity}kW <Tooltip title="The amount of power the solar panels you plan to install can generate">
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ marginLeft: "8px" }}
                />
              </Tooltip></p>
              <Slider value={solarCapacity} onChange={setsolarCapacity} min={1} max={30} />
            </div>}

            <br></br>
            <Checkbox checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)}>Accept Terms and Conditions</Checkbox>
            <br></br>
            <br></br>
            <a href="#">Terms and Conditions</a>

            <br></br>
            <br></br>
            <Button type="primary" disabled={!acceptTerms || !clientName || !selectedBatteryMake || !selectedInverterMake || (systemType === 'hybrid' && (!selectedSolarMake))} htmlType="submit">GENERATE SIZING</Button>
          </div>
        </Flex>
      </form>
      <Modal
        title="Quick Sizing Report"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Download Report"
        width={800}
      >
        <div>
          <div>
            <h3>User Input Values</h3>
            <Row gutter={16}>
              <Col span={12}>
                <List
                  bordered
                  dataSource={firstHalf}
                  renderItem={item => (
                    <List.Item>
                      <Text strong>{item.label}:</Text> <Text>{item.value}</Text>
                    </List.Item>
                  )}
                />
              </Col>
              <Col span={12}>
                <List
                  bordered
                  dataSource={secondHalf}
                  renderItem={item => (
                    <List.Item>
                      <Text strong>{item.label}:</Text> <Text>{item.value}</Text>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </div>

         
          <br />
          <div id="compsTable">
            


            <Table
              dataSource={tableData}
              columns={editableColumns}
              pagination={false}
              bordered
              title={() => "Recommended Components Based On Calculations"}
              footer={() =>
                "* Component recommendations take into account the configuration and minimums required to setup the system optimally."
              }
            />
            {(!batteryCompMeetsRequired || !inverterCompMeetsRequired || !panelCompMeetsRequired) && (
              <span>
                <br />
                <Alert message="CAUTION" description="Some of your components don't meet their required output in the table above. Only proceed if you know what you are doing" type="warning" showIcon />

              </span>
            )}
          </div>
<br />
          <div >
          <p>Recommended Component Configuration for the selected <b>{reportData?.system_results.design_voltage}</b> voltage type and <b>{reportData?.system_results.type}</b> system</p>
          <pre id="compsLayout">{formatConfigSetupData(configSetupData)}</pre>
          </div>
          <br />
          <hr />
          <br />
          <div id="costCart">
          <CostPlotChart costPlotData={{ reportCostData, inverterCost, batteryCost, panelCost }} />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default QuickSizing;