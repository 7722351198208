import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Form, Input, Select, Skeleton, notification, Row, Col, Modal, Flex, Statistic, Result, Card } from "antd";
// import { api_endpoint } from "./apiConfig";
import { pushUserData, pushBusinessData, registerBusiness, confirmTransaction, fetchCreditBalance } from "../services/apiService";
import { debounce } from 'lodash';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../constants'; // Adjust the import path as needed
import { PaystackButton } from 'react-paystack';

import config from '../config';


const libraries = ['places'];

import logger from '../utils/logger';
import FormItem from "antd/es/form/FormItem";
logger.enableLogging('Profile');

function Profile({ businessData, userData, setBusinessData, setUserData }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [address, setAddress] = useState(null);
  const [customAddress, setCustomAddress] = useState(null);

  // Handle selection from GooglePlacesAutocomplete
  const handleSelect = (value) => {
    console.log("value: ", value);
    setAddress(value);
    form.setFieldsValue( {address: { label: value?.label, value: { description: value.value.description, place_id: value.value.placeId }} });
    if (value?.label) {
      getPlaceIdAndCoords(value.label);
    }
  };

  // Get place ID and coordinates based on the selected address
  const getPlaceIdAndCoords = (val) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address: val }, (results, status) => {
      if (status === 'OK' && results[0]) {
        // console.log("Results: ", results);
        const local_placeId = results[0].place_id;
        const location = results[0].geometry.location;

        setCustomAddress({ placeId: local_placeId, description: val, lat: location.lat(), long: location.lng() });
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  };


  // Payments
  // TODO: MOVE TO SEPERATE FILE
  const [isRechargeModalVisible, setIsRechargeModalVisible] = useState(false);
  const [rechargeAmount, setRechargeAmount] = useState(0);


  const handlePaystackSuccessAction = async (reference) => {
    const token = localStorage.getItem('accessToken');
    // Optionally, update the user's credits in the state or fetch the updated data from the server
    logger.log("Profile", 'Payment Reference:', reference);
    const credit_result = await confirmTransaction(token, reference);
    logger.log("Profile", credit_result);
    if (credit_result.status === "200") {
      notification.success({
        message: 'Payment Successful',
        description: 'You have successfully recharged your credits.',
      });
      setPurchaseSuccess(true); // Set the purchase success state to true
      businessData['business_credits'] += rechargeAmount;
      setIsRechargeModalVisible(false);
    } else {
      notification.error({
        message: 'Payment Failed',
        description: 'An error occurred while recharging your credits.',
      });
    }
  };

  const handlePaystackCloseAction = () => {
    // Implement what should happen when the Paystack modal is closed
    notification.info({
      message: 'Payment Cancelled',
      description: 'You have cancelled the payment process.',
    });
  };

  const handleBankTransferConfirmationPending = () => {
    notification.info({
      message: 'Bank Transfer Pending',
      description: 'Your bank transfer is pending confirmation. Please wait for the confirmation. This will close one confirmation is completed.',
    });
  };



  let paystackConfig = null;

  if (businessData && businessData.business_email) {
    paystackConfig = {
      reference: (new Date()).getTime().toString(),
      email: businessData.business_email,
      amount: rechargeAmount * 100, // Paystack works with kobo, so multiply by 100
      currency: 'ZAR', // Set the currency here (e.g., NGN, GHS, USD)
      publicKey: config.paystackPublicKey,
      metadata: {
        business_uuid: businessData.uuid, // Pass user UUID or any other metadata
        business_email: businessData.business_email,
        amount_of_credits: rechargeAmount,
      },
    };
  }

  const showRechargeModal = () => {
    setIsRechargeModalVisible(true);
  };

  const handleRechargeCancel = () => {
    setIsRechargeModalVisible(false);
  };

  const handleRechargeAmountChange = (e) => {
    setRechargeAmount(e.target.value);
    setSelectedPackage({ label: 'Custom', value: e.target.value, cost: e.target.value * 3, duration: e.target.value * 2 * 5 + " days" });
  };

  // Define the packages for credit purchase
  const packages = [
    { label: 'Basic', value: 28, cost: 28, duration: '7 Days' },
    { label: 'Standard', value: 56, cost: 56, duration: '14 Days' },
    { label: 'Bulk', value: 120, cost: 120, duration: '30 Days' },
    { label: 'Custom', value: 'custom', cost: 28, duration: 'custom' },
  ];

  const [purchaseSuccess, setPurchaseSuccess] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState(null);

  const handlePackageSelect = (pkg) => {
    if (pkg.value === 'custom') {
      setRechargeAmount(28);
    } else {
      setRechargeAmount(pkg.cost);
    }
    setSelectedPackage(pkg);
  };

  const [form] = Form.useForm();
  const [business_form] = Form.useForm();
  const [register_form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const accessToken = localStorage.getItem('accessToken');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [businessLoading, setBusinessLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBusinessAdmin, setIsBusinessAdmin] = useState(false);

  const [messageText, setMessageText] = useState('');

  const [businessCredits, setBusinessCredits] = useState(0);

  useEffect(() => async () => {
    try {
      if (userData) {
        const token = localStorage.getItem('accessToken');
        const creds = await fetchCreditBalance(token);
        setBusinessCredits(creds);
        setLoading(false);
        setCustomAddress(userData.address);
        setAddress({ label: userData.address.description, value: { description: userData.address.description, place_id: userData.address.placeId } });
        setIsBusinessAdmin(userData.privilage.level === "1");
        if (!isBusinessAdmin && businessData) {
          setMessageText('You are not a business admin, you cannot edit business data');
        } else if (!businessData) {
          setMessageText('No linked businesses could be found. Please register a new business or get an invite from an existing business.');
        }
      }
    } catch (error) {
      console.error('Failed to fetch monitors:', error);
    }
  }, [userData]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  }

  const onFinish = async (values) => {
    setButtonLoading(true);
    try {
      const updatedValues = {
        ...values,
        address: customAddress ? customAddress : values.address,
      };
      const response = await pushUserData(accessToken, updatedValues);
      console.log(response);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'User profile updated successfully.',
        });
        const updatedUserData = {
          ...userData,
          ...values,
        };
        setUserData(updatedUserData);
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to update user profile.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the profile.',
      });
    } finally {
      setButtonLoading(false);
    }
  };

  const [isRegisterButtonDisabled, setIsRegisterButtonDisabled] = useState(true);

  // Debounce the validation function to prevent excessive calls
  const debouncedCheckRegisterFormValidity = debounce(async () => {
    try {
      await register_form.validateFields();
      setIsRegisterButtonDisabled(false);
    } catch (error) {
      setIsRegisterButtonDisabled(true);
    }
  }, 300);

  const onFinishBusiness = async (values) => {
    setBusinessLoading(true);
    try {
      const response = await pushBusinessData(accessToken, values);
      console.log(response);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Business profile updated successfully.',
        });
        const updatedBusinessData = {
          ...businessData,
          ...values,
        };
        setBusinessData(updatedBusinessData);
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to update business profile.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while updating the business.',
      });
    } finally {
      setBusinessLoading(false);
    }
    console.log('Business:', values);
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


  const onFinishRegister = async (values) => {
    setRegisterLoading(true);
    try {
      const response = await registerBusiness(accessToken, values);
      console.log(response);
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Business registered successfully.',
        });
        // Optionally update state or perform other actions
        // const temp_data = {
        //   ...values,
        // }



      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to register business.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while registering the business.',
      });
    } finally {
      setRegisterLoading(false);
      setIsModalVisible(false);

      await delay(2000); // Wait for 2 seconds
      window.location.reload(); // Reload the page after registering a new business
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (!isLoaded) return <div>Loading...</div>;


  return (
    <div>


      {/* <pre>{JSON.stringify(businessData, null, 2)}</pre> */}

      {/* <pre>{JSON.stringify(address, null, 2)}</pre> */}


      <h1 style={{ textAlign: 'left' }}>Profile</h1>
      <Flex>
        {purchaseSuccess ? (
          <div style={{ width: '100%' }}>
            <Result
              status="success"
              title="Successfully Purchased Credits!"

              subTitle={`You have successfully added ${rechargeAmount} credits to your account.`}
              extra={[
                <Button type="primary" key="console" onClick={() => window.location.reload()}>
  Go Back
</Button>
              ]}
            />
          </div>
        ) : (
          <>
            <div style={{ maxWidth: '600px', margin: '0 auto' }}>
              <h2>Personal</h2>
              <p style={{ textAlign: 'left' }}>Save your personal profile details.</p>
              <Skeleton loading={loading} active>
                <Form
                  form={form}
                  name="userProfile"
                  onFinish={onFinish}
                  initialValues={{
                    email: userData ? userData.email : '',
                    fullname: userData ? userData.fullname : '',
                    // address: userData ? userData.address.description : '',
                    phone_number: userData ? userData.phone_number : '',
                  }}
                  style={{ maxWidth: '600px', margin: '0 auto' }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Full Name"
                        name="fullname"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Full Name!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your full name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Email!',
                          },
                        ]}
                      >
                        <Input disabled placeholder="Enter your email" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Phone Number"
                        name="phone_number"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Phone Number!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter your phone number" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Address"
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Address!',
                          },
                        ]}
                      >
                        <GooglePlacesAutocomplete
                          apiKey={GOOGLE_MAPS_API_KEY}
                          selectProps={{
                            value: address,
                            onChange: handleSelect,
                            placeholder: 'Enter your address',
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={buttonLoading}>
                      SAVE
                    </Button>
                  </Form.Item>
                </Form>
              </Skeleton>
            </div>
            {businessData && isBusinessAdmin ? (
              <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                <Skeleton loading={loading} active>
                  <Row>
                    <Col span={24}>
                      <h2>Business Management</h2>
                      <p style={{ textAlign: 'left' }}>More details about your business.</p>
                    </Col>
                  </Row>
                  <Form
                    form={business_form}
                    name="businessProfile"
                    onFinish={onFinishBusiness}
                    initialValues={{
                      business_email: businessData ? businessData.business_email : '',
                      business_name: businessData ? businessData.business_name : '',
                      business_phone_number: businessData ? businessData.business_phone_number : '',
                      business_markup: businessData ? businessData.business_markup : '',
                      business_vat: businessData ? businessData.business_vat : '',
                      business_description: businessData ? businessData.business_description : '',
                      business_website: businessData ? businessData.business_website : '',
                      business_warehouse: businessData ? businessData.business_warehouse : '',
                    }}
                    style={{ maxWidth: '600px', margin: '0 auto' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Business Phone Number"
                          name="business_phone_number"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Phone Number!',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your business phone number" type="tel" maxLength={15} disabled={!isBusinessAdmin} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Business Email"
                          name="business_email"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Email!',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your business email" disabled={!isBusinessAdmin} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Business Name"
                          name="business_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Business Name',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your business name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Website"
                          name="business_website"
                          rules={[
                            {
                              required: false,
                              message: 'Please input your Business Website',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your business website" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label="Business Description"
                      name="business_description"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Business Description',
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Enter a description of your business" rows={4} maxLength={200} />
                    </Form.Item>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Vat Number"
                          name="business_vat"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your VAT Number!',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your VAT number" type="number" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Markup"
                          name="business_markup"
                          rules={[
                            {
                              required: false,
                              message: 'Please input your Markup!',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your markup" type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="Warehouse" name="business_warehouse">
                      <Select defaultValue="Cape Town" style={{ width: 120 }} onChange={handleChange}>
                        {/* Add options here */}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={businessLoading}>
                        SAVE
                      </Button>
                    </Form.Item>
                  </Form>
                </Skeleton>

                <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                  <h2>Credits</h2>
                  <Col>
                    <Flex gap='large'>
                    <Statistic title="Account Balance (Credits)" value={businessCredits} precision={2} />
                    <Statistic title="Account Balance (Credit Hours)" value={businessCredits*6} precision={2} />
</Flex>
                    <Button type="primary" style={{ marginTop: 16 }} onClick={showRechargeModal}>
                      Recharge Credits
                    </Button>
                  </Col>

                  <Modal
                    title="Recharge Credits"
                    open={isRechargeModalVisible}
                    onCancel={handleRechargeCancel}
                    footer={null}
                    width={800}
                  >
                    <Form>
                      <FormItem>
                        Select a package to recharge your credits for your business. All payments are done securly via Paystack.
                      </FormItem>
                      <Row gutter={16}>
                        {packages.map((pkg) => (
                          <Col span={6} key={pkg.label}>
                            <Card

                              hoverable
                              onClick={() => handlePackageSelect(pkg)}
                              style={{
                                borderColor: selectedPackage?.label === pkg.label ? '#1890ff' : '#f0f0f0',
                                backgroundColor: selectedPackage?.label === pkg.label ? '#e6f7ff' : '#fff',
                                height: '230px'
                              }}
                            >
                              <h3>{pkg.label}</h3>
                              {pkg.value !== 'custom' && <p>{pkg.value} credits</p>}
                              {pkg.value !== 'custom' && <p>{(pkg.value*6)/24} Days</p>}
                              
                              
                                {pkg.value !== 'custom' && <p>{pkg.cost} ZAR</p>}
                       
                              {pkg.value === 'custom' && (
                                <span>
                                  <Input
                                    type="number"
                                    placeholder="Enter your desired credit amount"
                                    value={rechargeAmount}
                                    onChange={handleRechargeAmountChange}
                                    min={5}

                                  />
                                  {pkg.value === 'custom' && <p>{(rechargeAmount*6)/24} Days</p>}
                                  <p>{rechargeAmount} ZAR</p>
                                </span>
                              )}
                            </Card>
                          </Col>
                        ))}
                      </Row>
                      <Form.Item>
                        <h3>Summary Breakdown:</h3>
                        <p>You selected the {selectedPackage?.label} package, which provides {rechargeAmount} additional credits to your business. Usage Estimate is around ~{(rechargeAmount * 6) / 24 + " Days"} of usage for this amount. These will not expire.</p>
                        <p>
                          <strong>{rechargeAmount} credits</strong> will be added to your account.
                        </p>
                        <p>
                          This will cost <strong>R{rechargeAmount}</strong>
                        </p>
                      </Form.Item>
                      <Form.Item>
                        <PaystackButton
                          {...paystackConfig}
                          text="Pay Now"
                          onSuccess={handlePaystackSuccessAction}
                          onClose={handlePaystackCloseAction}
                          onBankTransferConfirmationPending={handleBankTransferConfirmationPending}
                          disabled={rechargeAmount < 28}
                          className="paystack-btn paystack-btn-primary"
                        />
                      </Form.Item>
                    </Form>
                  </Modal>
                </div>
              </div>

            ) : (

              <p style={{ maxWidth: '600px', margin: '0 auto' }}>{messageText}</p>

            )}
            {!businessData && (
              <div style={{ maxWidth: '600px', margin: '0 auto' }}>

                <Row>
                  <Col span={24}>
                    <Button type="primary" onClick={showModal}>
                      Register a New Business
                    </Button>
                  </Col>
                </Row>
                <Modal
                  title="Register a New Business"
                  open={isModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                  width={800}
                >
                  <Form
                    form={register_form}
                    name="registerBusiness"
                    onFinish={onFinishRegister}
                    onFieldsChange={debouncedCheckRegisterFormValidity}
                    style={{ maxWidth: '600px', margin: '0 auto' }}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Business Name"
                          name="business_name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Business Name',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your business name" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Business Email"
                          name="business_email"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Business Email',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your business email" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Business Phone Number"
                          name="business_phone_number"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Business Phone Number',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your business phone number" type="tel" maxLength={15} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Website"
                          name="business_website"
                          rules={[
                            {
                              required: false,
                              message: 'Please input your Business Website',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your business website" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label="Business Description"
                      name="business_description"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your Business Description',
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Enter a description of your business" rows={4} maxLength={200} />
                    </Form.Item>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Vat Number"
                          name="business_vat"
                          rules={[
                            {
                              required: false,
                              message: 'Please input your VAT Number!',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your VAT number" type="number" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Markup"
                          name="business_markup"
                          rules={[
                            {
                              required: false,
                              message: 'Please input your Markup!',
                            },
                          ]}
                        >
                          <Input placeholder="Enter your markup" type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="Warehouse" name="warehouse">
                      <Select defaultValue="Cape Town" style={{ width: 120 }} onChange={handleChange}>
                        {/* Add options here */}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="tos"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('You must accept the terms and conditions')),
                        },
                      ]}
                    >
                      <Checkbox>
                        I have read and agree to the <a href="/terms">Terms and Conditions</a>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={registerLoading} disabled={isRegisterButtonDisabled}>
                        Register New Business
                      </Button>
                    </Form.Item>
                  </Form>
                </Modal>
              </div>
            )}


          </>
        )}
      </Flex>
    </div>

  );
}

Profile.propTypes = {
  businessData: PropTypes.object,
  userData: PropTypes.object,
  setBusinessData: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default Profile;